import { createRouter, createWebHistory } from 'vue-router'
import MedalTable from '../views/MedalTable.vue'
import DailySchedule from '../views/DailySchedule.vue'
import MatchupView from '../views/MatchupView.vue'

const routes = [
  { path: '/', name: 'MedalTable', component: MedalTable },
  { path: '/daily-schedule', name: 'DailySchedule', component: DailySchedule },
  { path: '/matchup', name: 'Matchup', component: MatchupView }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router