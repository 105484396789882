<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
/* 添加一些简单的样式 */
body {
  font-family: Arial, sans-serif;
}
button {
  margin-right: 10px;
}
</style>