/* eslint-disable no-unused-vars */
const matchData = [
  {
    "matchId": "1",
    "ParisTime": "09:00",
    "BeijingTime": "25日15:00",
    "eventName": "手球",
    "matchDetail": "女子预赛A组 斯洛文尼亚19:27丹麦",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "2",
    "ParisTime": "09:30",
    "BeijingTime": "25日15:30",
    "eventName": "射箭",
    "matchDetail": "女子个人排名赛",
    "venue": "荣军院"
  },
  {
    "matchId": "3",
    "ParisTime": "09:30",
    "BeijingTime": "25日15:30",
    "eventName": "射箭",
    "matchDetail": "女子团体排名赛",
    "venue": "荣军院"
  },
  {
    "matchId": "4",
    "ParisTime": "11:00",
    "BeijingTime": "25日17:00",
    "eventName": "手球",
    "matchDetail": "女子预赛B组 荷兰34:31安哥拉",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "5",
    "ParisTime": "14:00",
    "BeijingTime": "25日20:00",
    "eventName": "手球",
    "matchDetail": "女子预赛B组 西班牙18:29巴西",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "6",
    "ParisTime": "14:00",
    "BeijingTime": "25日20:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子分组循环赛B组 萨摩亚26:0肯尼亚",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "7",
    "ParisTime": "14:15",
    "BeijingTime": "25日20:15",
    "eventName": "射箭",
    "matchDetail": "男子个人排名赛",
    "venue": "荣军院"
  },
  {
    "matchId": "8",
    "ParisTime": "14:15",
    "BeijingTime": "25日20:15",
    "eventName": "射箭",
    "matchDetail": "男子团体排名赛",
    "venue": "荣军院"
  },
  {
    "matchId": "9",
    "ParisTime": "14:15",
    "BeijingTime": "25日20:15",
    "eventName": "射箭",
    "matchDetail": "混合团体排名赛",
    "venue": "荣军院"
  },
  {
    "matchId": "10",
    "ParisTime": "14:30",
    "BeijingTime": "25日20:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子分组循环赛B组 阿根廷14:22澳大利亚",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "11",
    "ParisTime": "15:00",
    "BeijingTime": "25日21:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子分组循环赛C组 美国33:17乌拉圭",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "12",
    "ParisTime": "15:30",
    "BeijingTime": "25日21:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子分组循环赛C组 斐济19:12法国",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "13",
    "ParisTime": "16:00",
    "BeijingTime": "25日22:00",
    "eventName": "手球",
    "matchDetail": "女子预赛A组 德国22:23韩国",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "14",
    "ParisTime": "16:00",
    "BeijingTime": "25日22:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子分组循环赛A组 南非49:5日本",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "15",
    "ParisTime": "16:30",
    "BeijingTime": "25日22:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子分组循环赛A组 新西兰14:12爱尔兰",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "16",
    "ParisTime": "17:00",
    "BeijingTime": "25日23:00",
    "eventName": "足球",
    "matchDetail": "女子A组 加拿大2:1新西兰",
    "venue": "热奥弗鲁瓦基查尔体育场"
  },
  {
    "matchId": "17",
    "ParisTime": "17:00",
    "BeijingTime": "25日23:00",
    "eventName": "足球",
    "matchDetail": "女子C组 西班牙2:1日本",
    "venue": "博茹瓦尔体育场"
  },
  {
    "matchId": "18",
    "ParisTime": "19:00",
    "BeijingTime": "26日01:00",
    "eventName": "手球",
    "matchDetail": "女子预赛B组 匈牙利28:31法国",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "19",
    "ParisTime": "19:00",
    "BeijingTime": "26日01:00",
    "eventName": "足球",
    "matchDetail": "女子B组 德国3:0澳大利亚",
    "venue": "马赛体育场"
  },
  {
    "matchId": "20",
    "ParisTime": "19:00",
    "BeijingTime": "26日01:00",
    "eventName": "足球",
    "matchDetail": "女子C组 尼日利亚0:1巴西",
    "venue": "波尔多体育场"
  },
  {
    "matchId": "21",
    "ParisTime": "20:00",
    "BeijingTime": "26日02:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子9-12名排名赛 萨摩亚42:7日本",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "22",
    "ParisTime": "20:30",
    "BeijingTime": "26日02:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子9-12名排名赛 乌拉圭14:19肯尼亚",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "23",
    "ParisTime": "21:00",
    "BeijingTime": "26日03:00",
    "eventName": "手球",
    "matchDetail": "女子预赛A组 挪威28:32瑞典",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "24",
    "ParisTime": "21:00",
    "BeijingTime": "26日03:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子1/4决赛 新西兰7:14南非",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "25",
    "ParisTime": "21:00",
    "BeijingTime": "26日03:00",
    "eventName": "足球",
    "matchDetail": "女子A组 法国3:2哥伦比亚",
    "venue": "里昂体育场"
  },
  {
    "matchId": "26",
    "ParisTime": "21:00",
    "BeijingTime": "26日03:00",
    "eventName": "足球",
    "matchDetail": "女子B组 美国3:0赞比亚",
    "venue": "尼斯体育场"
  },
  {
    "matchId": "27",
    "ParisTime": "21:30",
    "BeijingTime": "26日03:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子1/4决赛 阿根廷14:26法国",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "28",
    "ParisTime": "22:00",
    "BeijingTime": "26日04:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子1/4决赛 斐济19:15爱尔兰",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "29",
    "ParisTime": "22:30",
    "BeijingTime": "26日04:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子1/4决赛 澳大利亚18:0美国",
    "venue": "法兰西体育场"
  },
];
export default matchData;