<template>
  <div class="container">
    <div class="button-group">
      <button @click="$router.push('/')">奖牌榜</button>
      <button @click="$router.push('/daily-schedule')">每日赛况</button>
      <button @click="$router.push('/matchup')">对阵图</button>
    </div>
    <h1>奖牌榜</h1>

    <!-- 表格 -->
    <table class="medal-table">
      <thead>
        <tr>
          <th>排名</th>
          <th>国家</th>
          <th>金牌</th>
          <th>银牌</th>
          <th>铜牌</th>
          <th>总奖牌数</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="country in medalData" :key="country.rank">
          <td>{{ country.rank }}</td>
          <td>{{ country.country }}</td>
          <td>{{ country.gold }}</td>
          <td>{{ country.silver }}</td>
          <td>{{ country.bronze }}</td>
          <td>{{ country.totalMedals }}</td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
// 导入奖牌数据
import medalData from '../data.js';

export default {
  name: 'MedalTable',
  data() {
    return {
      medalData // 将数据绑定到组件实例
    };
  }
}
</script>

<style scoped>
/* 设置背景图片 */
.container {
  background-image: url('../assets/background.jpg'); /* 确保路径正确 */
  background-size: cover; /* 背景图像按比例铺满 */
  background-position: center; /* 居中背景图像 */
  background-repeat: no-repeat; /* 背景不重复 */
  min-height: 100vh; /* 确保容器至少填满整个视口高度 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px; /* 增加顶部填充以调整按钮位置 */
}

.button-group {
  display: flex;
  gap: 10px; /* 按钮之间的间距 */
  margin-bottom: 20px; /* 按钮组与标题之间的间距 */
}

.button-group button {
  font-size: 18px; /* 增大按钮字体大小 */
  padding: 10px 20px; /* 增大按钮内边距 */
  background-color: rgba(255, 255, 255, 0.4); /* 设置按钮背景颜色透明度 */
  color: black; /* 设置字体颜色为黑色且不透明 */
  border: 2px solid lightgray; /* 设置边框为浅灰色 */
  border-radius: 12px; /* 设置中偏大的圆角 */
}

.medal-table {
  width: 100%;
  max-width: 800px; /* 设置表格最大宽度 */
  border-collapse: collapse;
  border-radius: 12px; /* 添加圆角 */
  overflow: hidden; /* 确保圆角生效 */
}

.medal-table th, .medal-table td {
  text-align: center;
  padding: 4px; /* 减小填充以减少空白 */
  font-weight: bold; /* 将文字加粗 */
}

.medal-table th {
  background-color: rgba(242, 242, 242, 0.25); /* 使用 RGBA 设置背景透明度 */
}

.medal-table td {
  border-bottom: 1px solid #ddd;
}

/* 控制列宽 */
.medal-table th:nth-child(1),
.medal-table td:nth-child(1) {
  width: 10%;
}

.medal-table th:nth-child(2),
.medal-table td:nth-child(2) {
  width: 40%;
}

.medal-table th:nth-child(n+3),
.medal-table td:nth-child(n+3) {
  width: 10%;
}
</style>