/* eslint-disable no-unused-vars */
const matchData = [
  {
    "matchId": "1",
    "ParisTime": "00:24",
    "BeijingTime": "28日06:24",
    "eventName": "冲浪",
    "matchDetail": "女子第1轮 - 第2组",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "2",
    "ParisTime": "01:00",
    "BeijingTime": "28日07:00",
    "eventName": "冲浪",
    "matchDetail": "女子第1轮 - 第3组",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "3",
    "ParisTime": "01:36",
    "BeijingTime": "28日07:36",
    "eventName": "冲浪",
    "matchDetail": "女子第1轮 - 第4组",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "4",
    "ParisTime": "02:12",
    "BeijingTime": "28日08:12",
    "eventName": "冲浪",
    "matchDetail": "女子第1轮 - 第5组",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "5",
    "ParisTime": "02:48",
    "BeijingTime": "28日08:48",
    "eventName": "冲浪",
    "matchDetail": "女子第1轮 - 第6组",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "6",
    "ParisTime": "03:24",
    "BeijingTime": "28日09:24",
    "eventName": "冲浪",
    "matchDetail": "女子第1轮 - 第7组",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "7",
    "ParisTime": "04:00",
    "BeijingTime": "28日10:00",
    "eventName": "冲浪",
    "matchDetail": "女子第1轮 - 第8组",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "8",
    "ParisTime": "08:30",
    "BeijingTime": "28日14:30",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - D组 西本拳太2:0帕纳林",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "9",
    "ParisTime": "08:30",
    "BeijingTime": "28日14:30",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - A组 松本麻佑/永原和可那1:2蒂娜/陈康乐",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "10",
    "ParisTime": "08:30",
    "BeijingTime": "28日14:30",
    "eventName": "羽毛球",
    "matchDetail": "混合双打小组赛 - D组 冯彦哲/黄东萍2:0许永凯/陈薇涵",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "11",
    "ParisTime": "09:00",
    "BeijingTime": "28日15:00",
    "eventName": "手球",
    "matchDetail": "女子预赛B组 巴西24:25匈牙利",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "12",
    "ParisTime": "09:00",
    "BeijingTime": "28日15:00",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨复活赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "13",
    "ParisTime": "09:00",
    "BeijingTime": "28日15:00",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨复活赛1",
    "venue": "水上体育场"
  },
  {
    "matchId": "14",
    "ParisTime": "09:00",
    "BeijingTime": "28日15:00",
    "eventName": "沙滩排球",
    "matchDetail": "女子预赛 - A组 意大利1:2西班牙",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "15",
    "ParisTime": "09:00",
    "BeijingTime": "28日15:00",
    "eventName": "排球",
    "matchDetail": "女子预赛 - C组 意大利3:1多米尼加",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "16",
    "ParisTime": "09:12",
    "BeijingTime": "28日15:12",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨复活赛2",
    "venue": "水上体育场"
  },
  {
    "matchId": "17",
    "ParisTime": "09:15",
    "BeijingTime": "28日15:15",
    "eventName": "射击",
    "matchDetail": "女子10米气步枪资格赛",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "18",
    "ParisTime": "09:20",
    "BeijingTime": "28日15:20",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - A组 苏伟译/谢定峰2:0矢仓尼/董星宇",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "19",
    "ParisTime": "09:20",
    "BeijingTime": "28日15:20",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - E组 戴资颖2:0谭莲妮",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "20",
    "ParisTime": "09:20",
    "BeijingTime": "28日15:20",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - M组 普萨拉2:0阿卜杜勒·拉扎克",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "21",
    "ParisTime": "09:24",
    "BeijingTime": "28日15:24",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨复活赛3",
    "venue": "水上体育场"
  },
  {
    "matchId": "22",
    "ParisTime": "09:30",
    "BeijingTime": "28日15:30",
    "eventName": "射箭",
    "matchDetail": "女子团体1/8淘汰赛 中国台北 VS 美国",
    "venue": "荣军院"
  },
  {
    "matchId": "23",
    "ParisTime": "09:30",
    "BeijingTime": "28日15:30",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑64强 皮斯托亚13:15卡坦坦",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "24",
    "ParisTime": "09:30",
    "BeijingTime": "28日15:30",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑64强 扎卡拉尼3:15耶林斯卡",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "25",
    "ParisTime": "09:30",
    "BeijingTime": "28日15:30",
    "eventName": "竞技体操",
    "matchDetail": "女子资格赛 - 分组1",
    "venue": "贝尔西体育馆"
  },
  {
    "matchId": "26",
    "ParisTime": "09:30",
    "BeijingTime": "28日15:30",
    "eventName": "射击",
    "matchDetail": "男子10米气手枪决赛",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "27",
    "ParisTime": "09:36",
    "BeijingTime": "28日15:36",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨复活赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "28",
    "ParisTime": "09:36",
    "BeijingTime": "28日15:36",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨复活赛1",
    "venue": "水上体育场"
  },
  {
    "matchId": "29",
    "ParisTime": "09:48",
    "BeijingTime": "28日15:48",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨复活赛2",
    "venue": "水上体育场"
  },
  {
    "matchId": "30",
    "ParisTime": "09:53",
    "BeijingTime": "28日15:53",
    "eventName": "射箭",
    "matchDetail": "女子团体1/8淘汰赛 法国 VS 荷兰",
    "venue": "荣军院"
  },
  {
    "matchId": "31",
    "ParisTime": "10:00",
    "BeijingTime": "28日16:00",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑64强 卢戈15:11张",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "32",
    "ParisTime": "10:00",
    "BeijingTime": "28日16:00",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑64强 沙尔莱莫夫15:9萨纳",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "33",
    "ParisTime": "10:00",
    "BeijingTime": "28日16:00",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑64强 尤尔卡15:8纳沙特·穆赫辛",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "34",
    "ParisTime": "10:00",
    "BeijingTime": "28日16:00",
    "eventName": "曲棍球",
    "matchDetail": "女子分组循环赛A组 比利时2:1中国",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "35",
    "ParisTime": "10:00",
    "BeijingTime": "28日16:00",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级32强淘汰赛 德米雷尔0:1萨哈",
    "venue": "战神广场"
  },
  {
    "matchId": "36",
    "ParisTime": "10:00",
    "BeijingTime": "28日16:00",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级32强淘汰赛 阿部 歌10:0出口",
    "venue": "战神广场"
  },
  {
    "matchId": "37",
    "ParisTime": "10:00",
    "BeijingTime": "28日16:00",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨复活赛3",
    "venue": "水上体育场"
  },
  {
    "matchId": "38",
    "ParisTime": "10:00",
    "BeijingTime": "28日16:00",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 阿萨尔4:1拉库图阿里马纳纳",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "39",
    "ParisTime": "10:00",
    "BeijingTime": "28日16:00",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 赵大成 2:4杰哈",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "40",
    "ParisTime": "10:00",
    "BeijingTime": "28日16:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 孙颖莎4:0朱丽叶·高桥",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "41",
    "ParisTime": "10:00",
    "BeijingTime": "28日16:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 斯佐科斯4:1周静怡",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "42",
    "ParisTime": "10:00",
    "BeijingTime": "28日16:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - B组 荷兰1:2意大利",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "43",
    "ParisTime": "10:06",
    "BeijingTime": "28日16:06",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级32强淘汰赛 皮拉斯10:0波斯蒂戈斯",
    "venue": "战神广场"
  },
  {
    "matchId": "44",
    "ParisTime": "10:06",
    "BeijingTime": "28日16:06",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级32强淘汰赛 巴尔豪斯11:0马丁内斯",
    "venue": "战神广场"
  },
  {
    "matchId": "45",
    "ParisTime": "10:10",
    "BeijingTime": "28日16:10",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - A组 梁伟铿/王昶2:1文迪/莱恩",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "46",
    "ParisTime": "10:10",
    "BeijingTime": "28日16:10",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - G组 李梓嘉2:0内塔辛格",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "47",
    "ParisTime": "10:10",
    "BeijingTime": "28日16:10",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - A组 贾一凡/陈清晨2:0拉哈尤 /拉马达尼",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "48",
    "ParisTime": "10:10",
    "BeijingTime": "28日16:10",
    "eventName": "赛艇",
    "matchDetail": "女子双人双桨复活赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "49",
    "ParisTime": "10:10",
    "BeijingTime": "28日16:10",
    "eventName": "赛艇",
    "matchDetail": "女子双人双桨复活赛1",
    "venue": "水上体育场"
  },
  {
    "matchId": "50",
    "ParisTime": "10:12",
    "BeijingTime": "28日16:12",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级32强淘汰赛 纳贾福夫0:1邦契奇",
    "venue": "战神广场"
  },
  {
    "matchId": "51",
    "ParisTime": "10:12",
    "BeijingTime": "28日16:12",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级32强淘汰赛 比什雷尔特·霍洛多伊10:0朱叶青",
    "venue": "战神广场"
  },
  {
    "matchId": "52",
    "ParisTime": "10:16",
    "BeijingTime": "28日16:16",
    "eventName": "射箭",
    "matchDetail": "女子团体1/8淘汰赛 印度尼西亚 VS 马来西亚",
    "venue": "荣军院"
  },
  {
    "matchId": "53",
    "ParisTime": "10:18",
    "BeijingTime": "28日16:18",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级32强淘汰赛 阿尔哈桑0:10庞拉兹",
    "venue": "战神广场"
  },
  {
    "matchId": "54",
    "ParisTime": "10:18",
    "BeijingTime": "28日16:18",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级32强淘汰赛 伊拉奥伊0:1阿斯维斯塔",
    "venue": "战神广场"
  },
  {
    "matchId": "55",
    "ParisTime": "10:20",
    "BeijingTime": "28日16:20",
    "eventName": "赛艇",
    "matchDetail": "男子双人双桨复活赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "56",
    "ParisTime": "10:20",
    "BeijingTime": "28日16:20",
    "eventName": "赛艇",
    "matchDetail": "男子双人双桨复活赛1",
    "venue": "水上体育场"
  },
  {
    "matchId": "57",
    "ParisTime": "10:24",
    "BeijingTime": "28日16:24",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级32强淘汰赛 什梅洛夫1:0博希塔",
    "venue": "战神广场"
  },
  {
    "matchId": "58",
    "ParisTime": "10:24",
    "BeijingTime": "28日16:24",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级32强淘汰赛 皮门塔10:0席尔瓦",
    "venue": "战神广场"
  },
  {
    "matchId": "59",
    "ParisTime": "10:25",
    "BeijingTime": "28日16:25",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑32强 埃里戈15:12卡坦坦",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "60",
    "ParisTime": "10:25",
    "BeijingTime": "28日16:25",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑32强 蒂布斯12:15瓦尔齐克-克利马希克",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "61",
    "ParisTime": "10:25",
    "BeijingTime": "28日16:25",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑32强 莱斯宾斯卡11:15沃尔皮",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "62",
    "ParisTime": "10:25",
    "BeijingTime": "28日16:25",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑32强 张云嘉15:7陈情缘",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "63",
    "ParisTime": "10:30",
    "BeijingTime": "28日16:30",
    "eventName": "马术",
    "matchDetail": "三项赛 - 团体及个人越野赛",
    "venue": "凡尔赛宫"
  },
  {
    "matchId": "64",
    "ParisTime": "10:30",
    "BeijingTime": "28日16:30",
    "eventName": "马术",
    "matchDetail": "三项赛 - 个人越野赛",
    "venue": "凡尔赛宫"
  },
  {
    "matchId": "65",
    "ParisTime": "10:30",
    "BeijingTime": "28日16:30",
    "eventName": "马术",
    "matchDetail": "三项赛 - 团体越野赛",
    "venue": "凡尔赛宫"
  },
  {
    "matchId": "66",
    "ParisTime": "10:30",
    "BeijingTime": "28日16:30",
    "eventName": "曲棍球",
    "matchDetail": "女子分组循环赛A组 德国2:0日本",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "67",
    "ParisTime": "10:30",
    "BeijingTime": "28日16:30",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级32强淘汰赛 拉什诺内扎德0:10奇亚",
    "venue": "战神广场"
  },
  {
    "matchId": "68",
    "ParisTime": "10:30",
    "BeijingTime": "28日16:30",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级32强淘汰赛 玛哈拉尼10:0弗雷拉",
    "venue": "战神广场"
  },
  {
    "matchId": "69",
    "ParisTime": "10:30",
    "BeijingTime": "28日16:30",
    "eventName": "赛艇",
    "matchDetail": "女子双人单桨分组预赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "70",
    "ParisTime": "10:30",
    "BeijingTime": "28日16:30",
    "eventName": "赛艇",
    "matchDetail": "女子双人单桨分组预赛第1组",
    "venue": "水上体育场"
  },
  {
    "matchId": "71",
    "ParisTime": "10:30",
    "BeijingTime": "28日16:30",
    "eventName": "水球",
    "matchDetail": "男子预赛B组 澳大利亚5:9西班牙",
    "venue": "水上运动中心"
  },
  {
    "matchId": "72",
    "ParisTime": "10:36",
    "BeijingTime": "28日16:36",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级32强淘汰赛 加西亚·托内0:1克尔吉兹巴耶夫",
    "venue": "战神广场"
  },
  {
    "matchId": "73",
    "ParisTime": "10:36",
    "BeijingTime": "28日16:36",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级32强淘汰赛 普里莫10:0郑叡璘",
    "venue": "战神广场"
  },
  {
    "matchId": "74",
    "ParisTime": "10:39",
    "BeijingTime": "28日16:39",
    "eventName": "射箭",
    "matchDetail": "女子团体1/8淘汰赛 英国 VS 德国",
    "venue": "荣军院"
  },
  {
    "matchId": "75",
    "ParisTime": "10:40",
    "BeijingTime": "28日16:40",
    "eventName": "赛艇",
    "matchDetail": "女子双人单桨分组预赛第2组",
    "venue": "水上体育场"
  },
  {
    "matchId": "76",
    "ParisTime": "10:42",
    "BeijingTime": "28日16:42",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级32强淘汰赛 安保罗10:0巴彦蒙赫·纳尔曼达赫",
    "venue": "战神广场"
  },
  {
    "matchId": "77",
    "ParisTime": "10:42",
    "BeijingTime": "28日16:42",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级32强淘汰赛 菲奥拉·索菲亚0:10恩迪亚耶",
    "venue": "战神广场"
  },
  {
    "matchId": "78",
    "ParisTime": "10:45",
    "BeijingTime": "28日16:45",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 王楚钦4:1汪洋",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "79",
    "ParisTime": "10:45",
    "BeijingTime": "28日16:45",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 高承睿4:1阿拉米安",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "80",
    "ParisTime": "10:45",
    "BeijingTime": "28日16:45",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 卡尔伯格0:4阿库拉",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "81",
    "ParisTime": "10:45",
    "BeijingTime": "28日16:45",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 杜凯琹1:4边松景",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "82",
    "ParisTime": "10:48",
    "BeijingTime": "28日16:48",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级32强淘汰赛 艾别克·乌鲁0:11亚多夫",
    "venue": "战神广场"
  },
  {
    "matchId": "83",
    "ParisTime": "10:48",
    "BeijingTime": "28日16:48",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级32强淘汰赛 马马达利耶娃0:1德尔加多",
    "venue": "战神广场"
  },
  {
    "matchId": "84",
    "ParisTime": "10:50",
    "BeijingTime": "28日16:50",
    "eventName": "赛艇",
    "matchDetail": "女子双人单桨分组预赛第3组",
    "venue": "水上体育场"
  },
  {
    "matchId": "85",
    "ParisTime": "10:54",
    "BeijingTime": "28日16:54",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级32强淘汰赛 利马1:0努里拉耶夫",
    "venue": "战神广场"
  },
  {
    "matchId": "86",
    "ParisTime": "10:54",
    "BeijingTime": "28日16:54",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级32强淘汰赛 托罗·索莱尔0:10卢卡瓦苏伦",
    "venue": "战神广场"
  },
  {
    "matchId": "87",
    "ParisTime": "10:55",
    "BeijingTime": "28日16:55",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑32强 拉舍雷15:10宫脇 花铃",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "88",
    "ParisTime": "10:55",
    "BeijingTime": "28日16:55",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑32强 哈维12:8王雨婷",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "89",
    "ParisTime": "10:55",
    "BeijingTime": "28日16:55",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑32强 卡卢加雷亚努15:13上野 由佳",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "90",
    "ParisTime": "10:55",
    "BeijingTime": "28日16:55",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑32强 杜布罗维奇12:15帕斯托尔",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "91",
    "ParisTime": "11:00",
    "BeijingTime": "28日17:00",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - N组 李诗沣2:0库恩齐",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "92",
    "ParisTime": "11:00",
    "BeijingTime": "28日17:00",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - J组 大堀彩2:0阿林",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "93",
    "ParisTime": "11:00",
    "BeijingTime": "28日17:00",
    "eventName": "羽毛球",
    "matchDetail": "混合双打小组赛 - D组 杜颐沩/陈堂杰2:0珍妮·盖/邱恺翔",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "94",
    "ParisTime": "11:00",
    "BeijingTime": "28日17:00",
    "eventName": "篮球",
    "matchDetail": "男子小组赛 - C组 南苏丹90:79波多黎各",
    "venue": "皮埃尔·莫鲁瓦体育场"
  },
  {
    "matchId": "95",
    "ParisTime": "11:00",
    "BeijingTime": "28日17:00",
    "eventName": "拳击",
    "matchDetail": "男子51公斤级 - 预赛 - 32强赛 艾哈迈迪萨法0:5希尔",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "96",
    "ParisTime": "11:00",
    "BeijingTime": "28日17:00",
    "eventName": "手球",
    "matchDetail": "女子预赛A组 韩国23:30斯洛文尼亚",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "97",
    "ParisTime": "11:00",
    "BeijingTime": "28日17:00",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级32强淘汰赛 佩德罗1:10拉希莫夫",
    "venue": "战神广场"
  },
  {
    "matchId": "98",
    "ParisTime": "11:00",
    "BeijingTime": "28日17:00",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级16强淘汰赛 克尔迪约罗娃10:1阿部 歌",
    "venue": "战神广场"
  },
  {
    "matchId": "99",
    "ParisTime": "11:00",
    "BeijingTime": "28日17:00",
    "eventName": "赛艇",
    "matchDetail": "男子双人单桨分组预赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "100",
    "ParisTime": "11:00",
    "BeijingTime": "28日17:00",
    "eventName": "赛艇",
    "matchDetail": "男子双人单桨分组预赛第1组",
    "venue": "水上体育场"
  },
  {
    "matchId": "101",
    "ParisTime": "11:00",
    "BeijingTime": "28日17:00",
    "eventName": "游泳",
    "matchDetail": "男子200米自由泳分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "102",
    "ParisTime": "11:00",
    "BeijingTime": "28日17:00",
    "eventName": "游泳",
    "matchDetail": "男子200米自由泳分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "103",
    "ParisTime": "11:00",
    "BeijingTime": "28日17:00",
    "eventName": "沙滩排球",
    "matchDetail": "女子预赛 - E组 巴西2:0日本",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "104",
    "ParisTime": "11:03",
    "BeijingTime": "28日17:03",
    "eventName": "游泳",
    "matchDetail": "男子200米自由泳分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "105",
    "ParisTime": "11:06",
    "BeijingTime": "28日17:06",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级16强淘汰赛 维耶鲁1:0萨哈",
    "venue": "战神广场"
  },
  {
    "matchId": "106",
    "ParisTime": "11:06",
    "BeijingTime": "28日17:06",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级16强淘汰赛 巴尔豪斯11:0比什雷尔特·霍洛多伊",
    "venue": "战神广场"
  },
  {
    "matchId": "107",
    "ParisTime": "11:07",
    "BeijingTime": "28日17:07",
    "eventName": "游泳",
    "matchDetail": "男子200米自由泳分组预赛第3组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "108",
    "ParisTime": "11:10",
    "BeijingTime": "28日17:10",
    "eventName": "赛艇",
    "matchDetail": "男子双人单桨分组预赛第2组",
    "venue": "水上体育场"
  },
  {
    "matchId": "109",
    "ParisTime": "11:10",
    "BeijingTime": "28日17:10",
    "eventName": "游泳",
    "matchDetail": "男子200米自由泳分组预赛第4组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "110",
    "ParisTime": "11:12",
    "BeijingTime": "28日17:12",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级16强淘汰赛 皮拉斯0:1邦契奇",
    "venue": "战神广场"
  },
  {
    "matchId": "111",
    "ParisTime": "11:12",
    "BeijingTime": "28日17:12",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级16强淘汰赛 比沙尔10:0阿斯维斯塔",
    "venue": "战神广场"
  },
  {
    "matchId": "112",
    "ParisTime": "11:15",
    "BeijingTime": "28日17:15",
    "eventName": "射击",
    "matchDetail": "男子10米气步枪资格赛",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "113",
    "ParisTime": "11:15",
    "BeijingTime": "28日17:15",
    "eventName": "游泳",
    "matchDetail": "男子400米个人混合泳分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "114",
    "ParisTime": "11:15",
    "BeijingTime": "28日17:15",
    "eventName": "游泳",
    "matchDetail": "男子400米个人混合泳分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "115",
    "ParisTime": "11:16",
    "BeijingTime": "28日17:16",
    "eventName": "拳击",
    "matchDetail": "男子71公斤级 - 预赛 - 32强赛 杜尔卡茨0:5基万",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "116",
    "ParisTime": "11:18",
    "BeijingTime": "28日17:18",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级16强淘汰赛 阿部 日布美10:0庞拉兹",
    "venue": "战神广场"
  },
  {
    "matchId": "117",
    "ParisTime": "11:18",
    "BeijingTime": "28日17:18",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级16强淘汰赛 贾尔斯0:1皮门塔",
    "venue": "战神广场"
  },
  {
    "matchId": "118",
    "ParisTime": "11:20",
    "BeijingTime": "28日17:20",
    "eventName": "赛艇",
    "matchDetail": "男子双人单桨分组预赛第3组",
    "venue": "水上体育场"
  },
  {
    "matchId": "119",
    "ParisTime": "11:21",
    "BeijingTime": "28日17:21",
    "eventName": "游泳",
    "matchDetail": "男子400米个人混合泳分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "120",
    "ParisTime": "11:24",
    "BeijingTime": "28日17:24",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级16强淘汰赛 埃莫马利1:0什梅洛夫",
    "venue": "战神广场"
  },
  {
    "matchId": "121",
    "ParisTime": "11:24",
    "BeijingTime": "28日17:24",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级16强淘汰赛 克拉斯尼奇10:0玛哈拉尼",
    "venue": "战神广场"
  },
  {
    "matchId": "122",
    "ParisTime": "11:25",
    "BeijingTime": "28日17:25",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑32强 贝尔蒂埃·阿米塔13:15斯克拉格斯",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "123",
    "ParisTime": "11:25",
    "BeijingTime": "28日17:25",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑32强 埃斯特班7:15朗维埃",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "124",
    "ParisTime": "11:25",
    "BeijingTime": "28日17:25",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑32强 陈诺思15:14东 星罗",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "125",
    "ParisTime": "11:25",
    "BeijingTime": "28日17:25",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑32强 埃尔沙尔卡维5:15黄芊芊",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "126",
    "ParisTime": "11:27",
    "BeijingTime": "28日17:27",
    "eventName": "游泳",
    "matchDetail": "女子100米蛙泳分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "127",
    "ParisTime": "11:27",
    "BeijingTime": "28日17:27",
    "eventName": "游泳",
    "matchDetail": "女子100米蛙泳分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "128",
    "ParisTime": "11:30",
    "BeijingTime": "28日17:30",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级16强淘汰赛 马尔基维拉什维利0:10奇亚",
    "venue": "战神广场"
  },
  {
    "matchId": "129",
    "ParisTime": "11:30",
    "BeijingTime": "28日17:30",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级16强淘汰赛 普里莫1:0恩迪亚耶",
    "venue": "战神广场"
  },
  {
    "matchId": "130",
    "ParisTime": "11:30",
    "BeijingTime": "28日17:30",
    "eventName": "赛艇",
    "matchDetail": "女子轻量级双人双桨分组预赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "131",
    "ParisTime": "11:30",
    "BeijingTime": "28日17:30",
    "eventName": "赛艇",
    "matchDetail": "女子轻量级双人双桨分组预赛第1组",
    "venue": "水上体育场"
  },
  {
    "matchId": "132",
    "ParisTime": "11:30",
    "BeijingTime": "28日17:30",
    "eventName": "游泳",
    "matchDetail": "女子100米蛙泳分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "133",
    "ParisTime": "11:30",
    "BeijingTime": "28日17:30",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 阿昌塔2:4科祖尔",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "134",
    "ParisTime": "11:30",
    "BeijingTime": "28日17:30",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 艾多乌3:4卡尔伯格",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "135",
    "ParisTime": "11:30",
    "BeijingTime": "28日17:30",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 维瓦雷利0:4早田希娜",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "136",
    "ParisTime": "11:30",
    "BeijingTime": "28日17:30",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 佩索特斯卡4:3帕拉南",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "137",
    "ParisTime": "11:32",
    "BeijingTime": "28日17:32",
    "eventName": "拳击",
    "matchDetail": "男子71公斤级 - 预赛 - 32强赛 特劳雷4:0沃尔什",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "138",
    "ParisTime": "11:33",
    "BeijingTime": "28日17:33",
    "eventName": "游泳",
    "matchDetail": "女子100米蛙泳分组预赛第3组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "139",
    "ParisTime": "11:36",
    "BeijingTime": "28日17:36",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级16强淘汰赛 克尔吉兹巴耶夫1:0安保罗",
    "venue": "战神广场"
  },
  {
    "matchId": "140",
    "ParisTime": "11:36",
    "BeijingTime": "28日17:36",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级16强淘汰赛 祖弗里达1:0德尔加多",
    "venue": "战神广场"
  },
  {
    "matchId": "141",
    "ParisTime": "11:36",
    "BeijingTime": "28日17:36",
    "eventName": "游泳",
    "matchDetail": "女子100米蛙泳分组预赛第4组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "142",
    "ParisTime": "11:39",
    "BeijingTime": "28日17:39",
    "eventName": "游泳",
    "matchDetail": "女子100米蛙泳分组预赛第5组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "143",
    "ParisTime": "11:40",
    "BeijingTime": "28日17:40",
    "eventName": "竞技体操",
    "matchDetail": "女子资格赛 - 分组2",
    "venue": "贝尔西体育馆"
  },
  {
    "matchId": "144",
    "ParisTime": "11:40",
    "BeijingTime": "28日17:40",
    "eventName": "赛艇",
    "matchDetail": "女子轻量级双人双桨分组预赛第2组",
    "venue": "水上体育场"
  },
  {
    "matchId": "145",
    "ParisTime": "11:42",
    "BeijingTime": "28日17:42",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级16强淘汰赛 永东佩伦莱10:0亚多夫",
    "venue": "战神广场"
  },
  {
    "matchId": "146",
    "ParisTime": "11:42",
    "BeijingTime": "28日17:42",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级16强淘汰赛 普普10:0卢卡瓦苏伦",
    "venue": "战神广场"
  },
  {
    "matchId": "147",
    "ParisTime": "11:43",
    "BeijingTime": "28日17:43",
    "eventName": "游泳",
    "matchDetail": "男子100米仰泳分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "148",
    "ParisTime": "11:43",
    "BeijingTime": "28日17:43",
    "eventName": "游泳",
    "matchDetail": "男子100米仰泳分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "149",
    "ParisTime": "11:46",
    "BeijingTime": "28日17:46",
    "eventName": "游泳",
    "matchDetail": "男子100米仰泳分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "150",
    "ParisTime": "11:48",
    "BeijingTime": "28日17:48",
    "eventName": "拳击",
    "matchDetail": "男子92公斤级 - 预赛 - 16强赛 奥拉奥雷0:5奥拉尔拜",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "151",
    "ParisTime": "11:48",
    "BeijingTime": "28日17:48",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级16强淘汰赛 利马10:0拉希莫夫",
    "venue": "战神广场"
  },
  {
    "matchId": "152",
    "ParisTime": "11:48",
    "BeijingTime": "28日17:48",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级1/4决赛 克尔迪约罗娃10:0巴尔豪斯",
    "venue": "战神广场"
  },
  {
    "matchId": "153",
    "ParisTime": "11:48",
    "BeijingTime": "28日17:48",
    "eventName": "游泳",
    "matchDetail": "男子100米仰泳分组预赛第3组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "154",
    "ParisTime": "11:50",
    "BeijingTime": "28日17:50",
    "eventName": "赛艇",
    "matchDetail": "女子轻量级双人双桨分组预赛第3组",
    "venue": "水上体育场"
  },
  {
    "matchId": "155",
    "ParisTime": "11:51",
    "BeijingTime": "28日17:51",
    "eventName": "游泳",
    "matchDetail": "男子100米仰泳分组预赛第4组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "156",
    "ParisTime": "11:54",
    "BeijingTime": "28日17:54",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级1/4决赛 维耶鲁10:0邦契奇",
    "venue": "战神广场"
  },
  {
    "matchId": "157",
    "ParisTime": "11:54",
    "BeijingTime": "28日17:54",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级1/4决赛 比沙尔1:0皮门塔",
    "venue": "战神广场"
  },
  {
    "matchId": "158",
    "ParisTime": "11:54",
    "BeijingTime": "28日17:54",
    "eventName": "游泳",
    "matchDetail": "男子100米仰泳分组预赛第5组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "159",
    "ParisTime": "11:55",
    "BeijingTime": "28日17:55",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑32强 郭子嘉15:7伊诺斯特罗萨",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "160",
    "ParisTime": "11:55",
    "BeijingTime": "28日17:55",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑32强 阿姆尔·霍斯尼5:15法瓦雷托",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "161",
    "ParisTime": "11:55",
    "BeijingTime": "28日17:55",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑32强 哈姆扎3:15绍尔",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "162",
    "ParisTime": "11:55",
    "BeijingTime": "28日17:55",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑32强 基弗15:13耶林斯卡",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "163",
    "ParisTime": "11:56",
    "BeijingTime": "28日17:56",
    "eventName": "游泳",
    "matchDetail": "男子100米仰泳分组预赛第6组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "164",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级1/4决赛 阿部 日布美10:0埃莫马利",
    "venue": "战神广场"
  },
  {
    "matchId": "165",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级1/4决赛 克拉斯尼奇10:0普里莫",
    "venue": "战神广场"
  },
  {
    "matchId": "166",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "赛艇",
    "matchDetail": "男子轻量级双人双桨预赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "167",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "赛艇",
    "matchDetail": "男子轻量级双人双桨分组预赛第1组",
    "venue": "水上体育场"
  },
  {
    "matchId": "168",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "射击",
    "matchDetail": "女子10米气手枪决赛",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "169",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "滑板",
    "matchDetail": "女子街式赛预赛",
    "venue": "协和广场"
  },
  {
    "matchId": "170",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "滑板",
    "matchDetail": "女子街式赛预赛第1组",
    "venue": "协和广场"
  },
  {
    "matchId": "171",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "游泳",
    "matchDetail": "女子200米自由泳分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "172",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "游泳",
    "matchDetail": "女子200米自由泳分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "173",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子双打第1轮 哈桑/哈比卜0:2皮尔斯/艾伯登",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "174",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子双打第1轮 齐齐帕斯1:2博尔赫斯/卡布拉尔",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "175",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子双打第1轮 丹尼尔 太郎/锦织 圭1:2埃文斯/默里",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "176",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子双打第1轮 库尔霍夫/格里克斯普尔2:0毛罗然/福乔维奇",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "177",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子双打第1轮 梅德韦杰夫/萨菲乌林0:2克拉维茨/普特兹",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "178",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 福乔维奇1:2纳达尔",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "179",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 拉奥尼奇1:2克普费尔",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "180",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 阿纳尔迪2:0菲斯",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "181",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 贝兹2:0蒙泰罗",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "182",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 波佩林2:0贾里",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "183",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 穆塞蒂2:0孟菲尔斯",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "184",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 鲁德2:0丹尼尔 太郎",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "185",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 马丁内斯1:2瓦瓦索里",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "186",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 塞伦多洛2:0巴里奥斯·维拉",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "187",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 毛罗然0:2安贝尔",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "188",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 拉约维奇1:2马特雷尔",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "189",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 卡布拉尔0:2施特鲁夫",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "190",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 穆泰2:1纳加尔",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "191",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 门西克2:0舍甫琴科",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "192",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 塔比洛0:2萨菲乌林",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "193",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 埃切维里2:0赛博特·维尔德",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "194",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "女子双打第1轮 尼库列斯库/贝古0:2曹家宜/谢淑薇",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "195",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 诺斯科娃0:2王曦雨",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "196",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 谢里夫·艾哈迈德·阿卜杜勒阿齐兹1:2沃兹尼亚奇",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "197",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 弗雷希0:2托莫娃",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "198",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 佩古拉2:0戈卢比奇",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "199",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 内岛萌夏0:2斯维托丽娜",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "200",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 王欣瑜2:0科尔帕奇",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "201",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 索比斯·托莫1:2克雷吉茨科娃",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "202",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 里内特2:0安德烈耶娃",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "203",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 萨卡里2:0科维尼奇",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "204",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 亚历山德罗娃1:2袁悦",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "205",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 布雷尔2:0西尼亚科娃",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "206",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 维基奇2:0布龙泽蒂",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "207",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 卡尔2:0玛丽亚",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "208",
    "ParisTime": "12:00",
    "BeijingTime": "28日18:00",
    "eventName": "沙滩排球",
    "matchDetail": "女子预赛 - C组 德国2:0法国",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "209",
    "ParisTime": "12:04",
    "BeijingTime": "28日18:04",
    "eventName": "拳击",
    "matchDetail": "男子92公斤级 - 预赛 - 16强赛 雷耶斯·普拉4:1韩雪振",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "210",
    "ParisTime": "12:05",
    "BeijingTime": "28日18:05",
    "eventName": "游泳",
    "matchDetail": "女子200米自由泳分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "211",
    "ParisTime": "12:05",
    "BeijingTime": "28日18:05",
    "eventName": "水球",
    "matchDetail": "男子预赛B组 塞尔维亚16:15日本",
    "venue": "水上运动中心"
  },
  {
    "matchId": "212",
    "ParisTime": "12:06",
    "BeijingTime": "28日18:06",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级1/4决赛 奇亚0:10克尔吉兹巴耶夫",
    "venue": "战神广场"
  },
  {
    "matchId": "213",
    "ParisTime": "12:06",
    "BeijingTime": "28日18:06",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级1/4决赛 祖弗里达1:0普普",
    "venue": "战神广场"
  },
  {
    "matchId": "214",
    "ParisTime": "12:08",
    "BeijingTime": "28日18:08",
    "eventName": "游泳",
    "matchDetail": "女子200米自由泳分组预赛第3组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "215",
    "ParisTime": "12:10",
    "BeijingTime": "28日18:10",
    "eventName": "赛艇",
    "matchDetail": "男子轻量级双人双桨分组预赛第2组",
    "venue": "水上体育场"
  },
  {
    "matchId": "216",
    "ParisTime": "12:12",
    "BeijingTime": "28日18:12",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级1/4决赛 永东佩伦莱0:1利马",
    "venue": "战神广场"
  },
  {
    "matchId": "217",
    "ParisTime": "12:12",
    "BeijingTime": "28日18:12",
    "eventName": "游泳",
    "matchDetail": "女子200米自由泳分组预赛第4组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "218",
    "ParisTime": "12:15",
    "BeijingTime": "28日18:15",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 王臻0:4戸上隼輔",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "219",
    "ParisTime": "12:15",
    "BeijingTime": "28日18:15",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 埃尔 - 贝阿里0:4热兹姆斯基",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "220",
    "ParisTime": "12:15",
    "BeijingTime": "28日18:15",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 曾尖4:3马洛巴比奇",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "221",
    "ParisTime": "12:15",
    "BeijingTime": "28日18:15",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 田志希0:4付玉",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "222",
    "ParisTime": "12:20",
    "BeijingTime": "28日18:20",
    "eventName": "拳击",
    "matchDetail": "女子50公斤级 - 预赛 - 32强赛 克勒策0:5尼哈特·扎林",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "223",
    "ParisTime": "12:20",
    "BeijingTime": "28日18:20",
    "eventName": "赛艇",
    "matchDetail": "男子轻量级双人双桨分组预赛第3组",
    "venue": "水上体育场"
  },
  {
    "matchId": "224",
    "ParisTime": "12:25",
    "BeijingTime": "28日18:25",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑32强 卢戈15:10科赫",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "225",
    "ParisTime": "12:25",
    "BeijingTime": "28日18:25",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑32强 贝兰8:15坎诺内",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "226",
    "ParisTime": "12:25",
    "BeijingTime": "28日18:25",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑32强 沙尔莱莫夫13:15伯雷尔",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "227",
    "ParisTime": "12:25",
    "BeijingTime": "28日18:25",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑32强 弗赖利希13:15桑塔雷利",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "228",
    "ParisTime": "12:30",
    "BeijingTime": "28日18:30",
    "eventName": "赛艇",
    "matchDetail": "女子四人单桨分组预赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "229",
    "ParisTime": "12:30",
    "BeijingTime": "28日18:30",
    "eventName": "赛艇",
    "matchDetail": "女子四人单桨分组预赛第1组",
    "venue": "水上体育场"
  },
  {
    "matchId": "230",
    "ParisTime": "12:35",
    "BeijingTime": "28日18:35",
    "eventName": "帆船",
    "matchDetail": "女子帆船49人FX级 - 比赛01",
    "venue": "马赛港"
  },
  {
    "matchId": "231",
    "ParisTime": "12:36",
    "BeijingTime": "28日18:36",
    "eventName": "拳击",
    "matchDetail": "女子50公斤级 - 预赛 - 32强赛 奥云特塞格0:5瓦伦西亚·维多利亚",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "232",
    "ParisTime": "12:40",
    "BeijingTime": "28日18:40",
    "eventName": "赛艇",
    "matchDetail": "女子四人单桨分组预赛第2组",
    "venue": "水上体育场"
  },
  {
    "matchId": "233",
    "ParisTime": "12:45",
    "BeijingTime": "28日18:45",
    "eventName": "曲棍球",
    "matchDetail": "女子分组循环赛B组 澳大利亚2:1南非",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "234",
    "ParisTime": "12:50",
    "BeijingTime": "28日18:50",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑32强 里马多·加斯孔10:15安德拉斯菲",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "235",
    "ParisTime": "12:50",
    "BeijingTime": "28日18:50",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑32强 埃尔科德13:15库尔班诺夫",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "236",
    "ParisTime": "12:50",
    "BeijingTime": "28日18:50",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑32强 箕部 和安9:8亚辛",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "237",
    "ParisTime": "12:50",
    "BeijingTime": "28日18:50",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑32强 罗德里奎7:15埃尔赛义德",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "238",
    "ParisTime": "12:50",
    "BeijingTime": "28日18:50",
    "eventName": "赛艇",
    "matchDetail": "男子四人单桨分组预赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "239",
    "ParisTime": "12:50",
    "BeijingTime": "28日18:50",
    "eventName": "赛艇",
    "matchDetail": "男子四人单桨分组预赛第1组",
    "venue": "水上体育场"
  },
  {
    "matchId": "240",
    "ParisTime": "12:52",
    "BeijingTime": "28日18:52",
    "eventName": "拳击",
    "matchDetail": "女子66公斤级 - 预赛 - 32强赛 埃克尔斯2:3雷吉尔斯卡",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "241",
    "ParisTime": "13:00",
    "BeijingTime": "28日19:00",
    "eventName": "赛艇",
    "matchDetail": "男子四人单桨分组预赛第2组",
    "venue": "水上体育场"
  },
  {
    "matchId": "242",
    "ParisTime": "13:00",
    "BeijingTime": "28日19:00",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 张禹珍4:1冈萨雷斯",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "243",
    "ParisTime": "13:00",
    "BeijingTime": "28日19:00",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 约尔吉奇4:2奎克",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "244",
    "ParisTime": "13:00",
    "BeijingTime": "28日19:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 汉夫0:4郑怡静",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "245",
    "ParisTime": "13:00",
    "BeijingTime": "28日19:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 赫西1:4巴特拉",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "246",
    "ParisTime": "13:00",
    "BeijingTime": "28日19:00",
    "eventName": "排球",
    "matchDetail": "女子预赛 - B组 波兰3:1日本",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "247",
    "ParisTime": "13:05",
    "BeijingTime": "28日19:05",
    "eventName": "滑板",
    "matchDetail": "女子街式赛预赛第2组",
    "venue": "协和广场"
  },
  {
    "matchId": "248",
    "ParisTime": "13:15",
    "BeijingTime": "28日19:15",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑32强 图伦11:15维斯马拉",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "249",
    "ParisTime": "13:15",
    "BeijingTime": "28日19:15",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑32强 王子杰15:12里马多",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "250",
    "ParisTime": "13:15",
    "BeijingTime": "28日19:15",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑32强 何瑋桁9:15山田 胜",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "251",
    "ParisTime": "13:15",
    "BeijingTime": "28日19:15",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑32强 巴亚德9:15洛约拉",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "252",
    "ParisTime": "13:15",
    "BeijingTime": "28日19:15",
    "eventName": "曲棍球",
    "matchDetail": "女子分组循环赛B组 英国1:2西班牙",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "253",
    "ParisTime": "13:15",
    "BeijingTime": "28日19:15",
    "eventName": "帆船",
    "matchDetail": "男子帆船49人级 - 比赛01",
    "venue": "马赛港"
  },
  {
    "matchId": "254",
    "ParisTime": "13:15",
    "BeijingTime": "28日19:15",
    "eventName": "帆船",
    "matchDetail": "女子帆船49人FX级 - 比赛02",
    "venue": "马赛港"
  },
  {
    "matchId": "255",
    "ParisTime": "13:30",
    "BeijingTime": "28日19:30",
    "eventName": "篮球",
    "matchDetail": "女子小组赛 - A组 西班牙90:89中国",
    "venue": "皮埃尔·莫鲁瓦体育场"
  },
  {
    "matchId": "256",
    "ParisTime": "13:40",
    "BeijingTime": "28日19:40",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑32强 米德尔顿14:15阿利姆扎诺夫",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "257",
    "ParisTime": "13:40",
    "BeijingTime": "28日19:40",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑32强 金在元12:14加野 浩树",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "258",
    "ParisTime": "13:40",
    "BeijingTime": "28日19:40",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑32强 迪·韦罗利14:10鲁伯斯",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "259",
    "ParisTime": "13:40",
    "BeijingTime": "28日19:40",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑32强 尤尔卡5:15西克洛西",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "260",
    "ParisTime": "13:55",
    "BeijingTime": "28日19:55",
    "eventName": "帆船",
    "matchDetail": "女子帆船49人FX级 - 比赛03",
    "venue": "马赛港"
  },
  {
    "matchId": "261",
    "ParisTime": "14:00",
    "BeijingTime": "28日20:00",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - B组 姜敏赫/徐承宰2:0波波夫",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "262",
    "ParisTime": "14:00",
    "BeijingTime": "28日20:00",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - H组 金廷2:0舒之颢",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "263",
    "ParisTime": "14:00",
    "BeijingTime": "28日20:00",
    "eventName": "羽毛球",
    "matchDetail": "混合双打小组赛 - A组 郑思维/黄雅琼2:0里瓦尔迪 /门塔里",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "264",
    "ParisTime": "14:00",
    "BeijingTime": "28日20:00",
    "eventName": "手球",
    "matchDetail": "女子预赛A组 瑞典31:28德国",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "265",
    "ParisTime": "14:05",
    "BeijingTime": "28日20:05",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑16强 拉舍雷6:15埃里戈",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "266",
    "ParisTime": "14:05",
    "BeijingTime": "28日20:05",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑16强 哈维15:6瓦尔齐克-克利马希克",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "267",
    "ParisTime": "14:05",
    "BeijingTime": "28日20:05",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑16强 卡卢加雷亚努9:15沃尔皮",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "268",
    "ParisTime": "14:05",
    "BeijingTime": "28日20:05",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑16强 张云嘉5:15帕斯托尔",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "269",
    "ParisTime": "14:07",
    "BeijingTime": "28日20:07",
    "eventName": "帆船",
    "matchDetail": "男子帆船49人级 - 比赛02",
    "venue": "马赛港"
  },
  {
    "matchId": "270",
    "ParisTime": "14:10",
    "BeijingTime": "28日20:10",
    "eventName": "山地自行车",
    "matchDetail": "女子越野赛",
    "venue": "埃朗库尔山"
  },
  {
    "matchId": "271",
    "ParisTime": "14:10",
    "BeijingTime": "28日20:10",
    "eventName": "滑板",
    "matchDetail": "女子街式赛预赛第3组",
    "venue": "协和广场"
  },
  {
    "matchId": "272",
    "ParisTime": "14:15",
    "BeijingTime": "28日20:15",
    "eventName": "射箭",
    "matchDetail": "女子团体1/4决赛 荷兰 VS 印度",
    "venue": "荣军院"
  },
  {
    "matchId": "273",
    "ParisTime": "14:35",
    "BeijingTime": "28日20:35",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑16强 郭子嘉11:15斯克拉格斯",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "274",
    "ParisTime": "14:35",
    "BeijingTime": "28日20:35",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑16强 法瓦雷托15:9朗维埃",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "275",
    "ParisTime": "14:35",
    "BeijingTime": "28日20:35",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑16强 绍尔15:8陈诺思",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "276",
    "ParisTime": "14:35",
    "BeijingTime": "28日20:35",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑16强 黄芊芊9:15基弗",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "277",
    "ParisTime": "14:38",
    "BeijingTime": "28日20:38",
    "eventName": "射箭",
    "matchDetail": "女子团体1/4决赛 韩国 VS 中国台北",
    "venue": "荣军院"
  },
  {
    "matchId": "278",
    "ParisTime": "14:50",
    "BeijingTime": "28日20:50",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - G组 通琼2:0布洛娃",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "279",
    "ParisTime": "14:50",
    "BeijingTime": "28日20:50",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - P组 陈雨菲2:1李怡逢",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "280",
    "ParisTime": "14:50",
    "BeijingTime": "28日20:50",
    "eventName": "羽毛球",
    "matchDetail": "混合双打小组赛 - A组 郑娜银/金元浩2:0吉凯尔 /德尔吕",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "281",
    "ParisTime": "14:50",
    "BeijingTime": "28日20:50",
    "eventName": "竞技体操",
    "matchDetail": "女子资格赛 - 分组3",
    "venue": "贝尔西体育馆"
  },
  {
    "matchId": "282",
    "ParisTime": "14:59",
    "BeijingTime": "28日20:59",
    "eventName": "帆船",
    "matchDetail": "男子帆船49人级 - 比赛03",
    "venue": "马赛港"
  },
  {
    "matchId": "283",
    "ParisTime": "15:00",
    "BeijingTime": "28日21:00",
    "eventName": "沙滩排球",
    "matchDetail": "女子预赛 - E组 荷兰2:0立陶宛",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "284",
    "ParisTime": "15:00",
    "BeijingTime": "28日21:00",
    "eventName": "水球",
    "matchDetail": "男子预赛A组 意大利12:8美国",
    "venue": "水上运动中心"
  },
  {
    "matchId": "285",
    "ParisTime": "15:01",
    "BeijingTime": "28日21:01",
    "eventName": "射箭",
    "matchDetail": "女子团体1/4决赛 墨西哥 VS 德国",
    "venue": "荣军院"
  },
  {
    "matchId": "286",
    "ParisTime": "15:05",
    "BeijingTime": "28日21:05",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑16强 安德拉斯菲13:12卢戈",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "287",
    "ParisTime": "15:05",
    "BeijingTime": "28日21:05",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑16强 库尔班诺夫15:10坎诺内",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "288",
    "ParisTime": "15:05",
    "BeijingTime": "28日21:05",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑16强 箕部 和安11:15伯雷尔",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "289",
    "ParisTime": "15:05",
    "BeijingTime": "28日21:05",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑16强 埃尔赛义德15:10桑塔雷利",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "290",
    "ParisTime": "15:08",
    "BeijingTime": "28日21:08",
    "eventName": "滑板",
    "matchDetail": "女子街式赛预赛第4组",
    "venue": "协和广场"
  },
  {
    "matchId": "291",
    "ParisTime": "15:24",
    "BeijingTime": "28日21:24",
    "eventName": "射箭",
    "matchDetail": "女子团体1/4决赛 印度尼西亚 VS 中国",
    "venue": "荣军院"
  },
  {
    "matchId": "292",
    "ParisTime": "15:30",
    "BeijingTime": "28日21:30",
    "eventName": "拳击",
    "matchDetail": "男子57公斤级 - 预赛 - 32强赛 易卜拉欣5:0阿布萨尔",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "293",
    "ParisTime": "15:30",
    "BeijingTime": "28日21:30",
    "eventName": "皮划艇激流回旋",
    "matchDetail": "女子单人皮艇半决赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "294",
    "ParisTime": "15:30",
    "BeijingTime": "28日21:30",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑16强 阿利姆扎诺夫13:14维斯马拉",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "295",
    "ParisTime": "15:30",
    "BeijingTime": "28日21:30",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑16强 王子杰4:15加野 浩树",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "296",
    "ParisTime": "15:30",
    "BeijingTime": "28日21:30",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑16强 迪·韦罗利11:15山田 胜",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "297",
    "ParisTime": "15:30",
    "BeijingTime": "28日21:30",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑16强 西克洛西13:14洛约拉",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "298",
    "ParisTime": "15:30",
    "BeijingTime": "28日21:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子分组循环赛B组 爱尔兰12:21英国",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "299",
    "ParisTime": "15:40",
    "BeijingTime": "28日21:40",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - J组 奈良冈功大2:0科埃略",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "300",
    "ParisTime": "15:40",
    "BeijingTime": "28日21:40",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - B组 刘圣书/谭宁2:1斯托伊娃",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "301",
    "ParisTime": "15:40",
    "BeijingTime": "28日21:40",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - A组 安洗莹2:0纳尔班托娃",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "302",
    "ParisTime": "15:46",
    "BeijingTime": "28日21:46",
    "eventName": "拳击",
    "matchDetail": "男子71公斤级 - 预赛 - 32强赛 申别尔格诺夫2:3伊沙什",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "303",
    "ParisTime": "15:47",
    "BeijingTime": "28日21:47",
    "eventName": "射箭",
    "matchDetail": "女子团体半决赛 韩国 VS 荷兰",
    "venue": "荣军院"
  },
  {
    "matchId": "304",
    "ParisTime": "15:55",
    "BeijingTime": "28日21:55",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑8强 斯克拉格斯15:14埃里戈",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "305",
    "ParisTime": "15:55",
    "BeijingTime": "28日21:55",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑8强 哈维15:14法瓦雷托",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "306",
    "ParisTime": "15:55",
    "BeijingTime": "28日21:55",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑8强 绍尔12:15沃尔皮",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "307",
    "ParisTime": "15:55",
    "BeijingTime": "28日21:55",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑8强 基弗15:4帕斯托尔",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "308",
    "ParisTime": "16:00",
    "BeijingTime": "28日22:00",
    "eventName": "手球",
    "matchDetail": "女子预赛A组 丹麦18:27挪威",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "309",
    "ParisTime": "16:00",
    "BeijingTime": "28日22:00",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级复活赛 邦契奇10:0埃莫马利",
    "venue": "战神广场"
  },
  {
    "matchId": "310",
    "ParisTime": "16:00",
    "BeijingTime": "28日22:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子分组循环赛B组 澳大利亚34:5南非",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "311",
    "ParisTime": "16:00",
    "BeijingTime": "28日22:00",
    "eventName": "乒乓球",
    "matchDetail": "混合双打1/4决赛 斯佐科斯/约内斯库0:4林钟勋/申裕斌",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "312",
    "ParisTime": "16:00",
    "BeijingTime": "28日22:00",
    "eventName": "乒乓球",
    "matchDetail": "混合双打1/4决赛 卡尔伯格/卡尔松1:4李郑息/金琴英",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "313",
    "ParisTime": "16:00",
    "BeijingTime": "28日22:00",
    "eventName": "沙滩排球",
    "matchDetail": "女子预赛 - A组 巴西2:0埃及",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "314",
    "ParisTime": "16:02",
    "BeijingTime": "28日22:02",
    "eventName": "拳击",
    "matchDetail": "男子92公斤级 - 预赛 - 16强赛 普洛兹基-法奥加利0:5薛斯特雷特",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "315",
    "ParisTime": "16:06",
    "BeijingTime": "28日22:06",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级复活赛 奇亚1:0永东佩伦莱",
    "venue": "战神广场"
  },
  {
    "matchId": "316",
    "ParisTime": "16:10",
    "BeijingTime": "28日22:10",
    "eventName": "射箭",
    "matchDetail": "女子团体半决赛 墨西哥 VS 中国",
    "venue": "荣军院"
  },
  {
    "matchId": "317",
    "ParisTime": "16:12",
    "BeijingTime": "28日22:12",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级B表半决赛 克尔吉兹巴耶夫0:10利马",
    "venue": "战神广场"
  },
  {
    "matchId": "318",
    "ParisTime": "16:18",
    "BeijingTime": "28日22:18",
    "eventName": "拳击",
    "matchDetail": "男子92公斤级 - 预赛 - 16强赛 阿方索·多明格斯3:2拉·克鲁兹·佩拉萨",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "319",
    "ParisTime": "16:18",
    "BeijingTime": "28日22:18",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级A表半决赛 维耶鲁0:1阿部 日布美",
    "venue": "战神广场"
  },
  {
    "matchId": "320",
    "ParisTime": "16:24",
    "BeijingTime": "28日22:24",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级复活赛 巴尔豪斯0:10皮门塔",
    "venue": "战神广场"
  },
  {
    "matchId": "321",
    "ParisTime": "16:25",
    "BeijingTime": "28日22:25",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑8强 维斯马拉13:15安德拉斯菲",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "322",
    "ParisTime": "16:25",
    "BeijingTime": "28日22:25",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑8强 加野 浩树15:6库尔班诺夫",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "323",
    "ParisTime": "16:25",
    "BeijingTime": "28日22:25",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑8强 山田 胜11:12伯雷尔",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "324",
    "ParisTime": "16:25",
    "BeijingTime": "28日22:25",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑8强 洛约拉8:9埃尔赛义德",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "325",
    "ParisTime": "16:30",
    "BeijingTime": "28日22:30",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - B组 乔姆科/凯德伦2:0克拉尔/门德雷克",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "326",
    "ParisTime": "16:30",
    "BeijingTime": "28日22:30",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - I组 周天成2:0加里多",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "327",
    "ParisTime": "16:30",
    "BeijingTime": "28日22:30",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - K组 普兰诺伊2:0罗斯",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "328",
    "ParisTime": "16:30",
    "BeijingTime": "28日22:30",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级复活赛 普里莫0:10普普",
    "venue": "战神广场"
  },
  {
    "matchId": "329",
    "ParisTime": "16:30",
    "BeijingTime": "28日22:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子分组循环赛C组 美国36:7日本",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "330",
    "ParisTime": "16:34",
    "BeijingTime": "28日22:34",
    "eventName": "拳击",
    "matchDetail": "男子92公斤级 - 预赛 - 16强赛 穆希丁1:4穆洛约诺夫",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "331",
    "ParisTime": "16:35",
    "BeijingTime": "28日22:35",
    "eventName": "水球",
    "matchDetail": "男子预赛A组 克罗地亚11:8黑山",
    "venue": "水上运动中心"
  },
  {
    "matchId": "332",
    "ParisTime": "16:36",
    "BeijingTime": "28日22:36",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级B表半决赛 克拉斯尼奇10:0祖弗里达",
    "venue": "战神广场"
  },
  {
    "matchId": "333",
    "ParisTime": "16:42",
    "BeijingTime": "28日22:42",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级A表半决赛 克尔迪约罗娃1:0比沙尔",
    "venue": "战神广场"
  },
  {
    "matchId": "334",
    "ParisTime": "16:48",
    "BeijingTime": "28日22:48",
    "eventName": "射箭",
    "matchDetail": "女子团体铜牌赛 荷兰 VS 墨西哥",
    "venue": "荣军院"
  },
  {
    "matchId": "335",
    "ParisTime": "16:48",
    "BeijingTime": "28日22:48",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级铜牌争夺赛A 邦契奇0:1克尔吉兹巴耶夫",
    "venue": "战神广场"
  },
  {
    "matchId": "336",
    "ParisTime": "16:50",
    "BeijingTime": "28日22:50",
    "eventName": "拳击",
    "matchDetail": "女子50公斤级 - 预赛 - 32强赛 索伦蒂诺1:4克扎伊拜",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "337",
    "ParisTime": "16:54",
    "BeijingTime": "28日22:54",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级铜牌争夺赛B 奇亚0:1维耶鲁",
    "venue": "战神广场"
  },
  {
    "matchId": "338",
    "ParisTime": "17:00",
    "BeijingTime": "28日23:00",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛A组 德国0:2西班牙",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "339",
    "ParisTime": "17:00",
    "BeijingTime": "28日23:00",
    "eventName": "柔道",
    "matchDetail": "男子66公斤级决赛 阿部 日布美10:0利马",
    "venue": "战神广场"
  },
  {
    "matchId": "340",
    "ParisTime": "17:00",
    "BeijingTime": "28日23:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子分组循环赛C组 法国26:0巴西",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "341",
    "ParisTime": "17:00",
    "BeijingTime": "28日23:00",
    "eventName": "滑板",
    "matchDetail": "女子街式赛决赛",
    "venue": "协和广场"
  },
  {
    "matchId": "342",
    "ParisTime": "17:00",
    "BeijingTime": "28日23:00",
    "eventName": "乒乓球",
    "matchDetail": "混合双打1/4决赛 孙颖莎/王楚钦4:2林昀儒/陈思羽",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "343",
    "ParisTime": "17:00",
    "BeijingTime": "28日23:00",
    "eventName": "乒乓球",
    "matchDetail": "混合双打1/4决赛 杜凯琹/黄镇廷4:2玛丽亚·肖/罗布莱斯",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "344",
    "ParisTime": "17:00",
    "BeijingTime": "28日23:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - E组 捷克2:0加拿大",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "345",
    "ParisTime": "17:00",
    "BeijingTime": "28日23:00",
    "eventName": "排球",
    "matchDetail": "男子预赛 - A组 法国3:2塞尔维亚",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "346",
    "ParisTime": "17:00",
    "BeijingTime": "28日23:00",
    "eventName": "足球",
    "matchDetail": "女子A组 新西兰0:2哥伦比亚",
    "venue": "里昂体育场"
  },
  {
    "matchId": "347",
    "ParisTime": "17:00",
    "BeijingTime": "28日23:00",
    "eventName": "足球",
    "matchDetail": "女子C组 巴西1:2日本",
    "venue": "王子公园"
  },
  {
    "matchId": "348",
    "ParisTime": "17:06",
    "BeijingTime": "28日23:06",
    "eventName": "拳击",
    "matchDetail": "女子50公斤级 - 预赛 - 32强赛 埃雷拉3:2富尔特斯·费尔南德斯",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "349",
    "ParisTime": "17:06",
    "BeijingTime": "28日23:06",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级铜牌争夺赛A 皮门塔10:0祖弗里达",
    "venue": "战神广场"
  },
  {
    "matchId": "350",
    "ParisTime": "17:11",
    "BeijingTime": "28日23:11",
    "eventName": "射箭",
    "matchDetail": "女子团体金牌赛 韩国 VS 中国",
    "venue": "荣军院"
  },
  {
    "matchId": "351",
    "ParisTime": "17:12",
    "BeijingTime": "28日23:12",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级铜牌争夺赛B 普普0:1比沙尔",
    "venue": "战神广场"
  },
  {
    "matchId": "352",
    "ParisTime": "17:15",
    "BeijingTime": "28日23:15",
    "eventName": "篮球",
    "matchDetail": "男子小组赛 - C组 塞尔维亚84:110美国",
    "venue": "皮埃尔·莫鲁瓦体育场"
  },
  {
    "matchId": "353",
    "ParisTime": "17:18",
    "BeijingTime": "28日23:18",
    "eventName": "柔道",
    "matchDetail": "女子52公斤级决赛 克尔迪约罗娃1:0克拉斯尼奇",
    "venue": "战神广场"
  },
  {
    "matchId": "354",
    "ParisTime": "17:22",
    "BeijingTime": "28日23:22",
    "eventName": "拳击",
    "matchDetail": "女子66公斤级 - 预赛 - 32强赛 沃尔什1:4哈森",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "355",
    "ParisTime": "17:30",
    "BeijingTime": "28日23:30",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛B组 比利时2:1新西兰",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "356",
    "ParisTime": "17:30",
    "BeijingTime": "28日23:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子分组循环赛A组 斐济14:17加拿大",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "357",
    "ParisTime": "17:45",
    "BeijingTime": "28日23:45",
    "eventName": "皮划艇激流回旋",
    "matchDetail": "女子单人皮艇决赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "358",
    "ParisTime": "18:00",
    "BeijingTime": "29日00:00",
    "eventName": "竞技体操",
    "matchDetail": "女子资格赛 - 分组4",
    "venue": "贝尔西体育馆"
  },
  {
    "matchId": "359",
    "ParisTime": "18:00",
    "BeijingTime": "29日00:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子分组循环赛A组 新西兰43:5中国",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "360",
    "ParisTime": "19:00",
    "BeijingTime": "29日01:00",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑半决赛1 哈维9:15斯克拉格斯",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "361",
    "ParisTime": "19:00",
    "BeijingTime": "29日01:00",
    "eventName": "手球",
    "matchDetail": "女子预赛B组 安哥拉26:21西班牙",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "362",
    "ParisTime": "19:00",
    "BeijingTime": "29日01:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子分组循环赛B组 爱尔兰38:0南非",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "363",
    "ParisTime": "19:00",
    "BeijingTime": "29日01:00",
    "eventName": "冲浪",
    "matchDetail": "女子第2轮 - 第1组 阿吉雷 VS 杨思琪",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "364",
    "ParisTime": "19:00",
    "BeijingTime": "29日01:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 兹维列夫2:0穆纳尔",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "365",
    "ParisTime": "19:00",
    "BeijingTime": "29日01:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 托姆利亚诺维奇0:2高夫",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "366",
    "ParisTime": "19:00",
    "BeijingTime": "29日01:00",
    "eventName": "足球",
    "matchDetail": "女子B组 澳大利亚6:5赞比亚",
    "venue": "尼斯体育场"
  },
  {
    "matchId": "367",
    "ParisTime": "19:00",
    "BeijingTime": "29日01:00",
    "eventName": "足球",
    "matchDetail": "女子C组 西班牙1:0尼日利亚",
    "venue": "博茹瓦尔体育场"
  },
  {
    "matchId": "368",
    "ParisTime": "19:30",
    "BeijingTime": "29日01:30",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - E组 安东森2:0菲利蒙",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "369",
    "ParisTime": "19:30",
    "BeijingTime": "29日01:30",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - L组 马林2:0斯塔德曼",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "370",
    "ParisTime": "19:30",
    "BeijingTime": "29日01:30",
    "eventName": "羽毛球",
    "matchDetail": "混合双打小组赛 - B组 普瓦拉努克罗/泰拉塔纳柴2:0马梅里",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "371",
    "ParisTime": "19:30",
    "BeijingTime": "29日01:30",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑半决赛2 基弗15:10沃尔皮",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "372",
    "ParisTime": "19:30",
    "BeijingTime": "29日01:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子分组循环赛B组 澳大利亚36:5英国",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "373",
    "ParisTime": "19:30",
    "BeijingTime": "29日01:30",
    "eventName": "水球",
    "matchDetail": "男子预赛 - B组 法国12:13匈牙利",
    "venue": "水上运动中心"
  },
  {
    "matchId": "374",
    "ParisTime": "19:36",
    "BeijingTime": "29日01:36",
    "eventName": "冲浪",
    "matchDetail": "女子第2轮 - 第2组 鲍姆 VS 坎普",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "375",
    "ParisTime": "19:45",
    "BeijingTime": "29日01:45",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛A组 荷兰4:0法国",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "376",
    "ParisTime": "20:00",
    "BeijingTime": "29日02:00",
    "eventName": "拳击",
    "matchDetail": "男子71公斤级 - 预赛 - 32强赛 SCHACHIDOV Magomed Elim Sultanovic1:4穆桑加",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "377",
    "ParisTime": "20:00",
    "BeijingTime": "29日02:00",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑半决赛1 加野 浩树14:13安德拉斯菲",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "378",
    "ParisTime": "20:00",
    "BeijingTime": "29日02:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子分组循环赛C组 美国24:5巴西",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "379",
    "ParisTime": "20:00",
    "BeijingTime": "29日02:00",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 勒布伦4:0德赛·哈米特",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "380",
    "ParisTime": "20:00",
    "BeijingTime": "29日02:00",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 亚范纳多1:4林昀儒",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "381",
    "ParisTime": "20:00",
    "BeijingTime": "29日02:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 维加0:4张墨",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "382",
    "ParisTime": "20:00",
    "BeijingTime": "29日02:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 张安4:0萨哈基安",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "383",
    "ParisTime": "20:00",
    "BeijingTime": "29日02:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - E组 巴西2:0奥地利",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "384",
    "ParisTime": "20:12",
    "BeijingTime": "29日02:12",
    "eventName": "冲浪",
    "matchDetail": "女子第2轮 - 第3组 松田 志野 VS 邦瓦洛",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "385",
    "ParisTime": "20:15",
    "BeijingTime": "29日02:15",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛A组 南非2:2英国",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "386",
    "ParisTime": "20:16",
    "BeijingTime": "29日02:16",
    "eventName": "拳击",
    "matchDetail": "男子92公斤级 - 预赛 - 16强赛 布朗1:4马查多",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "387",
    "ParisTime": "20:20",
    "BeijingTime": "29日02:20",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - D组 拉斯姆森/阿斯特鲁普1:2李洋/王齐麟",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "388",
    "ParisTime": "20:20",
    "BeijingTime": "29日02:20",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - M组 骆建佑2:0劳达",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "389",
    "ParisTime": "20:20",
    "BeijingTime": "29日02:20",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - B组 杨雅婷/杨霈霖2:1许忻霏/许怡霏",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "390",
    "ParisTime": "20:25",
    "BeijingTime": "29日02:25",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑半决赛2 埃尔赛义德9:15伯雷尔",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "391",
    "ParisTime": "20:30",
    "BeijingTime": "29日02:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子分组循环赛C组 法国49:0日本",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "392",
    "ParisTime": "20:30",
    "BeijingTime": "29日02:30",
    "eventName": "游泳",
    "matchDetail": "男子400米个人混合泳决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "393",
    "ParisTime": "20:32",
    "BeijingTime": "29日02:32",
    "eventName": "拳击",
    "matchDetail": "男子92公斤级 - 预赛 - 16强赛 马利4:1贝雷兹尼基",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "394",
    "ParisTime": "20:40",
    "BeijingTime": "29日02:40",
    "eventName": "游泳",
    "matchDetail": "女子100米蝶泳决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "395",
    "ParisTime": "20:46",
    "BeijingTime": "29日02:46",
    "eventName": "游泳",
    "matchDetail": "男子200米自由泳半决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "396",
    "ParisTime": "20:46",
    "BeijingTime": "29日02:46",
    "eventName": "游泳",
    "matchDetail": "男子200米自由泳半决赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "397",
    "ParisTime": "20:48",
    "BeijingTime": "29日02:48",
    "eventName": "拳击",
    "matchDetail": "男子92公斤级 - 预赛 - 16强赛 库希塔什维利2:3博尔塔耶夫",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "398",
    "ParisTime": "20:48",
    "BeijingTime": "29日02:48",
    "eventName": "冲浪",
    "matchDetail": "女子第2轮 - 第4组 德菲 VS 皮克勒姆",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "399",
    "ParisTime": "20:50",
    "BeijingTime": "29日02:50",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑铜牌赛 哈维15:12沃尔皮",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "400",
    "ParisTime": "20:53",
    "BeijingTime": "29日02:53",
    "eventName": "游泳",
    "matchDetail": "男子200米自由泳半决赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "401",
    "ParisTime": "21:00",
    "BeijingTime": "29日03:00",
    "eventName": "篮球",
    "matchDetail": "女子小组赛 - A组 塞尔维亚58:55波多黎各",
    "venue": "皮埃尔·莫鲁瓦体育场"
  },
  {
    "matchId": "402",
    "ParisTime": "21:00",
    "BeijingTime": "29日03:00",
    "eventName": "手球",
    "matchDetail": "女子预赛B组 法国32:28荷兰",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "403",
    "ParisTime": "21:00",
    "BeijingTime": "29日03:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子分组循环赛A组 斐济12:40中国",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "404",
    "ParisTime": "21:00",
    "BeijingTime": "29日03:00",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 佩雷拉0:4卡尔德拉诺",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "405",
    "ParisTime": "21:00",
    "BeijingTime": "29日03:00",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 吉奥尼斯4:0李元璋",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "406",
    "ParisTime": "21:00",
    "BeijingTime": "29日03:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 科西奥·阿塞维斯0:4波尔卡诺娃",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "407",
    "ParisTime": "21:00",
    "BeijingTime": "29日03:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 杨晓欣2:4玛塔洛瓦",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "408",
    "ParisTime": "21:00",
    "BeijingTime": "29日03:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - B组 挪威2:0智利",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "409",
    "ParisTime": "21:00",
    "BeijingTime": "29日03:00",
    "eventName": "排球",
    "matchDetail": "男子预赛 - A组 斯洛文尼亚3:1加拿大",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "410",
    "ParisTime": "21:00",
    "BeijingTime": "29日03:00",
    "eventName": "足球",
    "matchDetail": "女子A组 法国1:2加拿大",
    "venue": "热奥弗鲁瓦基查尔体育场"
  },
  {
    "matchId": "411",
    "ParisTime": "21:00",
    "BeijingTime": "29日03:00",
    "eventName": "足球",
    "matchDetail": "女子B组 美国4:1德国",
    "venue": "马赛体育场"
  },
  {
    "matchId": "412",
    "ParisTime": "21:04",
    "BeijingTime": "29日03:04",
    "eventName": "拳击",
    "matchDetail": "女子50公斤级 - 预赛 - 32强赛 凯沃-奥亚5:0坦博",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "413",
    "ParisTime": "21:05",
    "BeijingTime": "29日03:05",
    "eventName": "水球",
    "matchDetail": "男子预赛A组 罗马尼亚7:14希腊",
    "venue": "水上运动中心"
  },
  {
    "matchId": "414",
    "ParisTime": "21:10",
    "BeijingTime": "29日03:10",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - D组 刘雨辰/欧烜屹2:0约书亚·袁/邱恺翔",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "415",
    "ParisTime": "21:10",
    "BeijingTime": "29日03:10",
    "eventName": "羽毛球",
    "matchDetail": "混合双打小组赛 - B组 徐承宰/蔡侑玎2:0塔贝林/皮克",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "416",
    "ParisTime": "21:10",
    "BeijingTime": "29日03:10",
    "eventName": "羽毛球",
    "matchDetail": "混合双打小组赛 - C组 东野 有纱/渡边勇大2:0叶宏蔚/李佳馨",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "417",
    "ParisTime": "21:10",
    "BeijingTime": "29日03:10",
    "eventName": "竞技体操",
    "matchDetail": "女子资格赛 - 分组5",
    "venue": "贝尔西体育馆"
  },
  {
    "matchId": "418",
    "ParisTime": "21:10",
    "BeijingTime": "29日03:10",
    "eventName": "游泳",
    "matchDetail": "女子100米蛙泳半决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "419",
    "ParisTime": "21:10",
    "BeijingTime": "29日03:10",
    "eventName": "游泳",
    "matchDetail": "女子100米蛙泳半决赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "420",
    "ParisTime": "21:16",
    "BeijingTime": "29日03:16",
    "eventName": "游泳",
    "matchDetail": "女子100米蛙泳半决赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "421",
    "ParisTime": "21:20",
    "BeijingTime": "29日03:20",
    "eventName": "拳击",
    "matchDetail": "女子50公斤级 - 预赛 - 32强赛 穆塔基0:5维莱加斯",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "422",
    "ParisTime": "21:20",
    "BeijingTime": "29日03:20",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑铜牌赛 埃尔赛义德8:7安德拉斯菲",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "423",
    "ParisTime": "21:24",
    "BeijingTime": "29日03:24",
    "eventName": "冲浪",
    "matchDetail": "女子第2轮 - 第5组 韦斯顿-韦伯 VS 雷萨诺",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "424",
    "ParisTime": "21:30",
    "BeijingTime": "29日03:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子分组循环赛A组 新西兰33:7加拿大",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "425",
    "ParisTime": "21:32",
    "BeijingTime": "29日03:32",
    "eventName": "游泳",
    "matchDetail": "男子100米仰泳半决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "426",
    "ParisTime": "21:32",
    "BeijingTime": "29日03:32",
    "eventName": "游泳",
    "matchDetail": "男子100米仰泳半决赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "427",
    "ParisTime": "21:36",
    "BeijingTime": "29日03:36",
    "eventName": "拳击",
    "matchDetail": "女子66公斤级 - 预赛 - 32强赛 莫龙塔·埃尔南德斯1:4陈念琴",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "428",
    "ParisTime": "21:38",
    "BeijingTime": "29日03:38",
    "eventName": "游泳",
    "matchDetail": "男子100米仰泳半决赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "429",
    "ParisTime": "21:44",
    "BeijingTime": "29日03:44",
    "eventName": "游泳",
    "matchDetail": "男子100米蛙泳决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "430",
    "ParisTime": "21:45",
    "BeijingTime": "29日03:45",
    "eventName": "击剑",
    "matchDetail": "女子个人花剑金牌赛 斯克拉格斯6:15基弗",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "431",
    "ParisTime": "21:50",
    "BeijingTime": "29日03:50",
    "eventName": "游泳",
    "matchDetail": "女子200米自由泳半决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "432",
    "ParisTime": "21:50",
    "BeijingTime": "29日03:50",
    "eventName": "游泳",
    "matchDetail": "女子200米自由泳半决赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "433",
    "ParisTime": "21:52",
    "BeijingTime": "29日03:52",
    "eventName": "拳击",
    "matchDetail": "女子66公斤级 - 预赛 - 32强赛 多斯桑托斯5:0杰西卡",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "434",
    "ParisTime": "21:57",
    "BeijingTime": "29日03:57",
    "eventName": "游泳",
    "matchDetail": "女子200米自由泳半决赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "435",
    "ParisTime": "22:00",
    "BeijingTime": "29日04:00",
    "eventName": "冲浪",
    "matchDetail": "女子第2轮 - 第6组 维特 VS 霍普金斯",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "436",
    "ParisTime": "22:00",
    "BeijingTime": "29日04:00",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 阿波洛尼亚1:4邱党",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "437",
    "ParisTime": "22:00",
    "BeijingTime": "29日04:00",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 勒布伦4:0洛伦佐",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "438",
    "ParisTime": "22:00",
    "BeijingTime": "29日04:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 梅什雷夫4:1玛丽亚·肖",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "439",
    "ParisTime": "22:00",
    "BeijingTime": "29日04:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 埃德姆0:4塔卡哈什",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "440",
    "ParisTime": "22:00",
    "BeijingTime": "29日04:00",
    "eventName": "沙滩排球",
    "matchDetail": "女子预赛 - C组 美国2:0捷克",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "441",
    "ParisTime": "22:15",
    "BeijingTime": "29日04:15",
    "eventName": "击剑",
    "matchDetail": "男子个人重剑金牌赛 伯雷尔9:15加野 浩树",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "442",
    "ParisTime": "22:36",
    "BeijingTime": "29日04:36",
    "eventName": "冲浪",
    "matchDetail": "女子第2轮 - 第7组 欣克尔 VS 登普弗勒-奥林",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "443",
    "ParisTime": "23:12",
    "BeijingTime": "29日05:12",
    "eventName": "冲浪",
    "matchDetail": "女子第2轮 - 第8组 勒利奥 VS 冈萨雷斯·埃特萨巴里",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "444",
    "ParisTime": "23:48",
    "BeijingTime": "29日05:48",
    "eventName": "冲浪",
    "matchDetail": "男子第2轮 - 第1组 菲奥拉万蒂 VS 五十岚卡诺阿",
    "venue": "特乎普欧，大溪地"
  },
];
export default matchData;