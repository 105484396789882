/* eslint-disable no-unused-vars */
const matchData = [
  {
    "matchId": "1",
    "ParisTime": "08:30",
    "BeijingTime": "30日14:30",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - G组 阿比扬2:0内塔辛格",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "2",
    "ParisTime": "08:30",
    "BeijingTime": "30日14:30",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - E组 因塔农2:0谭莲妮",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "3",
    "ParisTime": "08:30",
    "BeijingTime": "30日14:30",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - M组 库巴2:0阿卜杜勒·拉扎克",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "4",
    "ParisTime": "09:00",
    "BeijingTime": "30日15:00",
    "eventName": "手球",
    "matchDetail": "女子预赛A组 德国41:22斯洛文尼亚",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "5",
    "ParisTime": "09:00",
    "BeijingTime": "30日15:00",
    "eventName": "射击",
    "matchDetail": "男子多向飞碟资格赛 - 第2天",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "6",
    "ParisTime": "09:00",
    "BeijingTime": "30日15:00",
    "eventName": "射击",
    "matchDetail": "女子多向飞碟资格赛",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "7",
    "ParisTime": "09:00",
    "BeijingTime": "30日15:00",
    "eventName": "射击",
    "matchDetail": "女子多向飞碟资格赛 - 第1天",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "8",
    "ParisTime": "09:00",
    "BeijingTime": "30日15:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - C组 波兰2:0澳大利亚",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "9",
    "ParisTime": "09:00",
    "BeijingTime": "30日15:00",
    "eventName": "排球",
    "matchDetail": "男子预赛 - B组 意大利3:0埃及",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "10",
    "ParisTime": "09:20",
    "BeijingTime": "30日15:20",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - A组 贾一凡/陈清晨2:0松本麻佑/永原和可那",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "11",
    "ParisTime": "09:20",
    "BeijingTime": "30日15:20",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - B组 斯托伊娃2:0许忻霏/许怡霏",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "12",
    "ParisTime": "09:20",
    "BeijingTime": "30日15:20",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - J组 卡斯蒂略0:2阿林",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "13",
    "ParisTime": "09:30",
    "BeijingTime": "30日15:30",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨1/4决赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "14",
    "ParisTime": "09:30",
    "BeijingTime": "30日15:30",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨1/4决赛1",
    "venue": "水上体育场"
  },
  {
    "matchId": "15",
    "ParisTime": "09:30",
    "BeijingTime": "30日15:30",
    "eventName": "射击",
    "matchDetail": "混合团体10米气手枪决赛",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "16",
    "ParisTime": "09:30",
    "BeijingTime": "30日15:30",
    "eventName": "射击",
    "matchDetail": "混合团体10米气手枪金牌赛 土耳其 VS 塞尔维亚",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "17",
    "ParisTime": "09:30",
    "BeijingTime": "30日15:30",
    "eventName": "射击",
    "matchDetail": "混合团体10米气手枪铜牌赛 印度 VS 韩国",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "18",
    "ParisTime": "09:40",
    "BeijingTime": "30日15:40",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨1/4决赛2",
    "venue": "水上体育场"
  },
  {
    "matchId": "19",
    "ParisTime": "09:50",
    "BeijingTime": "30日15:50",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨1/4决赛3",
    "venue": "水上体育场"
  },
  {
    "matchId": "20",
    "ParisTime": "10:00",
    "BeijingTime": "30日16:00",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛A组 西班牙3:3法国",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "21",
    "ParisTime": "10:00",
    "BeijingTime": "30日16:00",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级64强淘汰赛 贾洛1:0阿卜杜勒加尼",
    "venue": "战神广场"
  },
  {
    "matchId": "22",
    "ParisTime": "10:00",
    "BeijingTime": "30日16:00",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级32强淘汰赛 蒂莫0:10金智秀",
    "venue": "战神广场"
  },
  {
    "matchId": "23",
    "ParisTime": "10:00",
    "BeijingTime": "30日16:00",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨1/4决赛4",
    "venue": "水上体育场"
  },
  {
    "matchId": "24",
    "ParisTime": "10:00",
    "BeijingTime": "30日16:00",
    "eventName": "乒乓球",
    "matchDetail": "男子单打32强赛 科祖尔2:4戸上隼輔",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "25",
    "ParisTime": "10:00",
    "BeijingTime": "30日16:00",
    "eventName": "乒乓球",
    "matchDetail": "男子单打32强赛 艾什1:4奥恰洛夫",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "26",
    "ParisTime": "10:00",
    "BeijingTime": "30日16:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打32强赛 斯佐科斯4:1佩索特斯卡",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "27",
    "ParisTime": "10:00",
    "BeijingTime": "30日16:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打32强赛 伯格斯特罗姆1:4陈梦",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "28",
    "ParisTime": "10:00",
    "BeijingTime": "30日16:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - C组 德国2:0法国",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "29",
    "ParisTime": "10:06",
    "BeijingTime": "30日16:06",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级32强淘汰赛 卡斯11:1阿拉博",
    "venue": "战神广场"
  },
  {
    "matchId": "30",
    "ParisTime": "10:06",
    "BeijingTime": "30日16:06",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级32强淘汰赛 高市 未来10:0戴尔·托罗·卡瓦哈尔",
    "venue": "战神广场"
  },
  {
    "matchId": "31",
    "ParisTime": "10:10",
    "BeijingTime": "30日16:10",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - H组 波波夫2:0舒之颢",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "32",
    "ParisTime": "10:10",
    "BeijingTime": "30日16:10",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - A组 拉哈尤 /拉马达尼0:2蒂娜/陈康乐",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "33",
    "ParisTime": "10:10",
    "BeijingTime": "30日16:10",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - B组 刘圣书/谭宁2:0杨雅婷/杨霈霖",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "34",
    "ParisTime": "10:10",
    "BeijingTime": "30日16:10",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨1/4决赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "35",
    "ParisTime": "10:10",
    "BeijingTime": "30日16:10",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨1/4决赛1",
    "venue": "水上体育场"
  },
  {
    "matchId": "36",
    "ParisTime": "10:12",
    "BeijingTime": "30日16:12",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级32强淘汰赛 朱巴纳扎尔0:1翁格瓦里",
    "venue": "战神广场"
  },
  {
    "matchId": "37",
    "ParisTime": "10:12",
    "BeijingTime": "30日16:12",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级32强淘汰赛 吉门德戈0:10克里斯托",
    "venue": "战神广场"
  },
  {
    "matchId": "38",
    "ParisTime": "10:18",
    "BeijingTime": "30日16:18",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级32强淘汰赛 长濑 孝则10:0阿普拉哈米安",
    "venue": "战神广场"
  },
  {
    "matchId": "39",
    "ParisTime": "10:18",
    "BeijingTime": "30日16:18",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级32强淘汰赛 希曼斯卡1:0鲁索",
    "venue": "战神广场"
  },
  {
    "matchId": "40",
    "ParisTime": "10:20",
    "BeijingTime": "30日16:20",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨1/4决赛2",
    "venue": "水上体育场"
  },
  {
    "matchId": "41",
    "ParisTime": "10:24",
    "BeijingTime": "30日16:24",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级32强淘汰赛 格贝科夫0:10阿尔巴伊拉克",
    "venue": "战神广场"
  },
  {
    "matchId": "42",
    "ParisTime": "10:24",
    "BeijingTime": "30日16:24",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级32强淘汰赛 沙欣0:10阿维蒂·阿尔卡拉斯",
    "venue": "战神广场"
  },
  {
    "matchId": "43",
    "ParisTime": "10:30",
    "BeijingTime": "30日16:30",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛A组 南非1:5德国",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "44",
    "ParisTime": "10:30",
    "BeijingTime": "30日16:30",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级32强淘汰赛 施密特11:0塞里福夫斯基",
    "venue": "战神广场"
  },
  {
    "matchId": "45",
    "ParisTime": "10:30",
    "BeijingTime": "30日16:30",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级32强淘汰赛 黑克尔1:11伦肖尔",
    "venue": "战神广场"
  },
  {
    "matchId": "46",
    "ParisTime": "10:30",
    "BeijingTime": "30日16:30",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨1/4决赛3",
    "venue": "水上体育场"
  },
  {
    "matchId": "47",
    "ParisTime": "10:30",
    "BeijingTime": "30日16:30",
    "eventName": "水球",
    "matchDetail": "男子预赛 - B组 澳大利亚8:3塞尔维亚",
    "venue": "水上运动中心"
  },
  {
    "matchId": "48",
    "ParisTime": "10:36",
    "BeijingTime": "30日16:36",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级32强淘汰赛 埃斯波西托10:0胡依那托",
    "venue": "战神广场"
  },
  {
    "matchId": "49",
    "ParisTime": "10:36",
    "BeijingTime": "30日16:36",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级32强淘汰赛 皮奥瓦萨娜1:0库尤洛娃",
    "venue": "战神广场"
  },
  {
    "matchId": "50",
    "ParisTime": "10:40",
    "BeijingTime": "30日16:40",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨1/4决赛4",
    "venue": "水上体育场"
  },
  {
    "matchId": "51",
    "ParisTime": "10:42",
    "BeijingTime": "30日16:42",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级32强淘汰赛 戈蒂埃·德拉波10:0费尔南多",
    "venue": "战神广场"
  },
  {
    "matchId": "52",
    "ParisTime": "10:42",
    "BeijingTime": "30日16:42",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级32强淘汰赛 扎霍娃0:1奥兹巴斯",
    "venue": "战神广场"
  },
  {
    "matchId": "53",
    "ParisTime": "10:48",
    "BeijingTime": "30日16:48",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级32强淘汰赛 塔塔拉什维利·努加扎里0:11甘迪亚",
    "venue": "战神广场"
  },
  {
    "matchId": "54",
    "ParisTime": "10:48",
    "BeijingTime": "30日16:48",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级32强淘汰赛 莱斯基10:0阿斯基拉什维利",
    "venue": "战神广场"
  },
  {
    "matchId": "55",
    "ParisTime": "10:50",
    "BeijingTime": "30日16:50",
    "eventName": "赛艇",
    "matchDetail": "女子双人双桨半决赛 A/B 1",
    "venue": "水上体育场"
  },
  {
    "matchId": "56",
    "ParisTime": "10:54",
    "BeijingTime": "30日16:54",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级32强淘汰赛 格里加拉什维利10:0拉蒂谢夫",
    "venue": "战神广场"
  },
  {
    "matchId": "57",
    "ParisTime": "10:54",
    "BeijingTime": "30日16:54",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级32强淘汰赛 巴里奥斯0:1贝尔卡迪",
    "venue": "战神广场"
  },
  {
    "matchId": "58",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - G组 斯瓦比科娃1:2布洛娃",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "59",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "篮球",
    "matchDetail": "男子小组赛 - A组 西班牙84:77希腊",
    "venue": "皮埃尔·莫鲁瓦体育场"
  },
  {
    "matchId": "60",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "拳击",
    "matchDetail": "男子51公斤级 - 预赛 - 16强赛 本纳玛3:2希尔",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "61",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "马术",
    "matchDetail": "盛装舞步团体和个人大奖赛预赛 - 第1天",
    "venue": "凡尔赛宫"
  },
  {
    "matchId": "62",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "马术",
    "matchDetail": "个人盛装舞步大奖赛 - 第1天",
    "venue": "凡尔赛宫"
  },
  {
    "matchId": "63",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "马术",
    "matchDetail": "盛装舞步团体大奖赛 - 第1天",
    "venue": "凡尔赛宫"
  },
  {
    "matchId": "64",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "手球",
    "matchDetail": "女子预赛A组 挪威26:20韩国",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "65",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级32强淘汰赛 博尔查维利11:0法伊扎德·穆罕默德·萨米姆",
    "venue": "战神广场"
  },
  {
    "matchId": "66",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级32强淘汰赛 唐婧10:0渡边圣未",
    "venue": "战神广场"
  },
  {
    "matchId": "67",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "赛艇",
    "matchDetail": "女子双人双桨半决赛 A/B 2",
    "venue": "水上体育场"
  },
  {
    "matchId": "68",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "游泳",
    "matchDetail": "男子200米蝶泳分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "69",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "游泳",
    "matchDetail": "男子200米蝶泳分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "70",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "乒乓球",
    "matchDetail": "男子单打32强赛 罗布莱斯2:4卡尔德拉诺",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "71",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "乒乓球",
    "matchDetail": "男子单打32强赛 加奇纳0:4林昀儒",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "72",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打32强赛 付玉3:4巴约尔",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "73",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打32强赛 边松景4:3米特尔汉",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "74",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "沙滩排球",
    "matchDetail": "女子预赛 - A组 意大利2:0埃及",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "75",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - D组 李洋/王齐麟2:0约书亚·袁/邱恺翔",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "76",
    "ParisTime": "11:00",
    "BeijingTime": "30日17:00",
    "eventName": "马术",
    "matchDetail": "Dressage Individual Grand Prix Group A",
    "venue": "凡尔赛宫"
  },
  {
    "matchId": "77",
    "ParisTime": "11:04",
    "BeijingTime": "30日17:04",
    "eventName": "游泳",
    "matchDetail": "男子200米蝶泳分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "78",
    "ParisTime": "11:06",
    "BeijingTime": "30日17:06",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级32强淘汰赛 马赫马德贝科夫10:0巴达维",
    "venue": "战神广场"
  },
  {
    "matchId": "79",
    "ParisTime": "11:06",
    "BeijingTime": "30日17:06",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级32强淘汰赛 阿格班尼奥1:0沙里尔",
    "venue": "战神广场"
  },
  {
    "matchId": "80",
    "ParisTime": "11:08",
    "BeijingTime": "30日17:08",
    "eventName": "游泳",
    "matchDetail": "男子200米蝶泳分组预赛第3组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "81",
    "ParisTime": "11:10",
    "BeijingTime": "30日17:10",
    "eventName": "赛艇",
    "matchDetail": "男子双人双桨半决赛 A/B 1",
    "venue": "水上体育场"
  },
  {
    "matchId": "82",
    "ParisTime": "11:12",
    "BeijingTime": "30日17:12",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级32强淘汰赛 库姆博0:10德维特",
    "venue": "战神广场"
  },
  {
    "matchId": "83",
    "ParisTime": "11:12",
    "BeijingTime": "30日17:12",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级32强淘汰赛 卡瓦纳·佩雷斯0:10夸德罗斯",
    "venue": "战神广场"
  },
  {
    "matchId": "84",
    "ParisTime": "11:12",
    "BeijingTime": "30日17:12",
    "eventName": "游泳",
    "matchDetail": "男子200米蝶泳分组预赛第4组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "85",
    "ParisTime": "11:16",
    "BeijingTime": "30日17:16",
    "eventName": "拳击",
    "matchDetail": "男子51公斤级 - 预赛 - 16强赛 克拉罗·菲兹5:0达·席尔瓦·特林达德",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "86",
    "ParisTime": "11:17",
    "BeijingTime": "30日17:17",
    "eventName": "游泳",
    "matchDetail": "男子100米自由泳分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "87",
    "ParisTime": "11:17",
    "BeijingTime": "30日17:17",
    "eventName": "游泳",
    "matchDetail": "男子100米自由泳分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "88",
    "ParisTime": "11:18",
    "BeijingTime": "30日17:18",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级32强淘汰赛 李俊奂1:0穆蒂",
    "venue": "战神广场"
  },
  {
    "matchId": "89",
    "ParisTime": "11:18",
    "BeijingTime": "30日17:18",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级16强淘汰赛 范利绍特0:1金智秀",
    "venue": "战神广场"
  },
  {
    "matchId": "90",
    "ParisTime": "11:20",
    "BeijingTime": "30日17:20",
    "eventName": "赛艇",
    "matchDetail": "男子双人双桨半决赛 A/B 2",
    "venue": "水上体育场"
  },
  {
    "matchId": "91",
    "ParisTime": "11:21",
    "BeijingTime": "30日17:21",
    "eventName": "游泳",
    "matchDetail": "男子100米自由泳分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "92",
    "ParisTime": "11:24",
    "BeijingTime": "30日17:24",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级32强淘汰赛 卡维利乌斯 0:10穆基",
    "venue": "战神广场"
  },
  {
    "matchId": "93",
    "ParisTime": "11:24",
    "BeijingTime": "30日17:24",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级16强淘汰赛 高市 未来0:1克里斯托",
    "venue": "战神广场"
  },
  {
    "matchId": "94",
    "ParisTime": "11:24",
    "BeijingTime": "30日17:24",
    "eventName": "游泳",
    "matchDetail": "男子100米自由泳分组预赛第3组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "95",
    "ParisTime": "11:27",
    "BeijingTime": "30日17:27",
    "eventName": "游泳",
    "matchDetail": "男子100米自由泳分组预赛第4组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "96",
    "ParisTime": "11:30",
    "BeijingTime": "30日17:30",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级32强淘汰赛 特卡耶夫0:10贾洛",
    "venue": "战神广场"
  },
  {
    "matchId": "97",
    "ParisTime": "11:30",
    "BeijingTime": "30日17:30",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级16强淘汰赛 希曼斯卡0:1阿维蒂·阿尔卡拉斯",
    "venue": "战神广场"
  },
  {
    "matchId": "98",
    "ParisTime": "11:30",
    "BeijingTime": "30日17:30",
    "eventName": "赛艇",
    "matchDetail": "女子四人单桨复活赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "99",
    "ParisTime": "11:30",
    "BeijingTime": "30日17:30",
    "eventName": "赛艇",
    "matchDetail": "女子四人单桨复活赛1",
    "venue": "水上体育场"
  },
  {
    "matchId": "100",
    "ParisTime": "11:30",
    "BeijingTime": "30日17:30",
    "eventName": "游泳",
    "matchDetail": "男子100米自由泳分组预赛第5组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "101",
    "ParisTime": "11:32",
    "BeijingTime": "30日17:32",
    "eventName": "拳击",
    "matchDetail": "男子51公斤级 - 预赛 - 16强赛 乔西亚1:4洛萨诺·塞拉诺",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "102",
    "ParisTime": "11:33",
    "BeijingTime": "30日17:33",
    "eventName": "游泳",
    "matchDetail": "男子100米自由泳分组预赛第6组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "103",
    "ParisTime": "11:36",
    "BeijingTime": "30日17:36",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级32强淘汰赛 博尔塔博耶夫10:0塞亨 萨贾德",
    "venue": "战神广场"
  },
  {
    "matchId": "104",
    "ParisTime": "11:36",
    "BeijingTime": "30日17:36",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级16强淘汰赛 伦肖尔0:1皮奥瓦萨娜",
    "venue": "战神广场"
  },
  {
    "matchId": "105",
    "ParisTime": "11:36",
    "BeijingTime": "30日17:36",
    "eventName": "游泳",
    "matchDetail": "男子100米自由泳分组预赛第7组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "106",
    "ParisTime": "11:39",
    "BeijingTime": "30日17:39",
    "eventName": "游泳",
    "matchDetail": "男子100米自由泳分组预赛第8组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "107",
    "ParisTime": "11:40",
    "BeijingTime": "30日17:40",
    "eventName": "赛艇",
    "matchDetail": "男子四人单桨复活赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "108",
    "ParisTime": "11:40",
    "BeijingTime": "30日17:40",
    "eventName": "赛艇",
    "matchDetail": "男子四人单桨复活赛1",
    "venue": "水上体育场"
  },
  {
    "matchId": "109",
    "ParisTime": "11:42",
    "BeijingTime": "30日17:42",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级16强淘汰赛 卡斯10:0翁格瓦里",
    "venue": "战神广场"
  },
  {
    "matchId": "110",
    "ParisTime": "11:42",
    "BeijingTime": "30日17:42",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级16强淘汰赛 博舍曼-皮纳尔10:0奥兹巴斯",
    "venue": "战神广场"
  },
  {
    "matchId": "111",
    "ParisTime": "11:42",
    "BeijingTime": "30日17:42",
    "eventName": "游泳",
    "matchDetail": "男子100米自由泳分组预赛第9组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "112",
    "ParisTime": "11:45",
    "BeijingTime": "30日17:45",
    "eventName": "游泳",
    "matchDetail": "男子100米自由泳分组预赛第10组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "113",
    "ParisTime": "11:48",
    "BeijingTime": "30日17:48",
    "eventName": "拳击",
    "matchDetail": "男子80公斤级 - 预赛 - 16强赛 托合塔尔别克·唐拉提汗0:5皮纳莱斯",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "114",
    "ParisTime": "11:48",
    "BeijingTime": "30日17:48",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级16强淘汰赛 长濑 孝则1:0阿尔巴伊拉克",
    "venue": "战神广场"
  },
  {
    "matchId": "115",
    "ParisTime": "11:48",
    "BeijingTime": "30日17:48",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级16强淘汰赛 莱斯基10:1贝尔卡迪",
    "venue": "战神广场"
  },
  {
    "matchId": "116",
    "ParisTime": "11:51",
    "BeijingTime": "30日17:51",
    "eventName": "游泳",
    "matchDetail": "女子1500米自由泳分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "117",
    "ParisTime": "11:51",
    "BeijingTime": "30日17:51",
    "eventName": "游泳",
    "matchDetail": "女子1500米自由泳分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "118",
    "ParisTime": "11:54",
    "BeijingTime": "30日17:54",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级16强淘汰赛 施密特0:1埃斯波西托",
    "venue": "战神广场"
  },
  {
    "matchId": "119",
    "ParisTime": "11:54",
    "BeijingTime": "30日17:54",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级16强淘汰赛 法兹利乌10:0唐婧",
    "venue": "战神广场"
  },
  {
    "matchId": "120",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "射箭",
    "matchDetail": "男子个人1/32淘汰赛 奇罗 VS 埃尔南德斯·维拉",
    "venue": "荣军院"
  },
  {
    "matchId": "121",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级16强淘汰赛 戈蒂埃·德拉波10:0甘迪亚",
    "venue": "战神广场"
  },
  {
    "matchId": "122",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级16强淘汰赛 阿格班尼奥1:0夸德罗斯",
    "venue": "战神广场"
  },
  {
    "matchId": "123",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "网球",
    "matchDetail": "男子双打第2轮 埃文斯/默里2:1吉勒/弗利根",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "124",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "网球",
    "matchDetail": "男子双打第2轮 纳达尔/阿尔卡拉斯2:1库尔霍夫/格里克斯普尔",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "125",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "网球",
    "matchDetail": "男子双打第2轮 孟菲尔斯/罗歇-瓦瑟兰0:2克拉维茨/普特兹",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "126",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第2轮 兹维列夫2:0马查克",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "127",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第2轮 瓦林卡0:2波佩林",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "128",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第2轮 德雷珀1:2弗里茨",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "129",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第2轮 奥热-阿利亚西姆2:0马特雷尔",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "130",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第2轮 施特鲁夫 VS 穆泰",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "131",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "网球",
    "matchDetail": "女子双打第2轮 保利尼/埃拉尼2:1加西亚/帕里",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "132",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "网球",
    "matchDetail": "女子双打第1轮 帕帕米查尔/萨卡里0:2克劳奇克/柯林斯",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "133",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第3轮 奥索里奥1:2柯林斯",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "134",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第3轮 科贝尔2:0费尔南德斯",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "135",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第3轮 纳瓦罗1:2郑钦文",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "136",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第3轮 斯维托丽娜1:2克雷吉茨科娃",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "137",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第3轮 施米德洛娃2:1保利尼",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "138",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第3轮 萨卡里1:2科斯秋克",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "139",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第3轮 维基奇2:0高夫",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "140",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "网球",
    "matchDetail": "混合双打第1轮 弗里茨/高夫2:1冈萨雷斯/波多罗斯卡",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "141",
    "ParisTime": "12:00",
    "BeijingTime": "30日18:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - D组 巴西0:2古巴",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "142",
    "ParisTime": "12:04",
    "BeijingTime": "30日18:04",
    "eventName": "拳击",
    "matchDetail": "男子80公斤级 - 预赛 - 16强赛 伊阿希什0:5维奥契奇",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "143",
    "ParisTime": "12:05",
    "BeijingTime": "30日18:05",
    "eventName": "帆船",
    "matchDetail": "女子帆船49人FX级 - 比赛07",
    "venue": "马赛港"
  },
  {
    "matchId": "144",
    "ParisTime": "12:05",
    "BeijingTime": "30日18:05",
    "eventName": "水球",
    "matchDetail": "男子预赛A组 克罗地亚11:14意大利",
    "venue": "水上运动中心"
  },
  {
    "matchId": "145",
    "ParisTime": "12:06",
    "BeijingTime": "30日18:06",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级16强淘汰赛 格里加拉什维利1:0博尔查维利",
    "venue": "战神广场"
  },
  {
    "matchId": "146",
    "ParisTime": "12:06",
    "BeijingTime": "30日18:06",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级1/4决赛 金智秀0:10克里斯托",
    "venue": "战神广场"
  },
  {
    "matchId": "147",
    "ParisTime": "12:10",
    "BeijingTime": "30日18:10",
    "eventName": "游泳",
    "matchDetail": "女子1500米自由泳分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "148",
    "ParisTime": "12:12",
    "BeijingTime": "30日18:12",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级16强淘汰赛 马赫马德贝科夫1:0德维特",
    "venue": "战神广场"
  },
  {
    "matchId": "149",
    "ParisTime": "12:12",
    "BeijingTime": "30日18:12",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级1/4决赛 阿维蒂·阿尔卡拉斯1:0皮奥瓦萨娜",
    "venue": "战神广场"
  },
  {
    "matchId": "150",
    "ParisTime": "12:13",
    "BeijingTime": "30日18:13",
    "eventName": "射箭",
    "matchDetail": "男子个人1/32淘汰赛 泰科涅米 VS 戴宇轩",
    "venue": "荣军院"
  },
  {
    "matchId": "151",
    "ParisTime": "12:13",
    "BeijingTime": "30日18:13",
    "eventName": "帆船",
    "matchDetail": "男子水翼帆板iQFoil级 - 比赛02",
    "venue": "马赛港"
  },
  {
    "matchId": "152",
    "ParisTime": "12:18",
    "BeijingTime": "30日18:18",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级16强淘汰赛 李俊奂10:0穆基",
    "venue": "战神广场"
  },
  {
    "matchId": "153",
    "ParisTime": "12:18",
    "BeijingTime": "30日18:18",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级1/4决赛 博舍曼-皮纳尔0:1莱斯基",
    "venue": "战神广场"
  },
  {
    "matchId": "154",
    "ParisTime": "12:20",
    "BeijingTime": "30日18:20",
    "eventName": "拳击",
    "matchDetail": "女子54公斤级 - 预赛 - 16强赛 彼得洛娃4:1黄筱雯",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "155",
    "ParisTime": "12:24",
    "BeijingTime": "30日18:24",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级16强淘汰赛 贾洛0:10博尔塔博耶夫",
    "venue": "战神广场"
  },
  {
    "matchId": "156",
    "ParisTime": "12:24",
    "BeijingTime": "30日18:24",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级1/4决赛 法兹利乌0:10阿格班尼奥",
    "venue": "战神广场"
  },
  {
    "matchId": "157",
    "ParisTime": "12:26",
    "BeijingTime": "30日18:26",
    "eventName": "射箭",
    "matchDetail": "女子个人1/32淘汰赛 斯里亚克蒂卡斯·卡埃塔诺 VS 平塔里奇",
    "venue": "荣军院"
  },
  {
    "matchId": "158",
    "ParisTime": "12:29",
    "BeijingTime": "30日18:29",
    "eventName": "游泳",
    "matchDetail": "女子1500米自由泳分组预赛第3组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "159",
    "ParisTime": "12:30",
    "BeijingTime": "30日18:30",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级1/4决赛 卡斯0:1长濑 孝则",
    "venue": "战神广场"
  },
  {
    "matchId": "160",
    "ParisTime": "12:33",
    "BeijingTime": "30日18:33",
    "eventName": "帆船",
    "matchDetail": "女子水翼帆板iQFoil级 - 比赛03",
    "venue": "马赛港"
  },
  {
    "matchId": "161",
    "ParisTime": "12:36",
    "BeijingTime": "30日18:36",
    "eventName": "拳击",
    "matchDetail": "女子54公斤级 - 预赛 - 16强赛 莱汉0:5常园",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "162",
    "ParisTime": "12:36",
    "BeijingTime": "30日18:36",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级1/4决赛 埃斯波西托10:0戈蒂埃·德拉波",
    "venue": "战神广场"
  },
  {
    "matchId": "163",
    "ParisTime": "12:39",
    "BeijingTime": "30日18:39",
    "eventName": "射箭",
    "matchDetail": "女子个人1/32淘汰赛 米尔卡 VS 马沙伊赫",
    "venue": "荣军院"
  },
  {
    "matchId": "164",
    "ParisTime": "12:42",
    "BeijingTime": "30日18:42",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级1/4决赛 格里加拉什维利1:0马赫马德贝科夫",
    "venue": "战神广场"
  },
  {
    "matchId": "165",
    "ParisTime": "12:44",
    "BeijingTime": "30日18:44",
    "eventName": "帆船",
    "matchDetail": "男子水翼帆板iQFoil级 - 比赛03",
    "venue": "马赛港"
  },
  {
    "matchId": "166",
    "ParisTime": "12:45",
    "BeijingTime": "30日18:45",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛A组 英国2:2荷兰",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "167",
    "ParisTime": "12:45",
    "BeijingTime": "30日18:45",
    "eventName": "帆船",
    "matchDetail": "女子帆船49人FX级 - 比赛08",
    "venue": "马赛港"
  },
  {
    "matchId": "168",
    "ParisTime": "12:47",
    "BeijingTime": "30日18:47",
    "eventName": "游泳",
    "matchDetail": "女子100米自由泳分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "169",
    "ParisTime": "12:47",
    "BeijingTime": "30日18:47",
    "eventName": "游泳",
    "matchDetail": "女子100米自由泳分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "170",
    "ParisTime": "12:48",
    "BeijingTime": "30日18:48",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级1/4决赛 李俊奂10:0博尔塔博耶夫",
    "venue": "战神广场"
  },
  {
    "matchId": "171",
    "ParisTime": "12:51",
    "BeijingTime": "30日18:51",
    "eventName": "游泳",
    "matchDetail": "女子100米自由泳分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "172",
    "ParisTime": "12:52",
    "BeijingTime": "30日18:52",
    "eventName": "射箭",
    "matchDetail": "男子个人1/16淘汰赛 奇罗 VS 戴宇轩",
    "venue": "荣军院"
  },
  {
    "matchId": "173",
    "ParisTime": "12:52",
    "BeijingTime": "30日18:52",
    "eventName": "拳击",
    "matchDetail": "女子54公斤级 - 预赛 - 16强赛 贝尔塔尔3:2吉蓬",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "174",
    "ParisTime": "12:54",
    "BeijingTime": "30日18:54",
    "eventName": "游泳",
    "matchDetail": "女子100米自由泳分组预赛第3组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "175",
    "ParisTime": "12:57",
    "BeijingTime": "30日18:57",
    "eventName": "游泳",
    "matchDetail": "女子100米自由泳分组预赛第4组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "176",
    "ParisTime": "13:00",
    "BeijingTime": "30日19:00",
    "eventName": "网球",
    "matchDetail": "男子单打第2轮 穆塞蒂2:0纳沃内",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "177",
    "ParisTime": "13:00",
    "BeijingTime": "30日19:00",
    "eventName": "网球",
    "matchDetail": "男子单打第2轮 门西克0:2保罗",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "178",
    "ParisTime": "13:00",
    "BeijingTime": "30日19:00",
    "eventName": "网球",
    "matchDetail": "女子双打第2轮 曹家宜/谢淑薇 VS 科斯秋克/雅斯特雷姆斯卡",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "179",
    "ParisTime": "13:00",
    "BeijingTime": "30日19:00",
    "eventName": "网球",
    "matchDetail": "女子双打第1轮 博尔特/沃森2:0科贝尔/西格蒙德",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "180",
    "ParisTime": "13:00",
    "BeijingTime": "30日19:00",
    "eventName": "网球",
    "matchDetail": "混合双打第1轮 沃森/索尔兹伯里1:2奥热-阿利亚西姆/达布罗夫斯基",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "181",
    "ParisTime": "13:00",
    "BeijingTime": "30日19:00",
    "eventName": "排球",
    "matchDetail": "男子预赛 - C组 美国3:2德国",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "182",
    "ParisTime": "13:00",
    "BeijingTime": "30日19:00",
    "eventName": "马术",
    "matchDetail": "Dressage Individual Grand Prix Group B",
    "venue": "凡尔赛宫"
  },
  {
    "matchId": "183",
    "ParisTime": "13:01",
    "BeijingTime": "30日19:01",
    "eventName": "游泳",
    "matchDetail": "男子200米蛙泳分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "184",
    "ParisTime": "13:01",
    "BeijingTime": "30日19:01",
    "eventName": "游泳",
    "matchDetail": "男子200米蛙泳分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "185",
    "ParisTime": "13:04",
    "BeijingTime": "30日19:04",
    "eventName": "帆船",
    "matchDetail": "女子水翼帆板iQFoil级 - 比赛04",
    "venue": "马赛港"
  },
  {
    "matchId": "186",
    "ParisTime": "13:05",
    "BeijingTime": "30日19:05",
    "eventName": "射箭",
    "matchDetail": "女子个人1/16淘汰赛 斯里亚克蒂卡斯·卡埃塔诺 VS 马沙伊赫",
    "venue": "荣军院"
  },
  {
    "matchId": "187",
    "ParisTime": "13:06",
    "BeijingTime": "30日19:06",
    "eventName": "游泳",
    "matchDetail": "男子200米蛙泳分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "188",
    "ParisTime": "13:08",
    "BeijingTime": "30日19:08",
    "eventName": "拳击",
    "matchDetail": "女子57公斤级 - 预赛 - 32强赛 萨科比2:3图尔季别科娃",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "189",
    "ParisTime": "13:10",
    "BeijingTime": "30日19:10",
    "eventName": "游泳",
    "matchDetail": "男子200米蛙泳分组预赛第3组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "190",
    "ParisTime": "13:15",
    "BeijingTime": "30日19:15",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛B组 爱尔兰0:2印度",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "191",
    "ParisTime": "13:15",
    "BeijingTime": "30日19:15",
    "eventName": "帆船",
    "matchDetail": "男子水翼帆板iQFoil级 - 比赛04",
    "venue": "马赛港"
  },
  {
    "matchId": "192",
    "ParisTime": "13:15",
    "BeijingTime": "30日19:15",
    "eventName": "游泳",
    "matchDetail": "男子200米蛙泳分组预赛第4组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "193",
    "ParisTime": "13:18",
    "BeijingTime": "30日19:18",
    "eventName": "射箭",
    "matchDetail": "男子个人1/32淘汰赛 戴尔梅达 VS 乌萨奇",
    "venue": "荣军院"
  },
  {
    "matchId": "194",
    "ParisTime": "13:20",
    "BeijingTime": "30日19:20",
    "eventName": "游泳",
    "matchDetail": "男子4x200米自由泳接力分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "195",
    "ParisTime": "13:20",
    "BeijingTime": "30日19:20",
    "eventName": "游泳",
    "matchDetail": "男子4x200米自由泳接力分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "196",
    "ParisTime": "13:24",
    "BeijingTime": "30日19:24",
    "eventName": "拳击",
    "matchDetail": "女子57公斤级 - 预赛 - 32强赛 耶尔迪兹·卡赫拉曼5:0卡马拉",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "197",
    "ParisTime": "13:25",
    "BeijingTime": "30日19:25",
    "eventName": "自由式小轮车",
    "matchDetail": "女子公园赛预赛",
    "venue": "协和广场"
  },
  {
    "matchId": "198",
    "ParisTime": "13:25",
    "BeijingTime": "30日19:25",
    "eventName": "自由式小轮车",
    "matchDetail": "女子公园赛预赛第1组",
    "venue": "协和广场"
  },
  {
    "matchId": "199",
    "ParisTime": "13:25",
    "BeijingTime": "30日19:25",
    "eventName": "帆船",
    "matchDetail": "女子帆船49人FX级 - 比赛09",
    "venue": "马赛港"
  },
  {
    "matchId": "200",
    "ParisTime": "13:30",
    "BeijingTime": "30日19:30",
    "eventName": "篮球",
    "matchDetail": "男子小组赛 - A组 加拿大93:83澳大利亚",
    "venue": "皮埃尔·莫鲁瓦体育场"
  },
  {
    "matchId": "201",
    "ParisTime": "13:30",
    "BeijingTime": "30日19:30",
    "eventName": "击剑",
    "matchDetail": "女子团体重剑8强 意大利39:26埃及",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "202",
    "ParisTime": "13:30",
    "BeijingTime": "30日19:30",
    "eventName": "击剑",
    "matchDetail": "女子团体重剑8强 中国45:41乌克兰",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "203",
    "ParisTime": "13:30",
    "BeijingTime": "30日19:30",
    "eventName": "击剑",
    "matchDetail": "女子团体重剑8强 波兰31:29美国",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "204",
    "ParisTime": "13:30",
    "BeijingTime": "30日19:30",
    "eventName": "击剑",
    "matchDetail": "女子团体重剑8强 韩国31:37法国",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "205",
    "ParisTime": "13:30",
    "BeijingTime": "30日19:30",
    "eventName": "游泳",
    "matchDetail": "男子4x200米自由泳接力分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "206",
    "ParisTime": "13:30",
    "BeijingTime": "30日19:30",
    "eventName": "网球",
    "matchDetail": "男子双打第2轮 卡雷诺·布斯塔/格拉诺勒斯0:2皮尔斯/艾伯登",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "207",
    "ParisTime": "13:30",
    "BeijingTime": "30日19:30",
    "eventName": "网球",
    "matchDetail": "男子双打第2轮 蒙泰罗/赛博特·维尔德0:2克拉吉塞克/拉姆",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "208",
    "ParisTime": "13:30",
    "BeijingTime": "30日19:30",
    "eventName": "网球",
    "matchDetail": "男子双打第2轮 帕夫拉塞克/马查克2:1塔比洛/贾里",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "209",
    "ParisTime": "13:30",
    "BeijingTime": "30日19:30",
    "eventName": "网球",
    "matchDetail": "女子双打第2轮 布克沙/索比斯·托莫2:0卡尔/波多罗斯卡",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "210",
    "ParisTime": "13:30",
    "BeijingTime": "30日19:30",
    "eventName": "网球",
    "matchDetail": "女子双打第1轮 安德烈耶娃/施奈德2:1托姆利亚诺维奇/盖德茨基",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "211",
    "ParisTime": "13:30",
    "BeijingTime": "30日19:30",
    "eventName": "网球",
    "matchDetail": "女子双打第1轮 詹咏然 /詹皓晴0:2克雷吉茨科娃/西尼亚科娃",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "212",
    "ParisTime": "13:30",
    "BeijingTime": "30日19:30",
    "eventName": "网球",
    "matchDetail": "混合双打第1轮 斯特凡尼/赛博特·维尔德1:2张之臻/王欣瑜",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "213",
    "ParisTime": "13:30",
    "BeijingTime": "30日19:30",
    "eventName": "乒乓球",
    "matchDetail": "混合双打铜牌赛 林钟勋/申裕斌4:0杜凯琹/黄镇廷",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "214",
    "ParisTime": "13:31",
    "BeijingTime": "30日19:31",
    "eventName": "射箭",
    "matchDetail": "男子个人1/32淘汰赛 斋藤史弥 VS 达莫尔",
    "venue": "荣军院"
  },
  {
    "matchId": "215",
    "ParisTime": "13:35",
    "BeijingTime": "30日19:35",
    "eventName": "帆船",
    "matchDetail": "女子水翼帆板iQFoil级 - 比赛05",
    "venue": "马赛港"
  },
  {
    "matchId": "216",
    "ParisTime": "13:44",
    "BeijingTime": "30日19:44",
    "eventName": "射箭",
    "matchDetail": "女子个人1/32淘汰赛 巴哈特 VS 米瑟",
    "venue": "荣军院"
  },
  {
    "matchId": "217",
    "ParisTime": "13:46",
    "BeijingTime": "30日19:46",
    "eventName": "帆船",
    "matchDetail": "男子水翼帆板iQFoil级 - 比赛05",
    "venue": "马赛港"
  },
  {
    "matchId": "218",
    "ParisTime": "13:57",
    "BeijingTime": "30日19:57",
    "eventName": "射箭",
    "matchDetail": "女子个人1/32淘汰赛 卡迈勒 VS 考尔",
    "venue": "荣军院"
  },
  {
    "matchId": "219",
    "ParisTime": "14:00",
    "BeijingTime": "30日20:00",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - C组 兰基雷迪/谢蒂2:0阿尔菲安/阿迪安托",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "220",
    "ParisTime": "14:00",
    "BeijingTime": "30日20:00",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - C组 志田千阳/松山奈未0:2孔熙容/金昭映",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "221",
    "ParisTime": "14:00",
    "BeijingTime": "30日20:00",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - A组 齐雪霏0:2纳尔班托娃",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "222",
    "ParisTime": "14:00",
    "BeijingTime": "30日20:00",
    "eventName": "手球",
    "matchDetail": "女子预赛B组 荷兰29:24西班牙",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "223",
    "ParisTime": "14:06",
    "BeijingTime": "30日20:06",
    "eventName": "自由式小轮车",
    "matchDetail": "女子公园赛预赛第2组",
    "venue": "协和广场"
  },
  {
    "matchId": "224",
    "ParisTime": "14:06",
    "BeijingTime": "30日20:06",
    "eventName": "帆船",
    "matchDetail": "女子水翼帆板iQFoil级 - 比赛06",
    "venue": "马赛港"
  },
  {
    "matchId": "225",
    "ParisTime": "14:10",
    "BeijingTime": "30日20:10",
    "eventName": "射箭",
    "matchDetail": "男子个人1/16淘汰赛 戴尔梅达 VS 斋藤史弥",
    "venue": "荣军院"
  },
  {
    "matchId": "226",
    "ParisTime": "14:17",
    "BeijingTime": "30日20:17",
    "eventName": "帆船",
    "matchDetail": "男子水翼帆板iQFoil级 - 比赛06",
    "venue": "马赛港"
  },
  {
    "matchId": "227",
    "ParisTime": "14:23",
    "BeijingTime": "30日20:23",
    "eventName": "射箭",
    "matchDetail": "女子个人1/16淘汰赛 米瑟 VS 考尔",
    "venue": "荣军院"
  },
  {
    "matchId": "228",
    "ParisTime": "14:30",
    "BeijingTime": "30日20:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子5-8名排名赛 中国19:15英国",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "229",
    "ParisTime": "14:30",
    "BeijingTime": "30日20:30",
    "eventName": "乒乓球",
    "matchDetail": "混合双打金牌赛 孙颖莎/王楚钦4:2李郑息/金琴英",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "230",
    "ParisTime": "14:35",
    "BeijingTime": "30日20:35",
    "eventName": "帆船",
    "matchDetail": "男子帆船49人级 - 比赛07",
    "venue": "马赛港"
  },
  {
    "matchId": "231",
    "ParisTime": "14:36",
    "BeijingTime": "30日20:36",
    "eventName": "射箭",
    "matchDetail": "男子个人1/32淘汰赛 金优镇 VS 马达耶",
    "venue": "荣军院"
  },
  {
    "matchId": "232",
    "ParisTime": "14:37",
    "BeijingTime": "30日20:37",
    "eventName": "帆船",
    "matchDetail": "女子水翼帆板iQFoil级 - 比赛07",
    "venue": "马赛港"
  },
  {
    "matchId": "233",
    "ParisTime": "14:49",
    "BeijingTime": "30日20:49",
    "eventName": "射箭",
    "matchDetail": "男子个人1/32淘汰赛 阿查·冈萨雷斯 VS 林子翔",
    "venue": "荣军院"
  },
  {
    "matchId": "234",
    "ParisTime": "14:50",
    "BeijingTime": "30日20:50",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - C组 塞德尔/拉姆斯富斯 VS 劳役/拉巴尔",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "235",
    "ParisTime": "14:50",
    "BeijingTime": "30日20:50",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - J组 全奕陈2:0科埃略",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "236",
    "ParisTime": "14:50",
    "BeijingTime": "30日20:50",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - C组 克拉斯托/蓬纳帕0:2于亚杰/玛帕萨",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "237",
    "ParisTime": "14:50",
    "BeijingTime": "30日20:50",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - P组 布里西费尔特2:1李怡逢",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "238",
    "ParisTime": "14:55",
    "BeijingTime": "30日20:55",
    "eventName": "马术",
    "matchDetail": "Dressage Individual Grand Prix Group C",
    "venue": "凡尔赛宫"
  },
  {
    "matchId": "239",
    "ParisTime": "15:00",
    "BeijingTime": "30日21:00",
    "eventName": "皮划艇激流回旋",
    "matchDetail": "女子单人划艇预赛 - 第1轮和第2轮",
    "venue": "水上体育场"
  },
  {
    "matchId": "240",
    "ParisTime": "15:00",
    "BeijingTime": "30日21:00",
    "eventName": "皮划艇激流回旋",
    "matchDetail": "女子单人划艇预赛第1轮",
    "venue": "水上体育场"
  },
  {
    "matchId": "241",
    "ParisTime": "15:00",
    "BeijingTime": "30日21:00",
    "eventName": "击剑",
    "matchDetail": "女子团体重剑5-8名排名赛 乌克兰45:31埃及",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "242",
    "ParisTime": "15:00",
    "BeijingTime": "30日21:00",
    "eventName": "击剑",
    "matchDetail": "女子团体重剑5-8名排名赛 韩国45:39美国",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "243",
    "ParisTime": "15:00",
    "BeijingTime": "30日21:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子5-8名排名赛 法国19:7爱尔兰",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "244",
    "ParisTime": "15:00",
    "BeijingTime": "30日21:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - D组 美国2:0摩洛哥",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "245",
    "ParisTime": "15:00",
    "BeijingTime": "30日21:00",
    "eventName": "水球",
    "matchDetail": "男子预赛 - B组 日本13:14法国",
    "venue": "水上运动中心"
  },
  {
    "matchId": "246",
    "ParisTime": "15:00",
    "BeijingTime": "30日21:00",
    "eventName": "足球",
    "matchDetail": "男子C组 多米尼加1:1乌兹别克斯坦",
    "venue": "王子公园"
  },
  {
    "matchId": "247",
    "ParisTime": "15:00",
    "BeijingTime": "30日21:00",
    "eventName": "足球",
    "matchDetail": "男子C组 西班牙1:2埃及",
    "venue": "波尔多体育场"
  },
  {
    "matchId": "248",
    "ParisTime": "15:02",
    "BeijingTime": "30日21:02",
    "eventName": "射箭",
    "matchDetail": "女子个人1/32淘汰赛 鲍尔 VS 诺里加",
    "venue": "荣军院"
  },
  {
    "matchId": "249",
    "ParisTime": "15:11",
    "BeijingTime": "30日21:11",
    "eventName": "自由式小轮车",
    "matchDetail": "男子公园赛预赛",
    "venue": "协和广场"
  },
  {
    "matchId": "250",
    "ParisTime": "15:11",
    "BeijingTime": "30日21:11",
    "eventName": "自由式小轮车",
    "matchDetail": "男子公园赛预赛第1组",
    "venue": "协和广场"
  },
  {
    "matchId": "251",
    "ParisTime": "15:15",
    "BeijingTime": "30日21:15",
    "eventName": "射箭",
    "matchDetail": "女子个人1/32淘汰赛 范德温克尔 VS 乔鲁尼萨",
    "venue": "荣军院"
  },
  {
    "matchId": "252",
    "ParisTime": "15:27",
    "BeijingTime": "30日21:27",
    "eventName": "帆船",
    "matchDetail": "男子帆船49人级 - 比赛08",
    "venue": "马赛港"
  },
  {
    "matchId": "253",
    "ParisTime": "15:28",
    "BeijingTime": "30日21:28",
    "eventName": "射箭",
    "matchDetail": "男子个人1/16淘汰赛 金优镇 VS 林子翔",
    "venue": "荣军院"
  },
  {
    "matchId": "254",
    "ParisTime": "15:30",
    "BeijingTime": "30日21:30",
    "eventName": "拳击",
    "matchDetail": "男子51公斤级 - 预赛 - 16强赛 侯赛因诺夫0:5阿尔坎塔拉·雷耶斯",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "255",
    "ParisTime": "15:30",
    "BeijingTime": "30日21:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子半决赛 新西兰24:12美国",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "256",
    "ParisTime": "15:30",
    "BeijingTime": "30日21:30",
    "eventName": "射击",
    "matchDetail": "男子多向飞碟决赛",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "257",
    "ParisTime": "15:40",
    "BeijingTime": "30日21:40",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - B组 姜敏赫/徐承宰2:0乔姆科/凯德伦",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "258",
    "ParisTime": "15:40",
    "BeijingTime": "30日21:40",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - I组 李卓耀2:1加里多",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "259",
    "ParisTime": "15:40",
    "BeijingTime": "30日21:40",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - D组 保木卓朗/小林优吾0:2刘雨辰/欧烜屹",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "260",
    "ParisTime": "15:41",
    "BeijingTime": "30日21:41",
    "eventName": "射箭",
    "matchDetail": "女子个人1/16淘汰赛 诺里加 VS 乔鲁尼萨",
    "venue": "荣军院"
  },
  {
    "matchId": "261",
    "ParisTime": "15:46",
    "BeijingTime": "30日21:46",
    "eventName": "拳击",
    "matchDetail": "男子51公斤级 - 预赛 - 16强赛 奇耶姆巴4:1阿米特",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "262",
    "ParisTime": "15:50",
    "BeijingTime": "30日21:50",
    "eventName": "皮划艇激流回旋",
    "matchDetail": "男子单人皮艇预赛 - 第1轮和第2轮",
    "venue": "水上体育场"
  },
  {
    "matchId": "263",
    "ParisTime": "15:50",
    "BeijingTime": "30日21:50",
    "eventName": "皮划艇激流回旋",
    "matchDetail": "男子单人皮艇预赛第1轮",
    "venue": "水上体育场"
  },
  {
    "matchId": "264",
    "ParisTime": "15:50",
    "BeijingTime": "30日21:50",
    "eventName": "击剑",
    "matchDetail": "女子团体重剑半决赛1 中国24:45意大利",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "265",
    "ParisTime": "15:50",
    "BeijingTime": "30日21:50",
    "eventName": "击剑",
    "matchDetail": "女子团体重剑半决赛2 波兰39:45法国",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "266",
    "ParisTime": "15:52",
    "BeijingTime": "30日21:52",
    "eventName": "自由式小轮车",
    "matchDetail": "男子公园赛预赛第2组",
    "venue": "协和广场"
  },
  {
    "matchId": "267",
    "ParisTime": "16:00",
    "BeijingTime": "30日22:00",
    "eventName": "手球",
    "matchDetail": "女子预赛B组 匈牙利31:31安哥拉",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "268",
    "ParisTime": "16:00",
    "BeijingTime": "30日22:00",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级复活赛 卡斯1:0戈蒂埃·德拉波",
    "venue": "战神广场"
  },
  {
    "matchId": "269",
    "ParisTime": "16:00",
    "BeijingTime": "30日22:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子半决赛 加拿大21:12澳大利亚",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "270",
    "ParisTime": "16:00",
    "BeijingTime": "30日22:00",
    "eventName": "沙滩排球",
    "matchDetail": "女子预赛 - E组 巴西2:0立陶宛",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "271",
    "ParisTime": "16:02",
    "BeijingTime": "30日22:02",
    "eventName": "拳击",
    "matchDetail": "男子51公斤级 - 预赛 - 16强赛 德皮纳4:1潘莫特",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "272",
    "ParisTime": "16:06",
    "BeijingTime": "30日22:06",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级复活赛 马赫马德贝科夫10:0博尔塔博耶夫",
    "venue": "战神广场"
  },
  {
    "matchId": "273",
    "ParisTime": "16:12",
    "BeijingTime": "30日22:12",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级B表半决赛 格里加拉什维利1:0李俊奂",
    "venue": "战神广场"
  },
  {
    "matchId": "274",
    "ParisTime": "16:18",
    "BeijingTime": "30日22:18",
    "eventName": "拳击",
    "matchDetail": "男子80公斤级 - 预赛 - 16强赛 彼得斯2:3奥拉尔拜",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "275",
    "ParisTime": "16:18",
    "BeijingTime": "30日22:18",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级A表半决赛 长濑 孝则10:0埃斯波西托",
    "venue": "战神广场"
  },
  {
    "matchId": "276",
    "ParisTime": "16:19",
    "BeijingTime": "30日22:19",
    "eventName": "帆船",
    "matchDetail": "男子帆船49人级 - 比赛09",
    "venue": "马赛港"
  },
  {
    "matchId": "277",
    "ParisTime": "16:24",
    "BeijingTime": "30日22:24",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级复活赛 金智秀0:10皮奥瓦萨娜",
    "venue": "战神广场"
  },
  {
    "matchId": "278",
    "ParisTime": "16:30",
    "BeijingTime": "30日22:30",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - L组 达拉赫1:2斯塔德曼",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "279",
    "ParisTime": "16:30",
    "BeijingTime": "30日22:30",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级复活赛 博舍曼-皮纳尔0:1法兹利乌",
    "venue": "战神广场"
  },
  {
    "matchId": "280",
    "ParisTime": "16:30",
    "BeijingTime": "30日22:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子11-12名排名赛 南非21:15斐济",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "281",
    "ParisTime": "16:34",
    "BeijingTime": "30日22:34",
    "eventName": "拳击",
    "matchDetail": "男子80公斤级 - 预赛 - 16强赛 阿拉赫韦尔迪耶夫5:0阿卜杜勒加瓦德",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "282",
    "ParisTime": "16:35",
    "BeijingTime": "30日22:35",
    "eventName": "水球",
    "matchDetail": "男子预赛A组 美国14:8罗马尼亚",
    "venue": "水上运动中心"
  },
  {
    "matchId": "283",
    "ParisTime": "16:36",
    "BeijingTime": "30日22:36",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级B表半决赛 莱斯基1:0阿格班尼奥",
    "venue": "战神广场"
  },
  {
    "matchId": "284",
    "ParisTime": "16:40",
    "BeijingTime": "30日22:40",
    "eventName": "皮划艇激流回旋",
    "matchDetail": "女子单人划艇预赛第2轮",
    "venue": "水上体育场"
  },
  {
    "matchId": "285",
    "ParisTime": "16:40",
    "BeijingTime": "30日22:40",
    "eventName": "击剑",
    "matchDetail": "女子团体重剑5-6名赛 韩国45:38乌克兰",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "286",
    "ParisTime": "16:40",
    "BeijingTime": "30日22:40",
    "eventName": "击剑",
    "matchDetail": "女子团体重剑7-8名赛 美国44:30埃及",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "287",
    "ParisTime": "16:42",
    "BeijingTime": "30日22:42",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级A表半决赛 克里斯托0:11阿维蒂·阿尔卡拉斯",
    "venue": "战神广场"
  },
  {
    "matchId": "288",
    "ParisTime": "16:48",
    "BeijingTime": "30日22:48",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级铜牌争夺赛A 卡斯0:1李俊奂",
    "venue": "战神广场"
  },
  {
    "matchId": "289",
    "ParisTime": "16:50",
    "BeijingTime": "30日22:50",
    "eventName": "拳击",
    "matchDetail": "女子54公斤级 - 预赛 - 16强赛 乌克塔莫娃0:5方哲美",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "290",
    "ParisTime": "16:54",
    "BeijingTime": "30日22:54",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级铜牌争夺赛B 马赫马德贝科夫10:0埃斯波西托",
    "venue": "战神广场"
  },
  {
    "matchId": "291",
    "ParisTime": "17:00",
    "BeijingTime": "30日23:00",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛B组 阿根廷2:0新西兰",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "292",
    "ParisTime": "17:00",
    "BeijingTime": "30日23:00",
    "eventName": "柔道",
    "matchDetail": "男子81公斤级决赛 长濑 孝则11:0格里加拉什维利",
    "venue": "战神广场"
  },
  {
    "matchId": "293",
    "ParisTime": "17:00",
    "BeijingTime": "30日23:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子9-10名排名赛 日本38:7巴西",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "294",
    "ParisTime": "17:00",
    "BeijingTime": "30日23:00",
    "eventName": "网球",
    "matchDetail": "男子双打第2轮 博尔赫斯/卡布拉尔0:2克普费尔/施特鲁夫",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "295",
    "ParisTime": "17:00",
    "BeijingTime": "30日23:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - F组 法国1:2西班牙",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "296",
    "ParisTime": "17:00",
    "BeijingTime": "30日23:00",
    "eventName": "排球",
    "matchDetail": "男子预赛 - A组 斯洛文尼亚3:0塞尔维亚",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "297",
    "ParisTime": "17:00",
    "BeijingTime": "30日23:00",
    "eventName": "足球",
    "matchDetail": "男子B组 乌克兰0:2阿根廷",
    "venue": "里昂体育场"
  },
  {
    "matchId": "298",
    "ParisTime": "17:00",
    "BeijingTime": "30日23:00",
    "eventName": "足球",
    "matchDetail": "男子B组 摩洛哥3:0伊拉克",
    "venue": "尼斯体育场"
  },
  {
    "matchId": "299",
    "ParisTime": "17:06",
    "BeijingTime": "30日23:06",
    "eventName": "拳击",
    "matchDetail": "女子54公斤级 - 预赛 - 16强赛 埃切加拉伊0:5阿克巴斯",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "300",
    "ParisTime": "17:06",
    "BeijingTime": "30日23:06",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级铜牌争夺赛A 皮奥瓦萨娜 VS 阿格班尼奥",
    "venue": "战神广场"
  },
  {
    "matchId": "301",
    "ParisTime": "17:12",
    "BeijingTime": "30日23:12",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级铜牌争夺赛B 法兹利乌10:0克里斯托",
    "venue": "战神广场"
  },
  {
    "matchId": "302",
    "ParisTime": "17:15",
    "BeijingTime": "30日23:15",
    "eventName": "篮球",
    "matchDetail": "男子小组赛 - B组 日本90:94法国",
    "venue": "皮埃尔·莫鲁瓦体育场"
  },
  {
    "matchId": "303",
    "ParisTime": "17:18",
    "BeijingTime": "30日23:18",
    "eventName": "柔道",
    "matchDetail": "女子63公斤级决赛 阿维蒂·阿尔卡拉斯1:10莱斯基",
    "venue": "战神广场"
  },
  {
    "matchId": "304",
    "ParisTime": "17:22",
    "BeijingTime": "30日23:22",
    "eventName": "拳击",
    "matchDetail": "女子54公斤级 - 预赛 - 16强赛 蒙冈特塞格4:1佩里约克",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "305",
    "ParisTime": "17:30",
    "BeijingTime": "30日23:30",
    "eventName": "三人篮球",
    "matchDetail": "女子分组循环赛 德国17:13美国",
    "venue": "协和广场"
  },
  {
    "matchId": "306",
    "ParisTime": "17:30",
    "BeijingTime": "30日23:30",
    "eventName": "皮划艇激流回旋",
    "matchDetail": "男子单人皮艇预赛第2轮",
    "venue": "水上体育场"
  },
  {
    "matchId": "307",
    "ParisTime": "17:38",
    "BeijingTime": "30日23:38",
    "eventName": "拳击",
    "matchDetail": "女子57公斤级 - 预赛 - 32强赛 门多萨3:2萨玛多娃",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "308",
    "ParisTime": "17:45",
    "BeijingTime": "30日23:45",
    "eventName": "射箭",
    "matchDetail": "男子个人1/32淘汰赛 汤智钧 VS 潘格斯图",
    "venue": "荣军院"
  },
  {
    "matchId": "309",
    "ParisTime": "17:54",
    "BeijingTime": "30日23:54",
    "eventName": "拳击",
    "matchDetail": "女子57公斤级 - 预赛 - 32强赛 贾斯敏0:5佩特西奧",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "310",
    "ParisTime": "17:58",
    "BeijingTime": "30日23:58",
    "eventName": "射箭",
    "matchDetail": "男子个人1/32淘汰赛 加利亚多·费拉达 VS 加佐兹",
    "venue": "荣军院"
  },
  {
    "matchId": "311",
    "ParisTime": "18:00",
    "BeijingTime": "31日00:00",
    "eventName": "三人篮球",
    "matchDetail": "女子分组循环赛 澳大利亚14:22加拿大",
    "venue": "协和广场"
  },
  {
    "matchId": "312",
    "ParisTime": "18:00",
    "BeijingTime": "31日00:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子7-8名排名赛 英国28:12爱尔兰",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "313",
    "ParisTime": "18:11",
    "BeijingTime": "31日00:11",
    "eventName": "射箭",
    "matchDetail": "女子个人1/32淘汰赛 克罗彭 VS 切萨里尼",
    "venue": "荣军院"
  },
  {
    "matchId": "314",
    "ParisTime": "18:15",
    "BeijingTime": "31日00:15",
    "eventName": "竞技体操",
    "matchDetail": "女子团体决赛",
    "venue": "贝尔西体育馆"
  },
  {
    "matchId": "315",
    "ParisTime": "18:24",
    "BeijingTime": "31日00:24",
    "eventName": "射箭",
    "matchDetail": "女子个人1/32淘汰赛 拉马扎诺娃 VS 安琦轩",
    "venue": "荣军院"
  },
  {
    "matchId": "316",
    "ParisTime": "18:30",
    "BeijingTime": "31日00:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子5-6名排名赛 中国7:21法国",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "317",
    "ParisTime": "18:35",
    "BeijingTime": "31日00:35",
    "eventName": "三人篮球",
    "matchDetail": "男子分组循环赛 拉脱维亚21:14立陶宛",
    "venue": "协和广场"
  },
  {
    "matchId": "318",
    "ParisTime": "18:37",
    "BeijingTime": "31日00:37",
    "eventName": "射箭",
    "matchDetail": "男子个人1/16淘汰赛 汤智钧 VS 加佐兹",
    "venue": "荣军院"
  },
  {
    "matchId": "319",
    "ParisTime": "18:50",
    "BeijingTime": "31日00:50",
    "eventName": "射箭",
    "matchDetail": "女子个人1/16淘汰赛 克罗彭 VS 拉马扎诺娃",
    "venue": "荣军院"
  },
  {
    "matchId": "320",
    "ParisTime": "19:00",
    "BeijingTime": "31日01:00",
    "eventName": "手球",
    "matchDetail": "女子预赛B组 法国26:20巴西",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "321",
    "ParisTime": "19:00",
    "BeijingTime": "31日01:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子铜牌赛 美国14:12澳大利亚",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "322",
    "ParisTime": "19:00",
    "BeijingTime": "31日01:00",
    "eventName": "网球",
    "matchDetail": "男子单打第2轮 奥夫纳0:2梅德韦杰夫",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "323",
    "ParisTime": "19:00",
    "BeijingTime": "31日01:00",
    "eventName": "网球",
    "matchDetail": "女子单打第3轮 斯维亚特克2:0王曦雨",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "324",
    "ParisTime": "19:00",
    "BeijingTime": "31日01:00",
    "eventName": "足球",
    "matchDetail": "男子A组 新西兰0:3法国",
    "venue": "马赛体育场"
  },
  {
    "matchId": "325",
    "ParisTime": "19:00",
    "BeijingTime": "31日01:00",
    "eventName": "足球",
    "matchDetail": "男子A组 美国3:0几内亚",
    "venue": "热奥弗鲁瓦基查尔体育场"
  },
  {
    "matchId": "326",
    "ParisTime": "19:03",
    "BeijingTime": "31日01:03",
    "eventName": "射箭",
    "matchDetail": "男子个人1/32淘汰赛 阿卜杜林 VS 彼得斯",
    "venue": "荣军院"
  },
  {
    "matchId": "327",
    "ParisTime": "19:05",
    "BeijingTime": "31日01:05",
    "eventName": "三人篮球",
    "matchDetail": "男子分组循环赛 中国16:21荷兰",
    "venue": "协和广场"
  },
  {
    "matchId": "328",
    "ParisTime": "19:16",
    "BeijingTime": "31日01:16",
    "eventName": "射箭",
    "matchDetail": "男子个人1/32淘汰赛 李 VS 博姆马德瓦拉",
    "venue": "荣军院"
  },
  {
    "matchId": "329",
    "ParisTime": "19:29",
    "BeijingTime": "31日01:29",
    "eventName": "射箭",
    "matchDetail": "女子个人1/32淘汰赛 杨晓蕾 VS 埃尔瓦利德",
    "venue": "荣军院"
  },
  {
    "matchId": "330",
    "ParisTime": "19:30",
    "BeijingTime": "31日01:30",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - M组 坎胡拉·阿尔蒂加0:2劳达",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "331",
    "ParisTime": "19:30",
    "BeijingTime": "31日01:30",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - N组 奥佩约里0:2库恩齐",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "332",
    "ParisTime": "19:30",
    "BeijingTime": "31日01:30",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - N组 何冰娇2:0吉尔摩",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "333",
    "ParisTime": "19:30",
    "BeijingTime": "31日01:30",
    "eventName": "击剑",
    "matchDetail": "女子团体重剑铜牌赛 波兰32:31中国",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "334",
    "ParisTime": "19:30",
    "BeijingTime": "31日01:30",
    "eventName": "水球",
    "matchDetail": "男子预赛A组 黑山16:17希腊",
    "venue": "水上运动中心"
  },
  {
    "matchId": "335",
    "ParisTime": "19:42",
    "BeijingTime": "31日01:42",
    "eventName": "射箭",
    "matchDetail": "女子个人1/32淘汰赛 施洛瑟 VS 巴贝林",
    "venue": "荣军院"
  },
  {
    "matchId": "336",
    "ParisTime": "19:45",
    "BeijingTime": "31日01:45",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛B组 澳大利亚2:6比利时",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "337",
    "ParisTime": "19:45",
    "BeijingTime": "31日01:45",
    "eventName": "七人制橄榄球",
    "matchDetail": "女子金牌赛 新西兰19:12加拿大",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "338",
    "ParisTime": "19:55",
    "BeijingTime": "31日01:55",
    "eventName": "射箭",
    "matchDetail": "男子个人1/16淘汰赛 彼得斯 VS 博姆马德瓦拉",
    "venue": "荣军院"
  },
  {
    "matchId": "339",
    "ParisTime": "20:00",
    "BeijingTime": "31日02:00",
    "eventName": "拳击",
    "matchDetail": "男子51公斤级 - 预赛 - 16强赛 古姆斯0:5比博西诺夫",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "340",
    "ParisTime": "20:00",
    "BeijingTime": "31日02:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - F组 荷兰2:0美国",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "341",
    "ParisTime": "20:08",
    "BeijingTime": "31日02:08",
    "eventName": "射箭",
    "matchDetail": "女子个人1/16淘汰赛 杨晓蕾 VS 巴贝林",
    "venue": "荣军院"
  },
  {
    "matchId": "342",
    "ParisTime": "20:16",
    "BeijingTime": "31日02:16",
    "eventName": "拳击",
    "matchDetail": "男子51公斤级 - 预赛 - 16强赛 洛佩兹·德·热苏斯0:5杜斯马托夫",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "343",
    "ParisTime": "20:20",
    "BeijingTime": "31日02:20",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - K组 黎德发2:0罗斯",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "344",
    "ParisTime": "20:20",
    "BeijingTime": "31日02:20",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - D组 卡特通2:0卢善恩",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "345",
    "ParisTime": "20:20",
    "BeijingTime": "31日02:20",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - I组 杨佳敏2:0卢迪克",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "346",
    "ParisTime": "20:30",
    "BeijingTime": "31日02:30",
    "eventName": "击剑",
    "matchDetail": "女子团体重剑金牌赛 法国29:30意大利",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "347",
    "ParisTime": "20:30",
    "BeijingTime": "31日02:30",
    "eventName": "游泳",
    "matchDetail": "男子100米自由泳半决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "348",
    "ParisTime": "20:30",
    "BeijingTime": "31日02:30",
    "eventName": "游泳",
    "matchDetail": "男子100米自由泳半决赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "349",
    "ParisTime": "20:32",
    "BeijingTime": "31日02:32",
    "eventName": "拳击",
    "matchDetail": "男子80公斤级 - 预赛 - 16强赛 卡兹尼亚克4:0阿基洛夫",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "350",
    "ParisTime": "20:37",
    "BeijingTime": "31日02:37",
    "eventName": "游泳",
    "matchDetail": "男子100米自由泳半决赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "351",
    "ParisTime": "20:44",
    "BeijingTime": "31日02:44",
    "eventName": "游泳",
    "matchDetail": "男子200米蝶泳半决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "352",
    "ParisTime": "20:44",
    "BeijingTime": "31日02:44",
    "eventName": "游泳",
    "matchDetail": "男子200米蝶泳半决赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "353",
    "ParisTime": "20:48",
    "BeijingTime": "31日02:48",
    "eventName": "拳击",
    "matchDetail": "男子80公斤级 - 预赛 - 16强赛 贝洛尼-杜利普雷0:5代·苏沙·佩雷拉",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "354",
    "ParisTime": "20:52",
    "BeijingTime": "31日02:52",
    "eventName": "游泳",
    "matchDetail": "男子200米蝶泳半决赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "355",
    "ParisTime": "21:00",
    "BeijingTime": "31日03:00",
    "eventName": "三人篮球",
    "matchDetail": "女子分组循环赛 西班牙18:16阿塞拜疆",
    "venue": "协和广场"
  },
  {
    "matchId": "356",
    "ParisTime": "21:00",
    "BeijingTime": "31日03:00",
    "eventName": "篮球",
    "matchDetail": "男子小组赛 - B组 巴西73:86德国",
    "venue": "皮埃尔·莫鲁瓦体育场"
  },
  {
    "matchId": "357",
    "ParisTime": "21:00",
    "BeijingTime": "31日03:00",
    "eventName": "手球",
    "matchDetail": "女子预赛A组 瑞典23:25丹麦",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "358",
    "ParisTime": "21:00",
    "BeijingTime": "31日03:00",
    "eventName": "游泳",
    "matchDetail": "女子100米仰泳决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "359",
    "ParisTime": "21:00",
    "BeijingTime": "31日03:00",
    "eventName": "沙滩排球",
    "matchDetail": "女子预赛 - A组 巴西2:0西班牙",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "360",
    "ParisTime": "21:00",
    "BeijingTime": "31日03:00",
    "eventName": "排球",
    "matchDetail": "男子预赛 - A组 法国3:0加拿大",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "361",
    "ParisTime": "21:00",
    "BeijingTime": "31日03:00",
    "eventName": "足球",
    "matchDetail": "男子D组 以色列0:1日本",
    "venue": "博茹瓦尔体育场"
  },
  {
    "matchId": "362",
    "ParisTime": "21:00",
    "BeijingTime": "31日03:00",
    "eventName": "足球",
    "matchDetail": "男子D组 巴拉圭1:0马里",
    "venue": "王子公园"
  },
  {
    "matchId": "363",
    "ParisTime": "21:04",
    "BeijingTime": "31日03:04",
    "eventName": "拳击",
    "matchDetail": "男子80公斤级 - 预赛 - 16强赛 马西亚尔0:5哈比布拉耶夫",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "364",
    "ParisTime": "21:05",
    "BeijingTime": "31日03:05",
    "eventName": "水球",
    "matchDetail": "男子预赛 - B组 西班牙10:7匈牙利",
    "venue": "水上运动中心"
  },
  {
    "matchId": "365",
    "ParisTime": "21:07",
    "BeijingTime": "31日03:07",
    "eventName": "游泳",
    "matchDetail": "男子800米自由泳决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "366",
    "ParisTime": "21:10",
    "BeijingTime": "31日03:10",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - B组 波波夫2:0克拉尔/门德雷克",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "367",
    "ParisTime": "21:10",
    "BeijingTime": "31日03:10",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - D组 李绍希/白荷娜2:0基蒂塔拉克尔/普拉琼贾伊",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "368",
    "ParisTime": "21:10",
    "BeijingTime": "31日03:10",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - D组 兰伯特/特兰0:2弗勒尔戈德/蒂格森",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "369",
    "ParisTime": "21:20",
    "BeijingTime": "31日03:20",
    "eventName": "拳击",
    "matchDetail": "男子80公斤级 - 预赛 - 16强赛 阿伊库桑0:5洛佩斯·卡多纳",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "370",
    "ParisTime": "21:30",
    "BeijingTime": "31日03:30",
    "eventName": "三人篮球",
    "matchDetail": "女子分组循环赛 法国19:21中国",
    "venue": "协和广场"
  },
  {
    "matchId": "371",
    "ParisTime": "21:33",
    "BeijingTime": "31日03:33",
    "eventName": "游泳",
    "matchDetail": "女子100米自由泳半决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "372",
    "ParisTime": "21:33",
    "BeijingTime": "31日03:33",
    "eventName": "游泳",
    "matchDetail": "女子100米自由泳半决赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "373",
    "ParisTime": "21:36",
    "BeijingTime": "31日03:36",
    "eventName": "拳击",
    "matchDetail": "女子54公斤级 - 预赛 - 16强赛 代·杰苏斯·查加斯1:4林爱智",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "374",
    "ParisTime": "21:40",
    "BeijingTime": "31日03:40",
    "eventName": "游泳",
    "matchDetail": "女子100米自由泳半决赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "375",
    "ParisTime": "21:52",
    "BeijingTime": "31日03:52",
    "eventName": "拳击",
    "matchDetail": "女子54公斤级 - 预赛 - 16强赛 普里蒂2:3阿里亚斯·卡斯塔尼达",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "376",
    "ParisTime": "21:59",
    "BeijingTime": "31日03:59",
    "eventName": "游泳",
    "matchDetail": "男子200米蛙泳半决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "377",
    "ParisTime": "21:59",
    "BeijingTime": "31日03:59",
    "eventName": "游泳",
    "matchDetail": "男子200米蛙泳半决赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "378",
    "ParisTime": "22:00",
    "BeijingTime": "31日04:00",
    "eventName": "沙滩排球",
    "matchDetail": "女子预赛 - E组 荷兰2:0日本",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "379",
    "ParisTime": "22:05",
    "BeijingTime": "31日04:05",
    "eventName": "三人篮球",
    "matchDetail": "男子分组循环赛 波兰19:21法国",
    "venue": "协和广场"
  },
  {
    "matchId": "380",
    "ParisTime": "22:07",
    "BeijingTime": "31日04:07",
    "eventName": "游泳",
    "matchDetail": "男子200米蛙泳半决赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "381",
    "ParisTime": "22:08",
    "BeijingTime": "31日04:08",
    "eventName": "拳击",
    "matchDetail": "女子57公斤级 - 预赛 - 32强赛 许紫春3:2泰斯塔",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "382",
    "ParisTime": "22:15",
    "BeijingTime": "31日04:15",
    "eventName": "游泳",
    "matchDetail": "男子4x200米自由泳接力决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "383",
    "ParisTime": "22:24",
    "BeijingTime": "31日04:24",
    "eventName": "拳击",
    "matchDetail": "女子57公斤级 - 预赛 - 32强赛 阿尔卡拉·塞戈维亚1:4塞雷梅塔",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "384",
    "ParisTime": "22:35",
    "BeijingTime": "31日04:35",
    "eventName": "三人篮球",
    "matchDetail": "男子分组循环赛 塞尔维亚22:14美国",
    "venue": "协和广场"
  },
];
export default matchData;