/* eslint-disable no-unused-vars */
const matchData = [
  {
    "matchId": "1",
    "ParisTime": "15:00",
    "BeijingTime": "24日21:00",
    "eventName": "足球",
    "matchDetail": "男子B组 阿根廷1:2摩洛哥",
    "venue": "热奥弗鲁瓦基查尔体育场"
  },
  {
    "matchId": "2",
    "ParisTime": "15:00",
    "BeijingTime": "24日21:00",
    "eventName": "足球",
    "matchDetail": "男子C组 乌兹别克斯坦1:2西班牙",
    "venue": "王子公园"
  },
  {
    "matchId": "3",
    "ParisTime": "15:30",
    "BeijingTime": "24日21:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子分组循环赛B组 澳大利亚21:14萨摩亚",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "4",
    "ParisTime": "16:00",
    "BeijingTime": "24日22:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子分组循环赛B组 阿根廷31:12肯尼亚",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "5",
    "ParisTime": "16:30",
    "BeijingTime": "24日22:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子分组循环赛C组 法国12:12美国",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "6",
    "ParisTime": "17:00",
    "BeijingTime": "24日23:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子分组循环赛C组 斐济40:12乌拉圭",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "7",
    "ParisTime": "17:00",
    "BeijingTime": "24日23:00",
    "eventName": "足球",
    "matchDetail": "男子A组 几内亚1:2新西兰",
    "venue": "尼斯体育场"
  },
  {
    "matchId": "8",
    "ParisTime": "17:00",
    "BeijingTime": "24日23:00",
    "eventName": "足球",
    "matchDetail": "男子C组 埃及0:0多米尼加",
    "venue": "博茹瓦尔体育场"
  },
  {
    "matchId": "9",
    "ParisTime": "17:30",
    "BeijingTime": "24日23:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子分组循环赛A组 爱尔兰10:5南非",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "10",
    "ParisTime": "18:00",
    "BeijingTime": "25日00:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子分组循环赛A组 新西兰40:12日本",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "11",
    "ParisTime": "19:00",
    "BeijingTime": "25日01:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子分组循环赛B组 澳大利亚21:7肯尼亚",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "12",
    "ParisTime": "19:00",
    "BeijingTime": "25日01:00",
    "eventName": "足球",
    "matchDetail": "男子B组 伊拉克2:1乌克兰",
    "venue": "里昂体育场"
  },
  {
    "matchId": "13",
    "ParisTime": "19:00",
    "BeijingTime": "25日01:00",
    "eventName": "足球",
    "matchDetail": "男子D组 日本5:0巴拉圭",
    "venue": "波尔多体育场"
  },
  {
    "matchId": "14",
    "ParisTime": "19:30",
    "BeijingTime": "25日01:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子分组循环赛B组 阿根廷28:12萨摩亚",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "15",
    "ParisTime": "20:00",
    "BeijingTime": "25日02:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子分组循环赛C组 法国19:12乌拉圭",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "16",
    "ParisTime": "20:30",
    "BeijingTime": "25日02:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子分组循环赛C组 斐济38:12美国",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "17",
    "ParisTime": "21:00",
    "BeijingTime": "25日03:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子分组循环赛A组 爱尔兰40:5日本",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "18",
    "ParisTime": "21:00",
    "BeijingTime": "25日03:00",
    "eventName": "足球",
    "matchDetail": "男子A组 法国3:0美国",
    "venue": "马赛体育场"
  },
  {
    "matchId": "19",
    "ParisTime": "21:00",
    "BeijingTime": "25日03:00",
    "eventName": "足球",
    "matchDetail": "男子D组 马里1:1以色列",
    "venue": "王子公园"
  },
  {
    "matchId": "20",
    "ParisTime": "21:30",
    "BeijingTime": "25日03:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子分组循环赛A组 新西兰17:5南非",
    "venue": "法兰西体育场"
  },
];
export default matchData;