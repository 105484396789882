<template>
  <div class="container">
    <div class="button-group">
      <button @click="$router.push('/')">奖牌榜</button>
      <button @click="$router.push('/daily-schedule')">每日赛况</button>
      <button @click="$router.push('/matchup')">对阵图</button>
    </div>

    <h1>对阵图</h1>

    <select id="event-select" v-model="selectedEvent">
      <option value="足球">足球</option>
    </select>

    <select id="gender-select" v-model="selectedGender">
      <option value="男子">男子</option>
      <option value="女子">女子</option>
    </select>

    <!-- Conditional rendering of the bracket -->
    <div v-if="selectedGender === '男子'" class="bracket">
      <!-- Render bracket here -->
      <div v-for="(round, index) in bracketData" :key="index" class="round">
        <h3>{{ round.round }}</h3>
        <ul>
          <li v-for="match in round.matches" :key="match.team1 + match.team2">
            {{ match.team1 }} vs {{ match.team2 }}
          </li>
        </ul>
      </div>
    </div>

    <!-- Placeholder for other content -->

    <!-- Tables Container -->
    <div class='tables-container'>
      <!-- First Table -->
      <div class='table' :class="{ 'highlight': selectedGender !== '' }">
        <table>
          <tr v-for="(content, index) in firstTableContent" :key="'table1-row' + index"><td>{{ content }}</td></tr>
        </table>
      </div>

      <!-- Second Table -->
      <div class='table'>
        <table>
          <tr v-for="(content, index) in secondTableContent" :key="'table2-row' + index"><td>{{ content }}</td></tr>
        </table>
      </div>

      <!-- Third Table -->
      <div class='table' :class="{ 'highlight': selectedGender !== '' }">
        <table>
          <tr v-for="(content, index) in thirdTableContent" :key="'table3-row' + index"><td>{{ content }}</td></tr>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
// Your existing script remains unchanged
export default {
  name: 'MatchupView',
  data() {
    return {
      selectedEvent: '足球',
      selectedGender: '女子', // Set default to 女子
      bracketData: [], // Initialize with empty array
      firstTableContent: [
        '1/4决赛',
        '法国 0',
        '巴西 1',
        '',
        '西班牙 2(4) PSO',
        '哥伦比亚2(2) PSO',
        '',
        '美国 1 AET',
        '日本 0 AET',
        '',
        '加拿大 0(2) PSO',
        '德国 0(4) PSO'
      ],
      secondTableContent: [
        "半决赛",
        "巴西 4",
        "西班牙 2",
        "",
        "美国 1 AET",
        "德国 0 AET"
      ],
      thirdTableContent: [
        "总决赛",
        "金牌赛",
        "巴西 0",
        "美国 1",
        "",
        "铜牌赛",
        "西班牙 0",
        "德国 1"
      ]
    };
  },
  methods: {
    updateContent() {
      console.log(`选择的事件: ${this.selectedEvent}`);
      console.log(`选择的性别: ${this.selectedGender}`);
      
      if (this.selectedGender === '男子') {
        this.fetchBracketData();
        
        this.firstTableContent = [
          '1/4决赛',
          '法国 1',
          '阿根廷 0',
          '',
          '埃及 1(5) PSO',
          '铜牌赛', // Updated to "铜牌赛"
          '埃及 0', // Updated to "埃及 0"
          '',
          '摩洛哥 4',
          '美国 0',
          '',
          '日本 0',
          '西班牙 3'
        ];
        
        this.secondTableContent = [
          '半决赛',
          '法国 3 AET',
          '埃及 1 AET',
          '',
          '摩洛哥 1',
          '西班牙 2'
        ];
        
        this.thirdTableContent = [
            "总决赛",
            "金牌赛",
            "法国 3 AET",
            "西班牙 5 AET",
            "",
            "铜牌赛",
            "埃及 0",
            "摩洛哥 6"
         ];
        
      } else if (this.selectedGender === '女子') {
        
         this.firstTableContent = [
           '1/4决赛',
           '法国 0',
           '巴西 1',
           '',
           '西班牙 2(4) PSO',
           '哥伦比亚2(2) PSO',
           '',
           '美国 1 AET',
           '日本 0 AET',
           '',
           '加拿大 0(2) PSO',
           '德国 0(4) PSO'
         ];
         
         this.secondTableContent = [
             "半决赛",
             "巴西 4",
             "西班牙 2",
             "",
             "美国 1 AET",
             "德国 0 AET"
         ];

         this.thirdTableContent = [
            "总决赛",
            "金牌赛",
            "巴西 0",
            "美国 1",
            "",
            "铜牌赛",
            "西班牙 0",
            "德国 1"
         ];
       
     }
   },
    
   fetchBracketData() {
     fetch('/api/football-men-bracket')
       .then(response => response.json())
       .then(data => {
         this.bracketData = data;
       })
       .catch(error => console.error('Error fetching bracket data:', error));
   }
 },
 watch: {
   selectedGender() {
     this.updateContent();
   }
 }
}
</script>

<style scoped>
.container {
 background-image: url('../assets/background.jpg');
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
 min-height:100vh;
 display:flex;
 flex-direction:column;
 align-items:center;
 justify-content:center;
 padding-top:40px;
}

.button-group {
 display:flex;
 gap:10px;
 margin-bottom:20px;
}

.button-group button {
 font-size:18px;
 padding:10px20px;
 background-color:rgba(255,255,255,0.4);
 color:black;
 border:2px solid lightgray;
 border-radius:12px;
}

#event-select,
#gender-select {
 margin-bottom:20px;
}

.bracket .round {
 margin-bottom:20px;
}

.bracket .round ul {
 list-style-type:none;
}

.bracket .round li {
 margin-bottom:10px;
}

/* New styles for tables */
.tables-container {
 display:flex;
 justify-content: space-between; /* Ensures even spacing */
 padding-left: calc(20px); /* Add left padding to create space from the edge */
 padding-right: calc(20px); /* Add right padding to create space from the edge */
}

.table {
 width:300px; /* Set fixed width for each table */
 margin-right: calc(60px); /* Add spacing between tables */
 border-radius:12px; /* Medium-sized rounded corners */
}

.table:last-child {
 margin-right:0; /* Remove right margin from the last table */
}

table {
 width:100%;
 border-collapse:collapse;
 border-radius:inherit; /* Inherit border radius from .table class */
}

table, th, td {
 border:1px solid black; /* Default border color */
}

/* Apply bold text to all table cells */
td {
 padding:8px;
 text-align:center;
 font-weight:bold; /* Make text bold */
}

/* Highlight class for changing text color and border color */
.highlight td {
 color:white; /* Change text color to white */
}

.highlight table, .highlight th, .highlight td {
 border-color:white; /* Change border color to white */
}
</style>