<template>
  <div class="container">
    <div class="button-group">
      <button @click="$router.push('/')">奖牌榜</button>
      <button @click="$router.push('/daily-schedule')">每日赛况</button>
      <button @click="$router.push('/matchup')">对阵图</button>
    </div>

    <h1>每日赛程</h1>

    <!-- 日期选择栏 -->
    <select id="date-select" v-model="selectedDate">
      <option v-for="date in dates" :key="date" :value="date">{{ date }}</option>
    </select>

    <p>您选择的日期是：{{ selectedDate }}</p>

    <!-- 在选择7月24日时显示表格 -->
    <table v-if="selectedDate === '2024-07-24'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData24" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 在选择7月25日时显示表格 -->
    <table v-if="selectedDate === '2024-07-25'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData25" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 在选择7月26日时显示表格 -->
    <table v-if="selectedDate === '2024-07-26'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData26" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 在选择7月27日时显示表格 -->
    <table v-if="selectedDate === '2024-07-27'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData27" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 在选择7月28日时显示表格 -->
    <table v-if="selectedDate === '2024-07-28'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData28" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 在选择7月29日时显示表格 -->
    <table v-if="selectedDate === '2024-07-29'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData29" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 在选择7月30日时显示表格 -->
    <table v-if="selectedDate === '2024-07-30'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData30" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 在选择7月31日时显示表格 -->
    <table v-if="selectedDate === '2024-07-31'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData31" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 在选择8月01日时显示表格 -->
    <table v-if="selectedDate === '2024-08-01'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData801" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 在选择8月02日时显示表格 -->
    <table v-if="selectedDate === '2024-08-02'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData802" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 在选择8月03日时显示表格 -->
    <table v-if="selectedDate === '2024-08-03'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData803" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 在选择8月04日时显示表格 -->
    <table v-if="selectedDate === '2024-08-04'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData804" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 在选择8月05日时显示表格 -->
    <table v-if="selectedDate === '2024-08-05'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData805" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 在选择8月06日时显示表格 -->
    <table v-if="selectedDate === '2024-08-06'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData806" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 在选择8月07日时显示表格 -->
    <table v-if="selectedDate === '2024-08-07'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData807" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 在选择8月08日时显示表格 -->
    <table v-if="selectedDate === '2024-08-08'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData808" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 在选择8月09日时显示表格 -->
    <table v-if="selectedDate === '2024-08-09'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData809" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 在选择8月10日时显示表格 -->
    <table v-if="selectedDate === '2024-08-10'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData810" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 在选择8月11日时显示表格 -->
    <table v-if="selectedDate === '2024-08-11'">
      <thead>
        <tr>
          <th>比赛ID</th>
          <th>巴黎时间</th>
          <th>北京时间</th>
          <th>项目名称</th>
          <th>比赛详情</th>
          <th>场馆</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in matchData811" :key="match.matchId">
          <td>{{ match.matchId }}</td>
          <td>{{ match.ParisTime }}</td>
          <td>{{ match.BeijingTime }}</td>
          <td>{{ match.eventName }}</td>
          <td>{{ match.matchDetail }}</td>
          <td>{{ match.venue }}</td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
// 导入比赛数据
import matchData24 from '../assets/724.js';
import matchData25 from '../assets/725.js';
import matchData26 from '../assets/726.js';
import matchData27 from '../assets/727.js';
import matchData28 from '../assets/728.js';
import matchData29 from '../assets/729.js';
import matchData30 from '../assets/730.js';
import matchData31 from '../assets/731.js';
import matchData801 from '../assets/801.js';
import matchData802 from '../assets/802.js';
import matchData803 from '../assets/803.js';
import matchData804 from '../assets/804.js';
import matchData805 from '../assets/805.js';
import matchData806 from '../assets/806.js';
import matchData807 from '../assets/807.js';
import matchData808 from '../assets/808.js';
import matchData809 from '../assets/809.js';
import matchData810 from '../assets/810.js';
import matchData811 from '../assets/811.js';

export default {
  name: 'DailyScheduleView',
  data() {
     return {
       dates: [
         '2024-07-24', '2024-07-25', '2024-07-26','2024-07-27','2024-07-28','2024-07-29','2024-07-30','2024-07-31', // 添加更多日期
         '2024-08-01','2024-08-02','2024-08-03','2024-08-04','2024-08-05','2024-08-06','2024-08-07','2024-08-08',
         '2024-08-09','2024-08-10','2024-08-11',
       ],
       selectedDate: '2024-07-24', // 默认选定日期
       matchData24, // 使用导入的724数据
       matchData25, // 使用导入的725数据
       matchData26, // 使用导入的726数据
       matchData27,
       matchData28,
       matchData29,
       matchData30,
       matchData31,
       matchData801,
       matchData802,
       matchData803,
       matchData804,
       matchData805,
       matchData806,
       matchData807,
       matchData808,
       matchData809,
       matchData810,
       matchData811,
     };
   }
}
</script>

<style scoped>
.container {
  background-image: url('../assets/background.jpg'); /* 确保路径正确 */
  background-size: cover; /* 背景图像按比例铺满 */
  background-position: center; /* 居中背景图像 */
  background-repeat: no-repeat; /* 背景不重复 */
  min-height: 100vh; /* 确保容器至少填满整个视口高度 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px; /* 增加顶部填充以调整按钮位置 */
}

.button-group {
  display: flex;
  gap: 10px; /* 按钮之间的间距 */
}

.button-group button {
  font-size: 18px; /* 增大按钮字体大小 */
}

#date-select {
 margin-bottom: 20px; /* 日期选择栏与其他元素之间的间距 */
}

table {
 width:auto;
 border-collapse: collapse;
}

th, td {
 border:1px solid #ddd;
 padding:8px;
}

th {
 background-color:#f2f2f2;
}
</style>
