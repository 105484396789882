/* eslint-disable no-unused-vars */
const matchData = [
  {
    "matchId": "1",
    "ParisTime": "19:30",
    "BeijingTime": "27日01:30",
    "eventName": "开幕式",
    "matchDetail": "开幕式",
    "venue": ""
  },
];
export default matchData;