/* eslint-disable no-unused-vars */
const medalData = [
  {
    "rank": "1",
    "country": "美利坚合众国",
    "gold": "40",
    "silver": "44",
    "bronze": "42",
    "totalMedals": "126"
  },
  {
    "rank": "2",
    "country": "中国",
    "gold": "40",
    "silver": "27",
    "bronze": "24",
    "totalMedals": "91"
  },
  {
    "rank": "3",
    "country": "日本",
    "gold": "20",
    "silver": "12",
    "bronze": "13",
    "totalMedals": "45"
  },
  {
    "rank": "4",
    "country": "澳大利亚",
    "gold": "18",
    "silver": "19",
    "bronze": "16",
    "totalMedals": "53"
  },
  {
    "rank": "5",
    "country": "法国",
    "gold": "16",
    "silver": "26",
    "bronze": "22",
    "totalMedals": "64"
  },
  {
    "rank": "6",
    "country": "荷兰",
    "gold": "15",
    "silver": "7",
    "bronze": "12",
    "totalMedals": "34"
  },
  {
    "rank": "7",
    "country": "英国",
    "gold": "14",
    "silver": "22",
    "bronze": "29",
    "totalMedals": "65"
  },
  {
    "rank": "8",
    "country": "大韩民国",
    "gold": "13",
    "silver": "9",
    "bronze": "10",
    "totalMedals": "32"
  },
  {
    "rank": "9",
    "country": "意大利",
    "gold": "12",
    "silver": "13",
    "bronze": "15",
    "totalMedals": "40"
  },
  {
    "rank": "10",
    "country": "德国",
    "gold": "12",
    "silver": "13",
    "bronze": "8",
    "totalMedals": "33"
  },
  {
    "rank": "11",
    "country": "新西兰",
    "gold": "10",
    "silver": "7",
    "bronze": "3",
    "totalMedals": "20"
  },
  {
    "rank": "12",
    "country": "加拿大",
    "gold": "9",
    "silver": "7",
    "bronze": "11",
    "totalMedals": "27"
  },
  {
    "rank": "13",
    "country": "乌兹别克斯坦",
    "gold": "8",
    "silver": "2",
    "bronze": "3",
    "totalMedals": "13"
  },
  {
    "rank": "14",
    "country": "匈牙利",
    "gold": "6",
    "silver": "7",
    "bronze": "6",
    "totalMedals": "19"
  },
  {
    "rank": "15",
    "country": "西班牙",
    "gold": "5",
    "silver": "4",
    "bronze": "9",
    "totalMedals": "18"
  },
  {
    "rank": "16",
    "country": "瑞典",
    "gold": "4",
    "silver": "4",
    "bronze": "3",
    "totalMedals": "11"
  },
  {
    "rank": "17",
    "country": "肯尼亚",
    "gold": "4",
    "silver": "2",
    "bronze": "5",
    "totalMedals": "11"
  },
  {
    "rank": "18",
    "country": "挪威",
    "gold": "4",
    "silver": "1",
    "bronze": "3",
    "totalMedals": "8"
  },
  {
    "rank": "19",
    "country": "爱尔兰",
    "gold": "4",
    "silver": "0",
    "bronze": "3",
    "totalMedals": "7"
  },
  {
    "rank": "20",
    "country": "巴西",
    "gold": "3",
    "silver": "7",
    "bronze": "10",
    "totalMedals": "20"
  },
  {
    "rank": "21",
    "country": "伊朗伊斯兰共和国",
    "gold": "3",
    "silver": "6",
    "bronze": "3",
    "totalMedals": "12"
  },
  {
    "rank": "22",
    "country": "乌克兰",
    "gold": "3",
    "silver": "5",
    "bronze": "4",
    "totalMedals": "12"
  },
  {
    "rank": "23",
    "country": "罗马尼亚",
    "gold": "3",
    "silver": "4",
    "bronze": "2",
    "totalMedals": "9"
  },
  {
    "rank": "24",
    "country": "格鲁吉亚",
    "gold": "3",
    "silver": "3",
    "bronze": "1",
    "totalMedals": "7"
  },
  {
    "rank": "25",
    "country": "比利时",
    "gold": "3",
    "silver": "1",
    "bronze": "6",
    "totalMedals": "10"
  },
  {
    "rank": "26",
    "country": "保加利亚",
    "gold": "3",
    "silver": "1",
    "bronze": "3",
    "totalMedals": "7"
  },
  {
    "rank": "27",
    "country": "塞尔维亚",
    "gold": "3",
    "silver": "1",
    "bronze": "1",
    "totalMedals": "5"
  },
  {
    "rank": "28",
    "country": "捷克",
    "gold": "3",
    "silver": "0",
    "bronze": "2",
    "totalMedals": "5"
  },
  {
    "rank": "29",
    "country": "丹麦",
    "gold": "2",
    "silver": "2",
    "bronze": "5",
    "totalMedals": "9"
  },
  {
    "rank": "30",
    "country": "阿塞拜疆",
    "gold": "2",
    "silver": "2",
    "bronze": "3",
    "totalMedals": "7"
  },
  {
    "rank": "31",
    "country": "克罗地亚",
    "gold": "2",
    "silver": "2",
    "bronze": "3",
    "totalMedals": "7"
  },
  {
    "rank": "32",
    "country": "古巴",
    "gold": "2",
    "silver": "1",
    "bronze": "6",
    "totalMedals": "9"
  },
  {
    "rank": "33",
    "country": "巴林",
    "gold": "2",
    "silver": "1",
    "bronze": "1",
    "totalMedals": "4"
  },
  {
    "rank": "34",
    "country": "斯洛文尼亚",
    "gold": "2",
    "silver": "1",
    "bronze": "0",
    "totalMedals": "3"
  },
  {
    "rank": "35",
    "country": "Chinese Taipei",
    "gold": "2",
    "silver": "0",
    "bronze": "5",
    "totalMedals": "7"
  },
  {
    "rank": "36",
    "country": "奥地利",
    "gold": "2",
    "silver": "0",
    "bronze": "3",
    "totalMedals": "5"
  },
  {
    "rank": "37",
    "country": "中国香港",
    "gold": "2",
    "silver": "0",
    "bronze": "2",
    "totalMedals": "4"
  },
  {
    "rank": "38",
    "country": "菲律宾",
    "gold": "2",
    "silver": "0",
    "bronze": "2",
    "totalMedals": "4"
  },
  {
    "rank": "39",
    "country": "阿尔及利亚",
    "gold": "2",
    "silver": "0",
    "bronze": "1",
    "totalMedals": "3"
  },
  {
    "rank": "40",
    "country": "印度尼西亚",
    "gold": "2",
    "silver": "0",
    "bronze": "1",
    "totalMedals": "3"
  },
  {
    "rank": "41",
    "country": "以色列",
    "gold": "1",
    "silver": "5",
    "bronze": "1",
    "totalMedals": "7"
  },
  {
    "rank": "42",
    "country": "波兰",
    "gold": "1",
    "silver": "4",
    "bronze": "5",
    "totalMedals": "10"
  },
  {
    "rank": "43",
    "country": "哈萨克斯坦",
    "gold": "1",
    "silver": "3",
    "bronze": "3",
    "totalMedals": "7"
  },
  {
    "rank": "44",
    "country": "牙买加",
    "gold": "1",
    "silver": "3",
    "bronze": "2",
    "totalMedals": "6"
  },
  {
    "rank": "45",
    "country": "南非",
    "gold": "1",
    "silver": "3",
    "bronze": "2",
    "totalMedals": "6"
  },
  {
    "rank": "46",
    "country": "泰国",
    "gold": "1",
    "silver": "3",
    "bronze": "2",
    "totalMedals": "6"
  },
  {
    "rank": "47",
    "country": "埃塞俄比亚",
    "gold": "1",
    "silver": "3",
    "bronze": "0",
    "totalMedals": "4"
  },
  {
    "rank": "48",
    "country": "瑞士",
    "gold": "1",
    "silver": "2",
    "bronze": "5",
    "totalMedals": "8"
  },
  {
    "rank": "49",
    "country": "厄瓜多尔",
    "gold": "1",
    "silver": "2",
    "bronze": "2",
    "totalMedals": "5"
  },
  {
    "rank": "50",
    "country": "葡萄牙",
    "gold": "1",
    "silver": "2",
    "bronze": "1",
    "totalMedals": "4"
  },
  {
    "rank": "51",
    "country": "希腊",
    "gold": "1",
    "silver": "1",
    "bronze": "6",
    "totalMedals": "8"
  },
  {
    "rank": "52",
    "country": "阿根廷",
    "gold": "1",
    "silver": "1",
    "bronze": "1",
    "totalMedals": "3"
  },
  {
    "rank": "53",
    "country": "埃及",
    "gold": "1",
    "silver": "1",
    "bronze": "1",
    "totalMedals": "3"
  },
  {
    "rank": "54",
    "country": "突尼斯",
    "gold": "1",
    "silver": "1",
    "bronze": "1",
    "totalMedals": "3"
  },
  {
    "rank": "55",
    "country": "博茨瓦纳",
    "gold": "1",
    "silver": "1",
    "bronze": "0",
    "totalMedals": "2"
  },
  {
    "rank": "56",
    "country": "智利",
    "gold": "1",
    "silver": "1",
    "bronze": "0",
    "totalMedals": "2"
  },
  {
    "rank": "57",
    "country": "圣卢西亚",
    "gold": "1",
    "silver": "1",
    "bronze": "0",
    "totalMedals": "2"
  },
  {
    "rank": "58",
    "country": "乌干达",
    "gold": "1",
    "silver": "1",
    "bronze": "0",
    "totalMedals": "2"
  },
  {
    "rank": "59",
    "country": "多米尼加",
    "gold": "1",
    "silver": "0",
    "bronze": "2",
    "totalMedals": "3"
  },
  {
    "rank": "60",
    "country": "危地马拉",
    "gold": "1",
    "silver": "0",
    "bronze": "1",
    "totalMedals": "2"
  },
  {
    "rank": "61",
    "country": "摩洛哥",
    "gold": "1",
    "silver": "0",
    "bronze": "1",
    "totalMedals": "2"
  },
  {
    "rank": "62",
    "country": "多米尼克",
    "gold": "1",
    "silver": "0",
    "bronze": "0",
    "totalMedals": "1"
  },
  {
    "rank": "63",
    "country": "巴基斯坦",
    "gold": "1",
    "silver": "0",
    "bronze": "0",
    "totalMedals": "1"
  },
  {
    "rank": "64",
    "country": "土耳其",
    "gold": "0",
    "silver": "3",
    "bronze": "5",
    "totalMedals": "8"
  },
  {
    "rank": "65",
    "country": "墨西哥",
    "gold": "0",
    "silver": "3",
    "bronze": "2",
    "totalMedals": "5"
  },
  {
    "rank": "66",
    "country": "亚美尼亚",
    "gold": "0",
    "silver": "3",
    "bronze": "1",
    "totalMedals": "4"
  },
  {
    "rank": "67",
    "country": "哥伦比亚",
    "gold": "0",
    "silver": "3",
    "bronze": "1",
    "totalMedals": "4"
  },
  {
    "rank": "68",
    "country": "吉尔吉斯斯坦",
    "gold": "0",
    "silver": "2",
    "bronze": "4",
    "totalMedals": "6"
  },
  {
    "rank": "69",
    "country": "朝鲜民主主义人民共和国",
    "gold": "0",
    "silver": "2",
    "bronze": "4",
    "totalMedals": "6"
  },
  {
    "rank": "70",
    "country": "立陶宛",
    "gold": "0",
    "silver": "2",
    "bronze": "2",
    "totalMedals": "4"
  },
  {
    "rank": "71",
    "country": "印度",
    "gold": "0",
    "silver": "1",
    "bronze": "5",
    "totalMedals": "6"
  },
  {
    "rank": "72",
    "country": "摩尔多瓦共和国",
    "gold": "0",
    "silver": "1",
    "bronze": "3",
    "totalMedals": "4"
  },
  {
    "rank": "73",
    "country": "科索沃",
    "gold": "0",
    "silver": "1",
    "bronze": "1",
    "totalMedals": "2"
  },
  {
    "rank": "74",
    "country": "塞浦路斯",
    "gold": "0",
    "silver": "1",
    "bronze": "0",
    "totalMedals": "1"
  },
  {
    "rank": "75",
    "country": "斐济",
    "gold": "0",
    "silver": "1",
    "bronze": "0",
    "totalMedals": "1"
  },
  {
    "rank": "76",
    "country": "约旦",
    "gold": "0",
    "silver": "1",
    "bronze": "0",
    "totalMedals": "1"
  },
  {
    "rank": "77",
    "country": "蒙古",
    "gold": "0",
    "silver": "1",
    "bronze": "0",
    "totalMedals": "1"
  },
  {
    "rank": "78",
    "country": "巴拿马",
    "gold": "0",
    "silver": "1",
    "bronze": "0",
    "totalMedals": "1"
  },
  {
    "rank": "79",
    "country": "塔吉克斯坦",
    "gold": "0",
    "silver": "0",
    "bronze": "3",
    "totalMedals": "3"
  },
  {
    "rank": "80",
    "country": "阿尔巴尼亚",
    "gold": "0",
    "silver": "0",
    "bronze": "2",
    "totalMedals": "2"
  },
  {
    "rank": "81",
    "country": "格林纳达",
    "gold": "0",
    "silver": "0",
    "bronze": "2",
    "totalMedals": "2"
  },
  {
    "rank": "82",
    "country": "马来西亚",
    "gold": "0",
    "silver": "0",
    "bronze": "2",
    "totalMedals": "2"
  },
  {
    "rank": "83",
    "country": "波多黎各",
    "gold": "0",
    "silver": "0",
    "bronze": "2",
    "totalMedals": "2"
  },
  {
    "rank": "84",
    "country": "科特迪瓦",
    "gold": "0",
    "silver": "0",
    "bronze": "1",
    "totalMedals": "1"
  },
  {
    "rank": "85",
    "country": "佛得角",
    "gold": "0",
    "silver": "0",
    "bronze": "1",
    "totalMedals": "1"
  },
  {
    "rank": "86",
    "country": "奥林匹克难民代表团",
    "gold": "0",
    "silver": "0",
    "bronze": "1",
    "totalMedals": "1"
  },
  {
    "rank": "87",
    "country": "秘鲁",
    "gold": "0",
    "silver": "0",
    "bronze": "1",
    "totalMedals": "1"
  },
  {
    "rank": "88",
    "country": "卡塔尔",
    "gold": "0",
    "silver": "0",
    "bronze": "1",
    "totalMedals": "1"
  },
  {
    "rank": "89",
    "country": "新加坡",
    "gold": "0",
    "silver": "0",
    "bronze": "1",
    "totalMedals": "1"
  },
  {
    "rank": "90",
    "country": "斯洛伐克",
    "gold": "0",
    "silver": "0",
    "bronze": "1",
    "totalMedals": "1"
  },
  {
    "rank": "91",
    "country": "赞比亚",
    "gold": "0",
    "silver": "0",
    "bronze": "1",
    "totalMedals": "1"
  },
];
export default medalData;