/* eslint-disable no-unused-vars */
const matchData = [
  {
    "matchId": "1",
    "ParisTime": "00:24",
    "BeijingTime": "02日06:24",
    "eventName": "冲浪",
    "matchDetail": "男子1/4决赛 - 第2组 瓦斯特 VS 杜鲁",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "2",
    "ParisTime": "01:00",
    "BeijingTime": "02日07:00",
    "eventName": "冲浪",
    "matchDetail": "男子1/4决赛 - 第3组 梅迪纳 VS 希安卡",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "3",
    "ParisTime": "01:36",
    "BeijingTime": "02日07:36",
    "eventName": "冲浪",
    "matchDetail": "男子1/4决赛 - 第4组 罗宾逊 VS 尤因",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "4",
    "ParisTime": "02:12",
    "BeijingTime": "02日08:12",
    "eventName": "冲浪",
    "matchDetail": "女子1/4决赛 - 第1组 马克斯 VS 赖特",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "5",
    "ParisTime": "02:48",
    "BeijingTime": "02日08:48",
    "eventName": "冲浪",
    "matchDetail": "女子1/4决赛 - 第2组 德菲 VS 穆尔",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "6",
    "ParisTime": "03:24",
    "BeijingTime": "02日09:24",
    "eventName": "冲浪",
    "matchDetail": "女子1/4决赛 - 第3组 埃罗斯塔布 VS 韦斯顿-韦伯",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "7",
    "ParisTime": "04:00",
    "BeijingTime": "02日10:00",
    "eventName": "冲浪",
    "matchDetail": "女子1/4决赛 - 第4组 席尔瓦 VS 亨尼西",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "8",
    "ParisTime": "08:30",
    "BeijingTime": "02日14:30",
    "eventName": "羽毛球",
    "matchDetail": "女子双打半决赛 贾一凡/陈清晨2:1蒂娜/陈康乐",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "9",
    "ParisTime": "09:00",
    "BeijingTime": "02日15:00",
    "eventName": "三人篮球",
    "matchDetail": "女子分组循环赛 中国15:18德国",
    "venue": "协和广场"
  },
  {
    "matchId": "10",
    "ParisTime": "09:00",
    "BeijingTime": "02日15:00",
    "eventName": "高尔夫",
    "matchDetail": "男子第2回合",
    "venue": "国家高尔夫球场"
  },
  {
    "matchId": "11",
    "ParisTime": "09:00",
    "BeijingTime": "02日15:00",
    "eventName": "手球",
    "matchDetail": "男子预赛B组 匈牙利25:28丹麦",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "12",
    "ParisTime": "09:00",
    "BeijingTime": "02日15:00",
    "eventName": "射击",
    "matchDetail": "女子25米手枪资格赛",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "13",
    "ParisTime": "09:00",
    "BeijingTime": "02日15:00",
    "eventName": "射击",
    "matchDetail": "女子25米手枪精准资格赛",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "14",
    "ParisTime": "09:00",
    "BeijingTime": "02日15:00",
    "eventName": "沙滩排球",
    "matchDetail": "女子预赛 - E组 立陶宛0:2日本",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "15",
    "ParisTime": "09:00",
    "BeijingTime": "02日15:00",
    "eventName": "排球",
    "matchDetail": "男子预赛 - C组 阿根廷0:3德国",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "16",
    "ParisTime": "09:30",
    "BeijingTime": "02日15:30",
    "eventName": "射箭",
    "matchDetail": "混合团体1/8淘汰赛 内斯波利/雷巴利亚蒂 VS 亚的斯/巴贝林",
    "venue": "荣军院"
  },
  {
    "matchId": "17",
    "ParisTime": "09:30",
    "BeijingTime": "02日15:30",
    "eventName": "三人篮球",
    "matchDetail": "女子分组循环赛 澳大利亚21:12阿塞拜疆",
    "venue": "协和广场"
  },
  {
    "matchId": "18",
    "ParisTime": "09:30",
    "BeijingTime": "02日15:30",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨决赛F",
    "venue": "水上体育场"
  },
  {
    "matchId": "19",
    "ParisTime": "09:30",
    "BeijingTime": "02日15:30",
    "eventName": "射击",
    "matchDetail": "男子双向飞碟资格赛",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "20",
    "ParisTime": "09:30",
    "BeijingTime": "02日15:30",
    "eventName": "射击",
    "matchDetail": "男子双向飞碟资格赛 - 第1天",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "21",
    "ParisTime": "09:30",
    "BeijingTime": "02日15:30",
    "eventName": "射击",
    "matchDetail": "女子50米步枪三姿决赛",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "22",
    "ParisTime": "09:40",
    "BeijingTime": "02日15:40",
    "eventName": "羽毛球",
    "matchDetail": "女子双打半决赛 刘圣书/谭宁2:0志田千阳/松山奈未",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "23",
    "ParisTime": "09:42",
    "BeijingTime": "02日15:42",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨决赛F",
    "venue": "水上体育场"
  },
  {
    "matchId": "24",
    "ParisTime": "09:49",
    "BeijingTime": "02日15:49",
    "eventName": "射箭",
    "matchDetail": "混合团体1/8淘汰赛 博姆马德瓦拉/巴哈特 VS 乔鲁尼萨/潘格斯图",
    "venue": "荣军院"
  },
  {
    "matchId": "25",
    "ParisTime": "09:54",
    "BeijingTime": "02日15:54",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨决赛E",
    "venue": "水上体育场"
  },
  {
    "matchId": "26",
    "ParisTime": "10:00",
    "BeijingTime": "02日16:00",
    "eventName": "曲棍球",
    "matchDetail": "女子分组循环赛A组 中国2:4德国",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "27",
    "ParisTime": "10:00",
    "BeijingTime": "02日16:00",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级32强淘汰赛 塔塔罗格鲁11:0马里尼奇",
    "venue": "战神广场"
  },
  {
    "matchId": "28",
    "ParisTime": "10:00",
    "BeijingTime": "02日16:00",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级32强淘汰赛 松基什维利10:0卢希特",
    "venue": "战神广场"
  },
  {
    "matchId": "29",
    "ParisTime": "10:00",
    "BeijingTime": "02日16:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打半决赛 申裕斌0:4陈梦",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "30",
    "ParisTime": "10:00",
    "BeijingTime": "02日16:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - B组 意大利0:2智利",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "31",
    "ParisTime": "10:05",
    "BeijingTime": "02日16:05",
    "eventName": "田径",
    "matchDetail": "男子十项全能100米",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "32",
    "ParisTime": "10:05",
    "BeijingTime": "02日16:05",
    "eventName": "田径",
    "matchDetail": "男子十项全能100米 - 第1组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "33",
    "ParisTime": "10:05",
    "BeijingTime": "02日16:05",
    "eventName": "三人篮球",
    "matchDetail": "男子分组循环赛 立陶宛21:16中国",
    "venue": "协和广场"
  },
  {
    "matchId": "34",
    "ParisTime": "10:06",
    "BeijingTime": "02日16:06",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级32强淘汰赛 科考里10:0席尔瓦",
    "venue": "战神广场"
  },
  {
    "matchId": "35",
    "ParisTime": "10:06",
    "BeijingTime": "02日16:06",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级32强淘汰赛 努内斯10:0姆祖吉",
    "venue": "战神广场"
  },
  {
    "matchId": "36",
    "ParisTime": "10:06",
    "BeijingTime": "02日16:06",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨决赛E",
    "venue": "水上体育场"
  },
  {
    "matchId": "37",
    "ParisTime": "10:08",
    "BeijingTime": "02日16:08",
    "eventName": "射箭",
    "matchDetail": "混合团体1/8淘汰赛 卡纳莱斯/阿查·冈萨雷斯 VS 杨晓蕾/王岩",
    "venue": "荣军院"
  },
  {
    "matchId": "38",
    "ParisTime": "10:10",
    "BeijingTime": "02日16:10",
    "eventName": "田径",
    "matchDetail": "男子链球资格赛 - A组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "39",
    "ParisTime": "10:12",
    "BeijingTime": "02日16:12",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级32强淘汰赛 克尔帕莱克10:0斯尼佩",
    "venue": "战神广场"
  },
  {
    "matchId": "40",
    "ParisTime": "10:12",
    "BeijingTime": "02日16:12",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级32强淘汰赛 安德鲁斯0:10塞里克",
    "venue": "战神广场"
  },
  {
    "matchId": "41",
    "ParisTime": "10:13",
    "BeijingTime": "02日16:13",
    "eventName": "田径",
    "matchDetail": "男子十项全能100米 - 第2组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "42",
    "ParisTime": "10:15",
    "BeijingTime": "02日16:15",
    "eventName": "田径",
    "matchDetail": "女子跳高资格赛",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "43",
    "ParisTime": "10:15",
    "BeijingTime": "02日16:15",
    "eventName": "田径",
    "matchDetail": "女子跳高资格赛 - A组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "44",
    "ParisTime": "10:15",
    "BeijingTime": "02日16:15",
    "eventName": "田径",
    "matchDetail": "女子跳高资格赛 - B组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "45",
    "ParisTime": "10:18",
    "BeijingTime": "02日16:18",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级32强淘汰赛 阿勃拉莫夫1:0奥德库",
    "venue": "战神广场"
  },
  {
    "matchId": "46",
    "ParisTime": "10:18",
    "BeijingTime": "02日16:18",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级32强淘汰赛 巴巴里·扎尔菲0:10莫里略",
    "venue": "战神广场"
  },
  {
    "matchId": "47",
    "ParisTime": "10:18",
    "BeijingTime": "02日16:18",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨决赛D",
    "venue": "水上体育场"
  },
  {
    "matchId": "48",
    "ParisTime": "10:21",
    "BeijingTime": "02日16:21",
    "eventName": "田径",
    "matchDetail": "男子十项全能100米 - 第3组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "49",
    "ParisTime": "10:24",
    "BeijingTime": "02日16:24",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级32强淘汰赛 塔卡亚瓦0:10菲泽尔",
    "venue": "战神广场"
  },
  {
    "matchId": "50",
    "ParisTime": "10:24",
    "BeijingTime": "02日16:24",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级32强淘汰赛 波图翁多·伊萨西0:10玛伦科",
    "venue": "战神广场"
  },
  {
    "matchId": "51",
    "ParisTime": "10:27",
    "BeijingTime": "02日16:27",
    "eventName": "射箭",
    "matchDetail": "混合团体1/8淘汰赛 林是见/金优镇 VS 戴宇轩/雷千莹",
    "venue": "荣军院"
  },
  {
    "matchId": "52",
    "ParisTime": "10:30",
    "BeijingTime": "02日16:30",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛A组 荷兰5:3西班牙",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "53",
    "ParisTime": "10:30",
    "BeijingTime": "02日16:30",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级32强淘汰赛 恩迪亚耶10:0马内",
    "venue": "战神广场"
  },
  {
    "matchId": "54",
    "ParisTime": "10:30",
    "BeijingTime": "02日16:30",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级32强淘汰赛 坎普斯10:0霍曼",
    "venue": "战神广场"
  },
  {
    "matchId": "55",
    "ParisTime": "10:30",
    "BeijingTime": "02日16:30",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨决赛D",
    "venue": "水上体育场"
  },
  {
    "matchId": "56",
    "ParisTime": "10:35",
    "BeijingTime": "02日16:35",
    "eventName": "田径",
    "matchDetail": "女子100米预赛",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "57",
    "ParisTime": "10:35",
    "BeijingTime": "02日16:35",
    "eventName": "田径",
    "matchDetail": "女子100米预赛 - 第1组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "58",
    "ParisTime": "10:35",
    "BeijingTime": "02日16:35",
    "eventName": "三人篮球",
    "matchDetail": "男子分组循环赛 波兰17:21荷兰",
    "venue": "协和广场"
  },
  {
    "matchId": "59",
    "ParisTime": "10:36",
    "BeijingTime": "02日16:36",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级32强淘汰赛 马戈梅多马罗夫·马戈梅多马罗夫10:0莉莉",
    "venue": "战神广场"
  },
  {
    "matchId": "60",
    "ParisTime": "10:36",
    "BeijingTime": "02日16:36",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级32强淘汰赛 塔瓦诺0:10扎比克",
    "venue": "战神广场"
  },
  {
    "matchId": "61",
    "ParisTime": "10:42",
    "BeijingTime": "02日16:42",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级6强淘汰赛 金民宗11:0塔塔罗格鲁",
    "venue": "战神广场"
  },
  {
    "matchId": "62",
    "ParisTime": "10:42",
    "BeijingTime": "02日16:42",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级32强淘汰赛 奥尔蒂斯10:0马安 图利卡",
    "venue": "战神广场"
  },
  {
    "matchId": "63",
    "ParisTime": "10:42",
    "BeijingTime": "02日16:42",
    "eventName": "赛艇",
    "matchDetail": "男子双人单桨决赛B",
    "venue": "水上体育场"
  },
  {
    "matchId": "64",
    "ParisTime": "10:43",
    "BeijingTime": "02日16:43",
    "eventName": "田径",
    "matchDetail": "女子100米预赛 - 第2组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "65",
    "ParisTime": "10:46",
    "BeijingTime": "02日16:46",
    "eventName": "射箭",
    "matchDetail": "混合团体1/8淘汰赛 格兰德/瓦伦西亚 VS 戴尔梅达/斯里亚克蒂卡斯·卡埃塔诺",
    "venue": "荣军院"
  },
  {
    "matchId": "66",
    "ParisTime": "10:48",
    "BeijingTime": "02日16:48",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级16强淘汰赛 普马莱宁0:10科考里",
    "venue": "战神广场"
  },
  {
    "matchId": "67",
    "ParisTime": "10:48",
    "BeijingTime": "02日16:48",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级32强淘汰赛 阿玛赛汗10:0贝尔利卡什",
    "venue": "战神广场"
  },
  {
    "matchId": "68",
    "ParisTime": "10:50",
    "BeijingTime": "02日16:50",
    "eventName": "羽毛球",
    "matchDetail": "男子双打半决赛 梁伟铿/王昶2:1苏伟译/谢定峰",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "69",
    "ParisTime": "10:51",
    "BeijingTime": "02日16:51",
    "eventName": "田径",
    "matchDetail": "女子100米预赛 - 第3组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "70",
    "ParisTime": "10:54",
    "BeijingTime": "02日16:54",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级16强淘汰赛 格兰达10:0加多",
    "venue": "战神广场"
  },
  {
    "matchId": "71",
    "ParisTime": "10:54",
    "BeijingTime": "02日16:54",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级32强淘汰赛 索皮·姆贝拉0:10曽根 晃",
    "venue": "战神广场"
  },
  {
    "matchId": "72",
    "ParisTime": "10:54",
    "BeijingTime": "02日16:54",
    "eventName": "赛艇",
    "matchDetail": "女子双人单桨决赛B",
    "venue": "水上体育场"
  },
  {
    "matchId": "73",
    "ParisTime": "10:55",
    "BeijingTime": "02日16:55",
    "eventName": "田径",
    "matchDetail": "男子十项全能跳远",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "74",
    "ParisTime": "10:55",
    "BeijingTime": "02日16:55",
    "eventName": "田径",
    "matchDetail": "男子十项全能跳远 - A组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "75",
    "ParisTime": "10:55",
    "BeijingTime": "02日16:55",
    "eventName": "田径",
    "matchDetail": "男子十项全能跳远 - B组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "76",
    "ParisTime": "10:59",
    "BeijingTime": "02日16:59",
    "eventName": "田径",
    "matchDetail": "女子100米预赛 - 第4组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "77",
    "ParisTime": "11:00",
    "BeijingTime": "02日17:00",
    "eventName": "篮球",
    "matchDetail": "男子小组赛 - B组 日本84:102巴西",
    "venue": "皮埃尔·莫鲁瓦体育场"
  },
  {
    "matchId": "78",
    "ParisTime": "11:00",
    "BeijingTime": "02日17:00",
    "eventName": "跳水",
    "matchDetail": "男子双人3米跳板跳水",
    "venue": "水上运动中心"
  },
  {
    "matchId": "79",
    "ParisTime": "11:00",
    "BeijingTime": "02日17:00",
    "eventName": "手球",
    "matchDetail": "男子预赛B组 阿根廷21:28法国",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "80",
    "ParisTime": "11:00",
    "BeijingTime": "02日17:00",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级16强淘汰赛 齐藤 达10:0克尔帕莱克",
    "venue": "战神广场"
  },
  {
    "matchId": "81",
    "ParisTime": "11:00",
    "BeijingTime": "02日17:00",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级16强淘汰赛 迪科10:0松基什维利",
    "venue": "战神广场"
  },
  {
    "matchId": "82",
    "ParisTime": "11:00",
    "BeijingTime": "02日17:00",
    "eventName": "游泳",
    "matchDetail": "男子100米蝶泳分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "83",
    "ParisTime": "11:00",
    "BeijingTime": "02日17:00",
    "eventName": "游泳",
    "matchDetail": "男子100米蝶泳分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "84",
    "ParisTime": "11:00",
    "BeijingTime": "02日17:00",
    "eventName": "乒乓球",
    "matchDetail": "男子单打半决赛 勒布伦0:4樊振东",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "85",
    "ParisTime": "11:00",
    "BeijingTime": "02日17:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - E组 奥地利0:2加拿大",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "86",
    "ParisTime": "11:03",
    "BeijingTime": "02日17:03",
    "eventName": "游泳",
    "matchDetail": "男子100米蝶泳分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "87",
    "ParisTime": "11:05",
    "BeijingTime": "02日17:05",
    "eventName": "射箭",
    "matchDetail": "混合团体1/8淘汰赛 中西绚哉/野田纱月 VS 加佐兹/戈基尔",
    "venue": "荣军院"
  },
  {
    "matchId": "88",
    "ParisTime": "11:06",
    "BeijingTime": "02日17:06",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级16强淘汰赛 拉希莫夫10:1阿勃拉莫夫",
    "venue": "战神广场"
  },
  {
    "matchId": "89",
    "ParisTime": "11:06",
    "BeijingTime": "02日17:06",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级16强淘汰赛 努内斯0:10塞里克",
    "venue": "战神广场"
  },
  {
    "matchId": "90",
    "ParisTime": "11:06",
    "BeijingTime": "02日17:06",
    "eventName": "赛艇",
    "matchDetail": "男子轻量级双人双桨决赛B",
    "venue": "水上体育场"
  },
  {
    "matchId": "91",
    "ParisTime": "11:06",
    "BeijingTime": "02日17:06",
    "eventName": "游泳",
    "matchDetail": "男子100米蝶泳分组预赛第3组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "92",
    "ParisTime": "11:09",
    "BeijingTime": "02日17:09",
    "eventName": "游泳",
    "matchDetail": "男子100米蝶泳分组预赛第4组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "93",
    "ParisTime": "11:10",
    "BeijingTime": "02日17:10",
    "eventName": "田径",
    "matchDetail": "男子1500米第1轮",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "94",
    "ParisTime": "11:10",
    "BeijingTime": "02日17:10",
    "eventName": "田径",
    "matchDetail": "男子1500米第1轮 - 第1组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "95",
    "ParisTime": "11:12",
    "BeijingTime": "02日17:12",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级16强淘汰赛 尤苏波夫10:0菲泽尔",
    "venue": "战神广场"
  },
  {
    "matchId": "96",
    "ParisTime": "11:12",
    "BeijingTime": "02日17:12",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级16强淘汰赛 金荷伦10:0莫里略",
    "venue": "战神广场"
  },
  {
    "matchId": "97",
    "ParisTime": "11:12",
    "BeijingTime": "02日17:12",
    "eventName": "游泳",
    "matchDetail": "男子100米蝶泳分组预赛第5组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "98",
    "ParisTime": "11:17",
    "BeijingTime": "02日17:17",
    "eventName": "游泳",
    "matchDetail": "女子200米个人混合泳分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "99",
    "ParisTime": "11:17",
    "BeijingTime": "02日17:17",
    "eventName": "游泳",
    "matchDetail": "女子200米个人混合泳分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "100",
    "ParisTime": "11:18",
    "BeijingTime": "02日17:18",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级16强淘汰赛 图希什维利10:0恩迪亚耶",
    "venue": "战神广场"
  },
  {
    "matchId": "101",
    "ParisTime": "11:18",
    "BeijingTime": "02日17:18",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级16强淘汰赛 苏沙10:0玛伦科",
    "venue": "战神广场"
  },
  {
    "matchId": "102",
    "ParisTime": "11:18",
    "BeijingTime": "02日17:18",
    "eventName": "赛艇",
    "matchDetail": "女子轻量级双人双桨决赛B",
    "venue": "水上体育场"
  },
  {
    "matchId": "103",
    "ParisTime": "11:21",
    "BeijingTime": "02日17:21",
    "eventName": "田径",
    "matchDetail": "男子1500米第1轮 - 第2组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "104",
    "ParisTime": "11:21",
    "BeijingTime": "02日17:21",
    "eventName": "游泳",
    "matchDetail": "女子200米个人混合泳分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "105",
    "ParisTime": "11:24",
    "BeijingTime": "02日17:24",
    "eventName": "射箭",
    "matchDetail": "混合团体1/8淘汰赛 埃利森/考夫霍尔德 VS 萨迪科夫/阿卜杜萨托罗娃",
    "venue": "荣军院"
  },
  {
    "matchId": "106",
    "ParisTime": "11:24",
    "BeijingTime": "02日17:24",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级16强淘汰赛 里内10:0马戈梅多马罗夫·马戈梅多马罗夫",
    "venue": "战神广场"
  },
  {
    "matchId": "107",
    "ParisTime": "11:24",
    "BeijingTime": "02日17:24",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级16强淘汰赛 赫什科10:0坎普斯",
    "venue": "战神广场"
  },
  {
    "matchId": "108",
    "ParisTime": "11:26",
    "BeijingTime": "02日17:26",
    "eventName": "游泳",
    "matchDetail": "女子200米个人混合泳分组预赛第3组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "109",
    "ParisTime": "11:30",
    "BeijingTime": "02日17:30",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级1/4决赛 金民宗1:0科考里",
    "venue": "战神广场"
  },
  {
    "matchId": "110",
    "ParisTime": "11:30",
    "BeijingTime": "02日17:30",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级16强淘汰赛 扎比克10:0奥尔蒂斯",
    "venue": "战神广场"
  },
  {
    "matchId": "111",
    "ParisTime": "11:30",
    "BeijingTime": "02日17:30",
    "eventName": "赛艇",
    "matchDetail": "男子双人单桨决赛A",
    "venue": "水上体育场"
  },
  {
    "matchId": "112",
    "ParisTime": "11:30",
    "BeijingTime": "02日17:30",
    "eventName": "游泳",
    "matchDetail": "女子200米个人混合泳分组预赛第4组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "113",
    "ParisTime": "11:32",
    "BeijingTime": "02日17:32",
    "eventName": "田径",
    "matchDetail": "男子1500米第1轮 - 第3组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "114",
    "ParisTime": "11:35",
    "BeijingTime": "02日17:35",
    "eventName": "田径",
    "matchDetail": "男子链球资格赛 - B组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "115",
    "ParisTime": "11:35",
    "BeijingTime": "02日17:35",
    "eventName": "游泳",
    "matchDetail": "女子200米个人混合泳分组预赛第5组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "116",
    "ParisTime": "11:36",
    "BeijingTime": "02日17:36",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级1/4决赛 格兰达0:1齐藤 达",
    "venue": "战神广场"
  },
  {
    "matchId": "117",
    "ParisTime": "11:36",
    "BeijingTime": "02日17:36",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级16强淘汰赛 厄兹德米尔10:0阿玛赛汗",
    "venue": "战神广场"
  },
  {
    "matchId": "118",
    "ParisTime": "11:40",
    "BeijingTime": "02日17:40",
    "eventName": "游泳",
    "matchDetail": "女子800米自由泳分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "119",
    "ParisTime": "11:40",
    "BeijingTime": "02日17:40",
    "eventName": "游泳",
    "matchDetail": "女子800米自由泳分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "120",
    "ParisTime": "11:42",
    "BeijingTime": "02日17:42",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级1/4决赛 拉希莫夫11:1尤苏波夫",
    "venue": "战神广场"
  },
  {
    "matchId": "121",
    "ParisTime": "11:42",
    "BeijingTime": "02日17:42",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级16强淘汰赛 徐仕妍0:1曽根 晃",
    "venue": "战神广场"
  },
  {
    "matchId": "122",
    "ParisTime": "11:42",
    "BeijingTime": "02日17:42",
    "eventName": "赛艇",
    "matchDetail": "女子双人单桨决赛A",
    "venue": "水上体育场"
  },
  {
    "matchId": "123",
    "ParisTime": "11:43",
    "BeijingTime": "02日17:43",
    "eventName": "射箭",
    "matchDetail": "混合团体1/8淘汰赛 伦东·马丁内斯/阿尔西拉 VS 乌恩鲁/克罗彭",
    "venue": "荣军院"
  },
  {
    "matchId": "124",
    "ParisTime": "11:48",
    "BeijingTime": "02日17:48",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级1/4决赛 图希什维利0:10里内",
    "venue": "战神广场"
  },
  {
    "matchId": "125",
    "ParisTime": "11:48",
    "BeijingTime": "02日17:48",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级1/4决赛 迪科10:0塞里克",
    "venue": "战神广场"
  },
  {
    "matchId": "126",
    "ParisTime": "11:50",
    "BeijingTime": "02日17:50",
    "eventName": "田径",
    "matchDetail": "女子100米第1轮",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "127",
    "ParisTime": "11:50",
    "BeijingTime": "02日17:50",
    "eventName": "田径",
    "matchDetail": "女子100米第1轮 - 第1组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "128",
    "ParisTime": "11:52",
    "BeijingTime": "02日17:52",
    "eventName": "游泳",
    "matchDetail": "女子800米自由泳分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "129",
    "ParisTime": "11:54",
    "BeijingTime": "02日17:54",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级1/4决赛 金荷伦0:1苏沙",
    "venue": "战神广场"
  },
  {
    "matchId": "130",
    "ParisTime": "11:58",
    "BeijingTime": "02日17:58",
    "eventName": "田径",
    "matchDetail": "女子100米第1轮 - 第2组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "131",
    "ParisTime": "12:00",
    "BeijingTime": "02日18:00",
    "eventName": "羽毛球",
    "matchDetail": "男子双打半决赛 拉斯姆森/阿斯特鲁普1:2李洋/王齐麟",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "132",
    "ParisTime": "12:00",
    "BeijingTime": "02日18:00",
    "eventName": "蹦床",
    "matchDetail": "女子预赛",
    "venue": "贝尔西体育馆"
  },
  {
    "matchId": "133",
    "ParisTime": "12:00",
    "BeijingTime": "02日18:00",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级1/4决赛 赫什科10:0扎比克",
    "venue": "战神广场"
  },
  {
    "matchId": "134",
    "ParisTime": "12:00",
    "BeijingTime": "02日18:00",
    "eventName": "射击",
    "matchDetail": "女子25米手枪速射资格赛",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "135",
    "ParisTime": "12:00",
    "BeijingTime": "02日18:00",
    "eventName": "网球",
    "matchDetail": "男子双打半决赛 皮尔斯/艾伯登2:0保罗/弗里茨",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "136",
    "ParisTime": "12:00",
    "BeijingTime": "02日18:00",
    "eventName": "网球",
    "matchDetail": "女子双打半决赛 穆霍娃/诺斯科娃0:2保利尼/埃拉尼",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "137",
    "ParisTime": "12:00",
    "BeijingTime": "02日18:00",
    "eventName": "网球",
    "matchDetail": "女子双打半决赛 布克沙/索比斯·托莫0:2安德烈耶娃/施奈德",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "138",
    "ParisTime": "12:00",
    "BeijingTime": "02日18:00",
    "eventName": "网球",
    "matchDetail": "混合双打铜牌赛 奥热-阿利亚西姆/达布罗夫斯基2:0库尔霍夫/舒尔斯",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "139",
    "ParisTime": "12:00",
    "BeijingTime": "02日18:00",
    "eventName": "沙滩排球",
    "matchDetail": "女子预赛 - C组 法国1:2捷克",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "140",
    "ParisTime": "12:02",
    "BeijingTime": "02日18:02",
    "eventName": "赛艇",
    "matchDetail": "男子轻量级双人双桨决赛A",
    "venue": "水上体育场"
  },
  {
    "matchId": "141",
    "ParisTime": "12:03",
    "BeijingTime": "02日18:03",
    "eventName": "游泳",
    "matchDetail": "男女混合4x100米混合泳接力分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "142",
    "ParisTime": "12:03",
    "BeijingTime": "02日18:03",
    "eventName": "游泳",
    "matchDetail": "男女混合4x100米混合泳接力分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "143",
    "ParisTime": "12:05",
    "BeijingTime": "02日18:05",
    "eventName": "帆船",
    "matchDetail": "女子帆船激光雷迪尔级 - 比赛02",
    "venue": "马赛港"
  },
  {
    "matchId": "144",
    "ParisTime": "12:05",
    "BeijingTime": "02日18:05",
    "eventName": "帆船",
    "matchDetail": "男女混合帆船470级 - 比赛01",
    "venue": "马赛港"
  },
  {
    "matchId": "145",
    "ParisTime": "12:06",
    "BeijingTime": "02日18:06",
    "eventName": "田径",
    "matchDetail": "女子100米第1轮 - 第3组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "146",
    "ParisTime": "12:06",
    "BeijingTime": "02日18:06",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级1/4决赛 厄兹德米尔1:0曽根 晃",
    "venue": "战神广场"
  },
  {
    "matchId": "147",
    "ParisTime": "12:09",
    "BeijingTime": "02日18:09",
    "eventName": "游泳",
    "matchDetail": "男女混合4x100米混合泳接力分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "148",
    "ParisTime": "12:13",
    "BeijingTime": "02日18:13",
    "eventName": "帆船",
    "matchDetail": "女子帆船49人FX级奖牌赛",
    "venue": "马赛港"
  },
  {
    "matchId": "149",
    "ParisTime": "12:14",
    "BeijingTime": "02日18:14",
    "eventName": "田径",
    "matchDetail": "女子100米第1轮 - 第4组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "150",
    "ParisTime": "12:15",
    "BeijingTime": "02日18:15",
    "eventName": "田径",
    "matchDetail": "男子十项全能铅球",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "151",
    "ParisTime": "12:15",
    "BeijingTime": "02日18:15",
    "eventName": "田径",
    "matchDetail": "男子十项全能铅球 - A组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "152",
    "ParisTime": "12:15",
    "BeijingTime": "02日18:15",
    "eventName": "田径",
    "matchDetail": "男子十项全能铅球 - B组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "153",
    "ParisTime": "12:20",
    "BeijingTime": "02日18:20",
    "eventName": "帆船",
    "matchDetail": "男子帆船激光级- 比赛03",
    "venue": "马赛港"
  },
  {
    "matchId": "154",
    "ParisTime": "12:22",
    "BeijingTime": "02日18:22",
    "eventName": "田径",
    "matchDetail": "女子100米第1轮 - 第5组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "155",
    "ParisTime": "12:22",
    "BeijingTime": "02日18:22",
    "eventName": "赛艇",
    "matchDetail": "女子轻量级双人双桨决赛A",
    "venue": "水上体育场"
  },
  {
    "matchId": "156",
    "ParisTime": "12:30",
    "BeijingTime": "02日18:30",
    "eventName": "田径",
    "matchDetail": "女子100米第1轮 - 第6组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "157",
    "ParisTime": "12:30",
    "BeijingTime": "02日18:30",
    "eventName": "三人篮球",
    "matchDetail": "女子分组循环赛 澳大利亚17:21西班牙",
    "venue": "协和广场"
  },
  {
    "matchId": "158",
    "ParisTime": "12:38",
    "BeijingTime": "02日18:38",
    "eventName": "田径",
    "matchDetail": "女子100米第1轮 - 第7组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "159",
    "ParisTime": "12:45",
    "BeijingTime": "02日18:45",
    "eventName": "曲棍球",
    "matchDetail": "女子分组循环赛A组 比利时1:3荷兰",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "160",
    "ParisTime": "12:46",
    "BeijingTime": "02日18:46",
    "eventName": "田径",
    "matchDetail": "女子100米第1轮 - 第8组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "161",
    "ParisTime": "13:00",
    "BeijingTime": "02日19:00",
    "eventName": "三人篮球",
    "matchDetail": "女子分组循环赛 美国14:13法国",
    "venue": "协和广场"
  },
  {
    "matchId": "162",
    "ParisTime": "13:00",
    "BeijingTime": "02日19:00",
    "eventName": "排球",
    "matchDetail": "男子预赛 - B组 巴西3:0埃及",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "163",
    "ParisTime": "13:13",
    "BeijingTime": "02日19:13",
    "eventName": "帆船",
    "matchDetail": "男子帆船49人级奖牌赛",
    "venue": "马赛港"
  },
  {
    "matchId": "164",
    "ParisTime": "13:13",
    "BeijingTime": "02日19:13",
    "eventName": "帆船",
    "matchDetail": "女子帆船激光雷迪尔级 - 比赛03",
    "venue": "马赛港"
  },
  {
    "matchId": "165",
    "ParisTime": "13:15",
    "BeijingTime": "02日19:15",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛B组 澳大利亚2:3印度",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "166",
    "ParisTime": "13:30",
    "BeijingTime": "02日19:30",
    "eventName": "篮球",
    "matchDetail": "男子小组赛 - A组 澳大利亚71:77希腊",
    "venue": "皮埃尔·莫鲁瓦体育场"
  },
  {
    "matchId": "167",
    "ParisTime": "13:30",
    "BeijingTime": "02日19:30",
    "eventName": "击剑",
    "matchDetail": "男子团体重剑8强 埃及39:45法国",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "168",
    "ParisTime": "13:30",
    "BeijingTime": "02日19:30",
    "eventName": "击剑",
    "matchDetail": "男子团体重剑8强 哈萨克斯坦30:45匈牙利",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "169",
    "ParisTime": "13:30",
    "BeijingTime": "02日19:30",
    "eventName": "击剑",
    "matchDetail": "男子团体重剑8强 委内瑞拉33:39日本",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "170",
    "ParisTime": "13:30",
    "BeijingTime": "02日19:30",
    "eventName": "击剑",
    "matchDetail": "男子团体重剑8强 意大利38:43捷克",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "171",
    "ParisTime": "13:30",
    "BeijingTime": "02日19:30",
    "eventName": "帆船",
    "matchDetail": "男子帆船激光级- 比赛04",
    "venue": "马赛港"
  },
  {
    "matchId": "172",
    "ParisTime": "13:30",
    "BeijingTime": "02日19:30",
    "eventName": "网球",
    "matchDetail": "男子单打半决赛 奥热-阿利亚西姆0:2阿尔卡拉斯",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "173",
    "ParisTime": "13:30",
    "BeijingTime": "02日19:30",
    "eventName": "网球",
    "matchDetail": "女子单打铜牌赛 斯维亚特克2:0施米德洛娃",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "174",
    "ParisTime": "13:30",
    "BeijingTime": "02日19:30",
    "eventName": "乒乓球",
    "matchDetail": "女子单打半决赛 孙颖莎4:0早田希娜",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "175",
    "ParisTime": "13:35",
    "BeijingTime": "02日19:35",
    "eventName": "三人篮球",
    "matchDetail": "男子分组循环赛 荷兰19:18立陶宛",
    "venue": "协和广场"
  },
  {
    "matchId": "176",
    "ParisTime": "13:50",
    "BeijingTime": "02日19:50",
    "eventName": "蹦床",
    "matchDetail": "女子决赛",
    "venue": "贝尔西体育馆"
  },
  {
    "matchId": "177",
    "ParisTime": "13:50",
    "BeijingTime": "02日19:50",
    "eventName": "帆船",
    "matchDetail": "男女混合帆船470级 - 比赛02",
    "venue": "马赛港"
  },
  {
    "matchId": "178",
    "ParisTime": "14:00",
    "BeijingTime": "02日20:00",
    "eventName": "马术",
    "matchDetail": "团体场地障碍决赛",
    "venue": "凡尔赛宫"
  },
  {
    "matchId": "179",
    "ParisTime": "14:00",
    "BeijingTime": "02日20:00",
    "eventName": "手球",
    "matchDetail": "男子预赛A组 克罗地亚27:38瑞典",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "180",
    "ParisTime": "14:00",
    "BeijingTime": "02日20:00",
    "eventName": "水球",
    "matchDetail": "女子预赛A组 澳大利亚10:7加拿大",
    "venue": "水上运动中心"
  },
  {
    "matchId": "181",
    "ParisTime": "14:05",
    "BeijingTime": "02日20:05",
    "eventName": "三人篮球",
    "matchDetail": "男子分组循环赛 法国20:22拉脱维亚",
    "venue": "协和广场"
  },
  {
    "matchId": "182",
    "ParisTime": "14:15",
    "BeijingTime": "02日20:15",
    "eventName": "射箭",
    "matchDetail": "混合团体1/4决赛 博姆马德瓦拉/巴哈特 VS 卡纳莱斯/阿查·冈萨雷斯",
    "venue": "荣军院"
  },
  {
    "matchId": "183",
    "ParisTime": "14:30",
    "BeijingTime": "02日20:30",
    "eventName": "乒乓球",
    "matchDetail": "男子单打半决赛 莫雷加德4:2卡尔德拉诺",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "184",
    "ParisTime": "14:34",
    "BeijingTime": "02日20:34",
    "eventName": "射箭",
    "matchDetail": "混合团体1/4决赛 林是见/金优镇 VS 内斯波利/雷巴利亚蒂",
    "venue": "荣军院"
  },
  {
    "matchId": "185",
    "ParisTime": "14:53",
    "BeijingTime": "02日20:53",
    "eventName": "射箭",
    "matchDetail": "混合团体1/4决赛 埃利森/考夫霍尔德 VS 中西绚哉/野田纱月",
    "venue": "荣军院"
  },
  {
    "matchId": "186",
    "ParisTime": "15:00",
    "BeijingTime": "02日21:00",
    "eventName": "羽毛球",
    "matchDetail": "混合双打铜牌赛 东野 有纱/渡边勇大2:0徐承宰/蔡侑玎",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "187",
    "ParisTime": "15:00",
    "BeijingTime": "02日21:00",
    "eventName": "击剑",
    "matchDetail": "男子团体重剑5-8名排名赛 埃及21:36哈萨克斯坦",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "188",
    "ParisTime": "15:00",
    "BeijingTime": "02日21:00",
    "eventName": "击剑",
    "matchDetail": "男子团体重剑5-8名排名赛 意大利45:34委内瑞拉",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "189",
    "ParisTime": "15:00",
    "BeijingTime": "02日21:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - F组 西班牙2:0美国",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "190",
    "ParisTime": "15:00",
    "BeijingTime": "02日21:00",
    "eventName": "足球",
    "matchDetail": "男子1/4决赛 摩洛哥4:0美国",
    "venue": "王子公园"
  },
  {
    "matchId": "191",
    "ParisTime": "15:12",
    "BeijingTime": "02日21:12",
    "eventName": "射箭",
    "matchDetail": "混合团体1/4决赛 格兰德/瓦伦西亚 VS 乌恩鲁/克罗彭",
    "venue": "荣军院"
  },
  {
    "matchId": "192",
    "ParisTime": "15:30",
    "BeijingTime": "02日21:30",
    "eventName": "拳击",
    "matchDetail": "女子57公斤级 - 预赛 - 16强赛 林郁婷5:0图尔季别科娃",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "193",
    "ParisTime": "15:30",
    "BeijingTime": "02日21:30",
    "eventName": "皮划艇激流回旋",
    "matchDetail": "男子极限皮艇计时赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "194",
    "ParisTime": "15:30",
    "BeijingTime": "02日21:30",
    "eventName": "皮划艇激流回旋",
    "matchDetail": "Women's Kayak Cross Qualification Phase",
    "venue": "水上体育场"
  },
  {
    "matchId": "195",
    "ParisTime": "15:31",
    "BeijingTime": "02日21:31",
    "eventName": "射箭",
    "matchDetail": "混合团体半决赛 林是见/金优镇 VS 博姆马德瓦拉/巴哈特",
    "venue": "荣军院"
  },
  {
    "matchId": "196",
    "ParisTime": "15:35",
    "BeijingTime": "02日21:35",
    "eventName": "水球",
    "matchDetail": "女子预赛B组 希腊8:12意大利",
    "venue": "水上运动中心"
  },
  {
    "matchId": "197",
    "ParisTime": "15:46",
    "BeijingTime": "02日21:46",
    "eventName": "拳击",
    "matchDetail": "女子57公斤级 - 预赛 - 16强赛 沃尔什0:5斯坦耶娃",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "198",
    "ParisTime": "15:50",
    "BeijingTime": "02日21:50",
    "eventName": "射箭",
    "matchDetail": "混合团体半决赛 埃利森/考夫霍尔德 VS 乌恩鲁/克罗彭",
    "venue": "荣军院"
  },
  {
    "matchId": "199",
    "ParisTime": "15:50",
    "BeijingTime": "02日21:50",
    "eventName": "击剑",
    "matchDetail": "男子团体重剑半决赛1 匈牙利45:30法国",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "200",
    "ParisTime": "15:50",
    "BeijingTime": "02日21:50",
    "eventName": "击剑",
    "matchDetail": "男子团体重剑半决赛2 捷克37:45日本",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "201",
    "ParisTime": "16:00",
    "BeijingTime": "02日22:00",
    "eventName": "手球",
    "matchDetail": "男子预赛A组 德国33:31西班牙",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "202",
    "ParisTime": "16:00",
    "BeijingTime": "02日22:00",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级复活赛 塞里克0:1金荷伦",
    "venue": "战神广场"
  },
  {
    "matchId": "203",
    "ParisTime": "16:00",
    "BeijingTime": "02日22:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - F组 法国0:2荷兰",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "204",
    "ParisTime": "16:02",
    "BeijingTime": "02日22:02",
    "eventName": "拳击",
    "matchDetail": "女子57公斤级 - 预赛 - 16强赛 赫利米0:5耶尔迪兹·卡赫拉曼",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "205",
    "ParisTime": "16:06",
    "BeijingTime": "02日22:06",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级复活赛 扎比克10:0曽根 晃",
    "venue": "战神广场"
  },
  {
    "matchId": "206",
    "ParisTime": "16:10",
    "BeijingTime": "02日22:10",
    "eventName": "羽毛球",
    "matchDetail": "混合双打金牌赛 郑思维/黄雅琼2:0郑娜银/金元浩",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "207",
    "ParisTime": "16:12",
    "BeijingTime": "02日22:12",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级B表半决赛 赫什科10:0厄兹德米尔",
    "venue": "战神广场"
  },
  {
    "matchId": "208",
    "ParisTime": "16:18",
    "BeijingTime": "02日22:18",
    "eventName": "拳击",
    "matchDetail": "女子57公斤级 - 预赛 - 16强赛 门多萨1:4塞尔克维拉·罗梅乌",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "209",
    "ParisTime": "16:18",
    "BeijingTime": "02日22:18",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级A表半决赛 迪科0:10苏沙",
    "venue": "战神广场"
  },
  {
    "matchId": "210",
    "ParisTime": "16:24",
    "BeijingTime": "02日22:24",
    "eventName": "射箭",
    "matchDetail": "混合团体铜牌赛 博姆马德瓦拉/巴哈特 VS 埃利森/考夫霍尔德",
    "venue": "荣军院"
  },
  {
    "matchId": "211",
    "ParisTime": "16:24",
    "BeijingTime": "02日22:24",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级复活赛 科考里0:10格兰达",
    "venue": "战神广场"
  },
  {
    "matchId": "212",
    "ParisTime": "16:30",
    "BeijingTime": "02日22:30",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级复活赛 尤苏波夫10:0图希什维利",
    "venue": "战神广场"
  },
  {
    "matchId": "213",
    "ParisTime": "16:34",
    "BeijingTime": "02日22:34",
    "eventName": "拳击",
    "matchDetail": "男子51公斤级 - 1/4决赛 本纳玛3:2克拉罗·菲兹",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "214",
    "ParisTime": "16:36",
    "BeijingTime": "02日22:36",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级B表半决赛 拉希莫夫0:10里内",
    "venue": "战神广场"
  },
  {
    "matchId": "215",
    "ParisTime": "16:40",
    "BeijingTime": "02日22:40",
    "eventName": "皮划艇激流回旋",
    "matchDetail": "Men's Kayak Cross Qualification Phase",
    "venue": "水上体育场"
  },
  {
    "matchId": "216",
    "ParisTime": "16:40",
    "BeijingTime": "02日22:40",
    "eventName": "皮划艇激流回旋",
    "matchDetail": "女子极限皮艇计时赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "217",
    "ParisTime": "16:40",
    "BeijingTime": "02日22:40",
    "eventName": "击剑",
    "matchDetail": "男子团体重剑5-6名赛 意大利45:36哈萨克斯坦",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "218",
    "ParisTime": "16:40",
    "BeijingTime": "02日22:40",
    "eventName": "击剑",
    "matchDetail": "男子团体重剑7-8名赛 埃及35:41委内瑞拉",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "219",
    "ParisTime": "16:42",
    "BeijingTime": "02日22:42",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级A表半决赛 金民宗10:0齐藤 达",
    "venue": "战神广场"
  },
  {
    "matchId": "220",
    "ParisTime": "16:43",
    "BeijingTime": "02日22:43",
    "eventName": "射箭",
    "matchDetail": "混合团体金牌赛 林是见/金优镇 VS 乌恩鲁/克罗彭",
    "venue": "荣军院"
  },
  {
    "matchId": "221",
    "ParisTime": "16:48",
    "BeijingTime": "02日22:48",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级铜牌争夺赛A 金荷伦10:0厄兹德米尔",
    "venue": "战神广场"
  },
  {
    "matchId": "222",
    "ParisTime": "16:50",
    "BeijingTime": "02日22:50",
    "eventName": "拳击",
    "matchDetail": "男子51公斤级 - 1/4决赛 洛萨诺·塞拉诺2:3阿尔坎塔拉·雷耶斯",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "223",
    "ParisTime": "16:54",
    "BeijingTime": "02日22:54",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级铜牌争夺赛B 扎比克0:10迪科",
    "venue": "战神广场"
  },
  {
    "matchId": "224",
    "ParisTime": "17:00",
    "BeijingTime": "02日23:00",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛B组 新西兰1:2爱尔兰",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "225",
    "ParisTime": "17:00",
    "BeijingTime": "02日23:00",
    "eventName": "柔道",
    "matchDetail": "女子78公斤以上级决赛 苏沙1:0赫什科",
    "venue": "战神广场"
  },
  {
    "matchId": "226",
    "ParisTime": "17:00",
    "BeijingTime": "02日23:00",
    "eventName": "沙滩排球",
    "matchDetail": "女子预赛 - E组 巴西2:1荷兰",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "227",
    "ParisTime": "17:00",
    "BeijingTime": "02日23:00",
    "eventName": "排球",
    "matchDetail": "男子预赛 - A组 法国2:3斯洛文尼亚",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "228",
    "ParisTime": "17:00",
    "BeijingTime": "02日23:00",
    "eventName": "足球",
    "matchDetail": "男子1/4决赛 日本0:3西班牙",
    "venue": "里昂体育场"
  },
  {
    "matchId": "229",
    "ParisTime": "17:06",
    "BeijingTime": "02日23:06",
    "eventName": "拳击",
    "matchDetail": "男子80公斤级 - 1/4决赛 皮纳莱斯5:0维奥契奇",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "230",
    "ParisTime": "17:06",
    "BeijingTime": "02日23:06",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级铜牌争夺赛A 格兰达0:1拉希莫夫",
    "venue": "战神广场"
  },
  {
    "matchId": "231",
    "ParisTime": "17:12",
    "BeijingTime": "02日23:12",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级铜牌争夺赛B 尤苏波夫11:0齐藤 达",
    "venue": "战神广场"
  },
  {
    "matchId": "232",
    "ParisTime": "17:15",
    "BeijingTime": "02日23:15",
    "eventName": "篮球",
    "matchDetail": "男子小组赛 - A组 加拿大88:85西班牙",
    "venue": "皮埃尔·莫鲁瓦体育场"
  },
  {
    "matchId": "233",
    "ParisTime": "17:18",
    "BeijingTime": "02日23:18",
    "eventName": "柔道",
    "matchDetail": "男子100公斤以上级决赛 金民宗0:10里内",
    "venue": "战神广场"
  },
  {
    "matchId": "234",
    "ParisTime": "17:22",
    "BeijingTime": "02日23:22",
    "eventName": "拳击",
    "matchDetail": "男子80公斤级 - 1/4决赛 奥拉尔拜5:0阿拉赫韦尔迪耶夫",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "235",
    "ParisTime": "17:30",
    "BeijingTime": "02日23:30",
    "eventName": "三人篮球",
    "matchDetail": "女子分组循环赛 阿塞拜疆21:19中国",
    "venue": "协和广场"
  },
  {
    "matchId": "236",
    "ParisTime": "17:30",
    "BeijingTime": "02日23:30",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛B组 比利时3:3阿根廷",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "237",
    "ParisTime": "17:35",
    "BeijingTime": "02日23:35",
    "eventName": "羽毛球",
    "matchDetail": "男子单打1/4决赛 周天成1:2森",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "238",
    "ParisTime": "17:38",
    "BeijingTime": "02日23:38",
    "eventName": "拳击",
    "matchDetail": "男子92公斤以上级 - 1/4决赛 伦齐0:5提亚法克",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "239",
    "ParisTime": "17:54",
    "BeijingTime": "02日23:54",
    "eventName": "拳击",
    "matchDetail": "男子92公斤以上级 - 1/4决赛 特雷莫亚纳0:5贾洛洛夫",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "240",
    "ParisTime": "18:00",
    "BeijingTime": "03日00:00",
    "eventName": "田径",
    "matchDetail": "男子十项全能跳高",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "241",
    "ParisTime": "18:00",
    "BeijingTime": "03日00:00",
    "eventName": "田径",
    "matchDetail": "男子十项全能跳高 - A组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "242",
    "ParisTime": "18:00",
    "BeijingTime": "03日00:00",
    "eventName": "田径",
    "matchDetail": "男子十项全能跳高 - B组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "243",
    "ParisTime": "18:00",
    "BeijingTime": "03日00:00",
    "eventName": "三人篮球",
    "matchDetail": "女子分组循环赛 美国18:17加拿大",
    "venue": "协和广场"
  },
  {
    "matchId": "244",
    "ParisTime": "18:00",
    "BeijingTime": "03日00:00",
    "eventName": "蹦床",
    "matchDetail": "男子预赛",
    "venue": "贝尔西体育馆"
  },
  {
    "matchId": "245",
    "ParisTime": "18:10",
    "BeijingTime": "03日00:10",
    "eventName": "田径",
    "matchDetail": "女子5000米第1轮",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "246",
    "ParisTime": "18:10",
    "BeijingTime": "03日00:10",
    "eventName": "田径",
    "matchDetail": "女子5000米第1轮 - 第1组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "247",
    "ParisTime": "18:15",
    "BeijingTime": "03日00:15",
    "eventName": "田径",
    "matchDetail": "女子三级跳远资格赛",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "248",
    "ParisTime": "18:15",
    "BeijingTime": "03日00:15",
    "eventName": "田径",
    "matchDetail": "女子三级跳远资格赛 - A组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "249",
    "ParisTime": "18:15",
    "BeijingTime": "03日00:15",
    "eventName": "田径",
    "matchDetail": "女子三级跳远资格赛 - B组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "250",
    "ParisTime": "18:30",
    "BeijingTime": "03日00:30",
    "eventName": "水球",
    "matchDetail": "女子预赛 - B组 美国17:5法国",
    "venue": "水上运动中心"
  },
  {
    "matchId": "251",
    "ParisTime": "18:35",
    "BeijingTime": "03日00:35",
    "eventName": "三人篮球",
    "matchDetail": "男子分组循环赛 法国19:21美国",
    "venue": "协和广场"
  },
  {
    "matchId": "252",
    "ParisTime": "18:36",
    "BeijingTime": "03日00:36",
    "eventName": "田径",
    "matchDetail": "女子5000米第1轮 - 第2组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "253",
    "ParisTime": "18:45",
    "BeijingTime": "03日00:45",
    "eventName": "羽毛球",
    "matchDetail": "男子单打1/4决赛 石宇奇0:2维蒂萨恩",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "254",
    "ParisTime": "18:55",
    "BeijingTime": "03日00:55",
    "eventName": "田径",
    "matchDetail": "女子铁饼资格赛 - A组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "255",
    "ParisTime": "19:00",
    "BeijingTime": "03日01:00",
    "eventName": "手球",
    "matchDetail": "男子预赛A组 日本28:29斯洛文尼亚",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "256",
    "ParisTime": "19:00",
    "BeijingTime": "03日01:00",
    "eventName": "网球",
    "matchDetail": "男子单打半决赛 德约科维奇2:0穆塞蒂",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "257",
    "ParisTime": "19:00",
    "BeijingTime": "03日01:00",
    "eventName": "网球",
    "matchDetail": "混合双打金牌赛 西尼亚科娃/马查克2:1张之臻/王欣瑜",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "258",
    "ParisTime": "19:00",
    "BeijingTime": "03日01:00",
    "eventName": "足球",
    "matchDetail": "男子1/4决赛 埃及1:1巴拉圭",
    "venue": "马赛体育场"
  },
  {
    "matchId": "259",
    "ParisTime": "19:05",
    "BeijingTime": "03日01:05",
    "eventName": "三人篮球",
    "matchDetail": "男子分组循环赛 拉脱维亚21:14塞尔维亚",
    "venue": "协和广场"
  },
  {
    "matchId": "260",
    "ParisTime": "19:10",
    "BeijingTime": "03日01:10",
    "eventName": "田径",
    "matchDetail": "4x400米混合接力第1轮",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "261",
    "ParisTime": "19:10",
    "BeijingTime": "03日01:10",
    "eventName": "田径",
    "matchDetail": "4x400米混合接力第1轮 - 第1组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "262",
    "ParisTime": "19:22",
    "BeijingTime": "03日01:22",
    "eventName": "田径",
    "matchDetail": "4x400米混合接力第1轮 - 第2组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "263",
    "ParisTime": "19:30",
    "BeijingTime": "03日01:30",
    "eventName": "击剑",
    "matchDetail": "男子团体重剑铜牌赛 捷克43:41法国",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "264",
    "ParisTime": "19:45",
    "BeijingTime": "03日01:45",
    "eventName": "田径",
    "matchDetail": "女子800米第1轮",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "265",
    "ParisTime": "19:45",
    "BeijingTime": "03日01:45",
    "eventName": "田径",
    "matchDetail": "女子800米第1轮 - 第1组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "266",
    "ParisTime": "19:45",
    "BeijingTime": "03日01:45",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛A组 法国2:5南非",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "267",
    "ParisTime": "19:50",
    "BeijingTime": "03日01:50",
    "eventName": "蹦床",
    "matchDetail": "男子决赛",
    "venue": "贝尔西体育馆"
  },
  {
    "matchId": "268",
    "ParisTime": "19:54",
    "BeijingTime": "03日01:54",
    "eventName": "田径",
    "matchDetail": "女子800米第1轮 - 第2组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "269",
    "ParisTime": "19:55",
    "BeijingTime": "03日01:55",
    "eventName": "羽毛球",
    "matchDetail": "男子单打1/4决赛 安东森0:2李梓嘉",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "270",
    "ParisTime": "20:00",
    "BeijingTime": "03日02:00",
    "eventName": "小轮车竞速",
    "matchDetail": "男子半决赛第1轮",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "271",
    "ParisTime": "20:00",
    "BeijingTime": "03日02:00",
    "eventName": "小轮车竞速",
    "matchDetail": "男子半决赛第1轮第1组",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "272",
    "ParisTime": "20:00",
    "BeijingTime": "03日02:00",
    "eventName": "拳击",
    "matchDetail": "女子57公斤级 - 预赛 - 16强赛 齐达尼1:4佩特西奧",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "273",
    "ParisTime": "20:00",
    "BeijingTime": "03日02:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - B组 挪威2:0荷兰",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "274",
    "ParisTime": "20:03",
    "BeijingTime": "03日02:03",
    "eventName": "田径",
    "matchDetail": "女子800米第1轮 - 第3组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "275",
    "ParisTime": "20:05",
    "BeijingTime": "03日02:05",
    "eventName": "小轮车竞速",
    "matchDetail": "男子半决赛第1轮第2组",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "276",
    "ParisTime": "20:05",
    "BeijingTime": "03日02:05",
    "eventName": "水球",
    "matchDetail": "女子预赛A组 中国11:17匈牙利",
    "venue": "水上运动中心"
  },
  {
    "matchId": "277",
    "ParisTime": "20:10",
    "BeijingTime": "03日02:10",
    "eventName": "田径",
    "matchDetail": "男子铅球资格赛",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "278",
    "ParisTime": "20:10",
    "BeijingTime": "03日02:10",
    "eventName": "田径",
    "matchDetail": "男子铅球资格赛 - A组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "279",
    "ParisTime": "20:10",
    "BeijingTime": "03日02:10",
    "eventName": "田径",
    "matchDetail": "男子铅球资格赛 - B组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "280",
    "ParisTime": "20:12",
    "BeijingTime": "03日02:12",
    "eventName": "田径",
    "matchDetail": "女子800米第1轮 - 第4组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "281",
    "ParisTime": "20:15",
    "BeijingTime": "03日02:15",
    "eventName": "小轮车竞速",
    "matchDetail": "女子半决赛第1轮",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "282",
    "ParisTime": "20:15",
    "BeijingTime": "03日02:15",
    "eventName": "小轮车竞速",
    "matchDetail": "女子半决赛第1轮第1组",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "283",
    "ParisTime": "20:15",
    "BeijingTime": "03日02:15",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛A组 英国1:2德国",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "284",
    "ParisTime": "20:16",
    "BeijingTime": "03日02:16",
    "eventName": "拳击",
    "matchDetail": "女子57公斤级 - 预赛 - 16强赛 许紫春3:2阿尔博莱达·门多萨",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "285",
    "ParisTime": "20:20",
    "BeijingTime": "03日02:20",
    "eventName": "田径",
    "matchDetail": "女子铁饼资格赛 - B组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "286",
    "ParisTime": "20:20",
    "BeijingTime": "03日02:20",
    "eventName": "小轮车竞速",
    "matchDetail": "女子半决赛第1轮第2组",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "287",
    "ParisTime": "20:21",
    "BeijingTime": "03日02:21",
    "eventName": "田径",
    "matchDetail": "女子800米第1轮 - 第5组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "288",
    "ParisTime": "20:30",
    "BeijingTime": "03日02:30",
    "eventName": "田径",
    "matchDetail": "女子800米第1轮 - 第6组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "289",
    "ParisTime": "20:30",
    "BeijingTime": "03日02:30",
    "eventName": "小轮车竞速",
    "matchDetail": "男子半决赛第2轮",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "290",
    "ParisTime": "20:30",
    "BeijingTime": "03日02:30",
    "eventName": "小轮车竞速",
    "matchDetail": "男子半决赛第2轮第1组",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "291",
    "ParisTime": "20:30",
    "BeijingTime": "03日02:30",
    "eventName": "击剑",
    "matchDetail": "男子团体重剑金牌赛 日本25:26匈牙利",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "292",
    "ParisTime": "20:30",
    "BeijingTime": "03日02:30",
    "eventName": "游泳",
    "matchDetail": "男子50米自由泳决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "293",
    "ParisTime": "20:32",
    "BeijingTime": "03日02:32",
    "eventName": "拳击",
    "matchDetail": "女子57公斤级 - 预赛 - 16强赛 易卜拉欣莫娃0:5洛萨达·莫达",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "294",
    "ParisTime": "20:35",
    "BeijingTime": "03日02:35",
    "eventName": "小轮车竞速",
    "matchDetail": "男子半决赛第2轮第2组",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "295",
    "ParisTime": "20:37",
    "BeijingTime": "03日02:37",
    "eventName": "游泳",
    "matchDetail": "女子200米仰泳决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "296",
    "ParisTime": "20:45",
    "BeijingTime": "03日02:45",
    "eventName": "小轮车竞速",
    "matchDetail": "女子半决赛第2轮",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "297",
    "ParisTime": "20:45",
    "BeijingTime": "03日02:45",
    "eventName": "小轮车竞速",
    "matchDetail": "女子半决赛第2轮第1组",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "298",
    "ParisTime": "20:45",
    "BeijingTime": "03日02:45",
    "eventName": "游泳",
    "matchDetail": "男子200米个人混合泳决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "299",
    "ParisTime": "20:48",
    "BeijingTime": "03日02:48",
    "eventName": "拳击",
    "matchDetail": "女子57公斤级 - 预赛 - 16强赛 塞雷梅塔5:0拉希米",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "300",
    "ParisTime": "20:50",
    "BeijingTime": "03日02:50",
    "eventName": "田径",
    "matchDetail": "男子十项全能400米",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "301",
    "ParisTime": "20:50",
    "BeijingTime": "03日02:50",
    "eventName": "田径",
    "matchDetail": "男子十项全能400米 - 第1组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "302",
    "ParisTime": "20:50",
    "BeijingTime": "03日02:50",
    "eventName": "小轮车竞速",
    "matchDetail": "女子半决赛第2轮第2组",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "303",
    "ParisTime": "20:58",
    "BeijingTime": "03日02:58",
    "eventName": "田径",
    "matchDetail": "男子十项全能400米 - 第2组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "304",
    "ParisTime": "21:00",
    "BeijingTime": "03日03:00",
    "eventName": "三人篮球",
    "matchDetail": "女子分组循环赛 加拿大20:22西班牙",
    "venue": "协和广场"
  },
  {
    "matchId": "305",
    "ParisTime": "21:00",
    "BeijingTime": "03日03:00",
    "eventName": "篮球",
    "matchDetail": "男子小组赛 - B组 法国71:85德国",
    "venue": "皮埃尔·莫鲁瓦体育场"
  },
  {
    "matchId": "306",
    "ParisTime": "21:00",
    "BeijingTime": "03日03:00",
    "eventName": "小轮车竞速",
    "matchDetail": "男子半决赛第3轮",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "307",
    "ParisTime": "21:00",
    "BeijingTime": "03日03:00",
    "eventName": "小轮车竞速",
    "matchDetail": "男子半决赛第3轮第1组",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "308",
    "ParisTime": "21:00",
    "BeijingTime": "03日03:00",
    "eventName": "手球",
    "matchDetail": "男子预赛B组 挪威25:26埃及",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "309",
    "ParisTime": "21:00",
    "BeijingTime": "03日03:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - E组 捷克0:2巴西",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "310",
    "ParisTime": "21:00",
    "BeijingTime": "03日03:00",
    "eventName": "排球",
    "matchDetail": "男子预赛 - C组 日本1:3美国",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "311",
    "ParisTime": "21:00",
    "BeijingTime": "03日03:00",
    "eventName": "足球",
    "matchDetail": "男子1/4决赛 法国1:0阿根廷",
    "venue": "波尔多体育场"
  },
  {
    "matchId": "312",
    "ParisTime": "21:04",
    "BeijingTime": "03日03:04",
    "eventName": "拳击",
    "matchDetail": "男子51公斤级 - 1/4决赛 奇耶姆巴0:5德皮纳",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "313",
    "ParisTime": "21:05",
    "BeijingTime": "03日03:05",
    "eventName": "羽毛球",
    "matchDetail": "男子单打1/4决赛 骆建佑0:2安赛龙",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "314",
    "ParisTime": "21:05",
    "BeijingTime": "03日03:05",
    "eventName": "小轮车竞速",
    "matchDetail": "男子半决赛第3轮第2组",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "315",
    "ParisTime": "21:05",
    "BeijingTime": "03日03:05",
    "eventName": "游泳",
    "matchDetail": "男子100米蝶泳半决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "316",
    "ParisTime": "21:05",
    "BeijingTime": "03日03:05",
    "eventName": "游泳",
    "matchDetail": "男子100米蝶泳半决赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "317",
    "ParisTime": "21:06",
    "BeijingTime": "03日03:06",
    "eventName": "田径",
    "matchDetail": "男子十项全能400米 - 第3组",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "318",
    "ParisTime": "21:12",
    "BeijingTime": "03日03:12",
    "eventName": "游泳",
    "matchDetail": "男子100米蝶泳半决赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "319",
    "ParisTime": "21:15",
    "BeijingTime": "03日03:15",
    "eventName": "小轮车竞速",
    "matchDetail": "女子半决赛第3轮",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "320",
    "ParisTime": "21:15",
    "BeijingTime": "03日03:15",
    "eventName": "小轮车竞速",
    "matchDetail": "女子半决赛第3轮第1组",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "321",
    "ParisTime": "21:20",
    "BeijingTime": "03日03:20",
    "eventName": "田径",
    "matchDetail": "男子10000米决赛",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "322",
    "ParisTime": "21:20",
    "BeijingTime": "03日03:20",
    "eventName": "小轮车竞速",
    "matchDetail": "女子半决赛第3轮第2组",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "323",
    "ParisTime": "21:20",
    "BeijingTime": "03日03:20",
    "eventName": "拳击",
    "matchDetail": "男子51公斤级 - 1/4决赛 比博西诺夫2:3杜斯马托夫",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "324",
    "ParisTime": "21:30",
    "BeijingTime": "03日03:30",
    "eventName": "三人篮球",
    "matchDetail": "女子分组循环赛 法国13:14德国",
    "venue": "协和广场"
  },
  {
    "matchId": "325",
    "ParisTime": "21:31",
    "BeijingTime": "03日03:31",
    "eventName": "游泳",
    "matchDetail": "女子200米个人混合泳半决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "326",
    "ParisTime": "21:31",
    "BeijingTime": "03日03:31",
    "eventName": "游泳",
    "matchDetail": "女子200米个人混合泳半决赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "327",
    "ParisTime": "21:35",
    "BeijingTime": "03日03:35",
    "eventName": "小轮车竞速",
    "matchDetail": "男子决赛",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "328",
    "ParisTime": "21:36",
    "BeijingTime": "03日03:36",
    "eventName": "拳击",
    "matchDetail": "男子80公斤级 - 1/4决赛 卡兹尼亚克5:0代·苏沙·佩雷拉",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "329",
    "ParisTime": "21:39",
    "BeijingTime": "03日03:39",
    "eventName": "游泳",
    "matchDetail": "女子200米个人混合泳半决赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "330",
    "ParisTime": "21:50",
    "BeijingTime": "03日03:50",
    "eventName": "小轮车竞速",
    "matchDetail": "女子决赛",
    "venue": "圣康丁伊夫林小轮车体育馆"
  },
  {
    "matchId": "331",
    "ParisTime": "21:52",
    "BeijingTime": "03日03:52",
    "eventName": "拳击",
    "matchDetail": "男子80公斤级 - 1/4决赛 哈比布拉耶夫2:3洛佩斯·卡多纳",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "332",
    "ParisTime": "22:00",
    "BeijingTime": "03日04:00",
    "eventName": "沙滩排球",
    "matchDetail": "女子预赛 - C组 美国2:0德国",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "333",
    "ParisTime": "22:05",
    "BeijingTime": "03日04:05",
    "eventName": "三人篮球",
    "matchDetail": "男子分组循环赛 塞尔维亚21:12波兰",
    "venue": "协和广场"
  },
  {
    "matchId": "334",
    "ParisTime": "22:08",
    "BeijingTime": "03日04:08",
    "eventName": "拳击",
    "matchDetail": "男子92公斤以上级 - 1/4决赛 阿布杜·莫泽4:1孔戈·查拉",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "335",
    "ParisTime": "22:24",
    "BeijingTime": "03日04:24",
    "eventName": "拳击",
    "matchDetail": "男子92公斤以上级 - 1/4决赛 加法·德里西·埃尔·艾萨维5:0查洛扬",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "336",
    "ParisTime": "22:35",
    "BeijingTime": "03日04:35",
    "eventName": "三人篮球",
    "matchDetail": "男子分组循环赛 中国17:21美国",
    "venue": "协和广场"
  },
];
export default matchData;