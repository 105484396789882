/* eslint-disable no-unused-vars */
const matchData = [
  {
    "matchId": "1",
    "ParisTime": "08:30",
    "BeijingTime": "27日14:30",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - N组 何冰娇2:0阿扎赫拉",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "2",
    "ParisTime": "08:30",
    "BeijingTime": "27日14:30",
    "eventName": "羽毛球",
    "matchDetail": "混合双打小组赛 - B组 徐承宰/蔡侑玎2:0马梅里",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "3",
    "ParisTime": "08:30",
    "BeijingTime": "27日14:30",
    "eventName": "羽毛球",
    "matchDetail": "混合双打小组赛 - D组 冯彦哲/黄东萍2:0珍妮·盖/邱恺翔",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "4",
    "ParisTime": "09:00",
    "BeijingTime": "27日15:00",
    "eventName": "手球",
    "matchDetail": "男子预赛A组 西班牙25:22斯洛文尼亚",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "5",
    "ParisTime": "09:00",
    "BeijingTime": "27日15:00",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨分组预赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "6",
    "ParisTime": "09:00",
    "BeijingTime": "27日15:00",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨分组预赛第1组",
    "venue": "水上体育场"
  },
  {
    "matchId": "7",
    "ParisTime": "09:00",
    "BeijingTime": "27日15:00",
    "eventName": "射击",
    "matchDetail": "混合团体10米气步枪资格赛",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "8",
    "ParisTime": "09:00",
    "BeijingTime": "27日15:00",
    "eventName": "排球",
    "matchDetail": "男子预赛 - C组 日本2:3德国",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "9",
    "ParisTime": "09:12",
    "BeijingTime": "27日15:12",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨分组预赛第2组",
    "venue": "水上体育场"
  },
  {
    "matchId": "10",
    "ParisTime": "09:20",
    "BeijingTime": "27日15:20",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - A组 梁伟铿/王昶2:0矢仓尼/董星宇",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "11",
    "ParisTime": "09:20",
    "BeijingTime": "27日15:20",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - I组 杨佳敏2:0亚瓦里瓦法",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "12",
    "ParisTime": "09:20",
    "BeijingTime": "27日15:20",
    "eventName": "羽毛球",
    "matchDetail": "混合双打小组赛 - D组 杜颐沩/陈堂杰2:0许永凯/陈薇涵",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "13",
    "ParisTime": "09:24",
    "BeijingTime": "27日15:24",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨分组预赛第3组",
    "venue": "水上体育场"
  },
  {
    "matchId": "14",
    "ParisTime": "09:30",
    "BeijingTime": "27日15:30",
    "eventName": "马术",
    "matchDetail": "三项赛盛装舞步 - 团体赛和个人赛",
    "venue": "凡尔赛宫"
  },
  {
    "matchId": "15",
    "ParisTime": "09:30",
    "BeijingTime": "27日15:30",
    "eventName": "马术",
    "matchDetail": "三项赛个人盛装舞步",
    "venue": "凡尔赛宫"
  },
  {
    "matchId": "16",
    "ParisTime": "09:30",
    "BeijingTime": "27日15:30",
    "eventName": "马术",
    "matchDetail": "三项赛团体盛装舞步",
    "venue": "凡尔赛宫"
  },
  {
    "matchId": "17",
    "ParisTime": "09:36",
    "BeijingTime": "27日15:36",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨分组预赛第4组",
    "venue": "水上体育场"
  },
  {
    "matchId": "18",
    "ParisTime": "09:48",
    "BeijingTime": "27日15:48",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨分组预赛第5组",
    "venue": "水上体育场"
  },
  {
    "matchId": "19",
    "ParisTime": "10:00",
    "BeijingTime": "27日16:00",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑64强 侯赛因15:14迪翁盖",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "20",
    "ParisTime": "10:00",
    "BeijingTime": "27日16:00",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑64强 多伊格·卡尔德隆14:15提卡娜·阿卜杜勒·拉赫曼·基里亚",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "21",
    "ParisTime": "10:00",
    "BeijingTime": "27日16:00",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑64强 吉村 美保15:7乌维霍雷耶",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "22",
    "ParisTime": "10:00",
    "BeijingTime": "27日16:00",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑64强 埃哈卜15:9加伯",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "23",
    "ParisTime": "10:00",
    "BeijingTime": "27日16:00",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛A组 英国4:0西班牙",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "24",
    "ParisTime": "10:00",
    "BeijingTime": "27日16:00",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级32强淘汰赛 卡茨0:1卡尔利诺",
    "venue": "战神广场"
  },
  {
    "matchId": "25",
    "ParisTime": "10:00",
    "BeijingTime": "27日16:00",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级32强淘汰赛 郭宗英0:10拉波尔德",
    "venue": "战神广场"
  },
  {
    "matchId": "26",
    "ParisTime": "10:00",
    "BeijingTime": "27日16:00",
    "eventName": "赛艇",
    "matchDetail": "男子单人双桨分组预赛第6组",
    "venue": "水上体育场"
  },
  {
    "matchId": "27",
    "ParisTime": "10:06",
    "BeijingTime": "27日16:06",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级32强淘汰赛 哈马托夫1:0鲁济耶夫",
    "venue": "战神广场"
  },
  {
    "matchId": "28",
    "ParisTime": "10:06",
    "BeijingTime": "27日16:06",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级32强淘汰赛 李慧卿0:10巴布尔法斯",
    "venue": "战神广场"
  },
  {
    "matchId": "29",
    "ParisTime": "10:10",
    "BeijingTime": "27日16:10",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - A组 苏伟译/谢定峰2:1文迪/莱恩",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "30",
    "ParisTime": "10:10",
    "BeijingTime": "27日16:10",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - A组 贾一凡/陈清晨2:0蒂娜/陈康乐",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "31",
    "ParisTime": "10:10",
    "BeijingTime": "27日16:10",
    "eventName": "羽毛球",
    "matchDetail": "混合双打小组赛 - B组 普瓦拉努克罗/泰拉塔纳柴2:0塔贝林/皮克",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "32",
    "ParisTime": "10:12",
    "BeijingTime": "27日16:12",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级32强淘汰赛 斯梅托夫10:0查卡多阿",
    "venue": "战神广场"
  },
  {
    "matchId": "33",
    "ParisTime": "10:12",
    "BeijingTime": "27日16:12",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级32强淘汰赛 阿利耶娃0:1库尔博诺瓦娃",
    "venue": "战神广场"
  },
  {
    "matchId": "34",
    "ParisTime": "10:12",
    "BeijingTime": "27日16:12",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨分组预赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "35",
    "ParisTime": "10:12",
    "BeijingTime": "27日16:12",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨分组预赛第1组",
    "venue": "水上体育场"
  },
  {
    "matchId": "36",
    "ParisTime": "10:18",
    "BeijingTime": "27日16:18",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级32强淘汰赛 莫雷诺0:1弗斯特拉滕",
    "venue": "战神广场"
  },
  {
    "matchId": "37",
    "ParisTime": "10:18",
    "BeijingTime": "27日16:18",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级32强淘汰赛 角田 夏美11:0费雷拉",
    "venue": "战神广场"
  },
  {
    "matchId": "38",
    "ParisTime": "10:24",
    "BeijingTime": "27日16:24",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级32强淘汰赛 桑乔0:1奥古斯托",
    "venue": "战神广场"
  },
  {
    "matchId": "39",
    "ParisTime": "10:24",
    "BeijingTime": "27日16:24",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级32强淘汰赛 索利斯1:10怀特布",
    "venue": "战神广场"
  },
  {
    "matchId": "40",
    "ParisTime": "10:24",
    "BeijingTime": "27日16:24",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨分组预赛第2组",
    "venue": "水上体育场"
  },
  {
    "matchId": "41",
    "ParisTime": "10:25",
    "BeijingTime": "27日16:25",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑64强 卡昆13:15迪泰拉",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "42",
    "ParisTime": "10:25",
    "BeijingTime": "27日16:25",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑64强 基亚15:13福图希",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "43",
    "ParisTime": "10:30",
    "BeijingTime": "27日16:30",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛B组 比利时2:0爱尔兰",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "44",
    "ParisTime": "10:30",
    "BeijingTime": "27日16:30",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级32强淘汰赛 基索卡0:10沃尔恰克",
    "venue": "战神广场"
  },
  {
    "matchId": "45",
    "ParisTime": "10:30",
    "BeijingTime": "27日16:30",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级32强淘汰赛 布克莉10:0贝德",
    "venue": "战神广场"
  },
  {
    "matchId": "46",
    "ParisTime": "10:30",
    "BeijingTime": "27日16:30",
    "eventName": "射击",
    "matchDetail": "男子10米气手枪资格赛",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "47",
    "ParisTime": "10:30",
    "BeijingTime": "27日16:30",
    "eventName": "射击",
    "matchDetail": "混合团体10米气步枪金牌赛 中国 VS 韩国",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "48",
    "ParisTime": "10:30",
    "BeijingTime": "27日16:30",
    "eventName": "射击",
    "matchDetail": "混合团体10米气步枪铜牌赛 哈萨克斯坦 VS 德国",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "49",
    "ParisTime": "10:36",
    "BeijingTime": "27日16:36",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级32强淘汰赛 马卡伯0:10麦肯齐",
    "venue": "战神广场"
  },
  {
    "matchId": "50",
    "ParisTime": "10:36",
    "BeijingTime": "27日16:36",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级32强淘汰赛 黄氏晶0:1贝迪乌伊",
    "venue": "战神广场"
  },
  {
    "matchId": "51",
    "ParisTime": "10:36",
    "BeijingTime": "27日16:36",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨分组预赛第3组",
    "venue": "水上体育场"
  },
  {
    "matchId": "52",
    "ParisTime": "10:42",
    "BeijingTime": "27日16:42",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级32强淘汰赛 萨米0:10恩赫泰万",
    "venue": "战神广场"
  },
  {
    "matchId": "53",
    "ParisTime": "10:42",
    "BeijingTime": "27日16:42",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级32强淘汰赛 巴乌多尔吉10:0埃波西托",
    "venue": "战神广场"
  },
  {
    "matchId": "54",
    "ParisTime": "10:48",
    "BeijingTime": "27日16:48",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级32强淘汰赛 祖鲁0:10金元珍",
    "venue": "战神广场"
  },
  {
    "matchId": "55",
    "ParisTime": "10:48",
    "BeijingTime": "27日16:48",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级32强淘汰赛 王嘉莉0:10坦泽尔",
    "venue": "战神广场"
  },
  {
    "matchId": "56",
    "ParisTime": "10:48",
    "BeijingTime": "27日16:48",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨分组预赛第4组",
    "venue": "水上体育场"
  },
  {
    "matchId": "57",
    "ParisTime": "10:50",
    "BeijingTime": "27日16:50",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑32强 江旻憓15:11侯赛因",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "58",
    "ParisTime": "10:50",
    "BeijingTime": "27日16:50",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑32强 克拉西克12:11里齐",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "59",
    "ParisTime": "10:50",
    "BeijingTime": "27日16:50",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑32强 吉村 美保14:13孙一文",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "60",
    "ParisTime": "10:50",
    "BeijingTime": "27日16:50",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑32强 斯瓦托夫斯卡-文格拉尔切克11:15宋世拉",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "61",
    "ParisTime": "10:54",
    "BeijingTime": "27日16:54",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级16强淘汰赛 杨勇纬10:1卡尔利诺",
    "venue": "战神广场"
  },
  {
    "matchId": "62",
    "ParisTime": "10:54",
    "BeijingTime": "27日16:54",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级32强淘汰赛 科斯塔1:0门茨",
    "venue": "战神广场"
  },
  {
    "matchId": "63",
    "ParisTime": "11:00",
    "BeijingTime": "27日17:00",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - A组 石宇奇2:0奥蒂",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "64",
    "ParisTime": "11:00",
    "BeijingTime": "27日17:00",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - C组 维蒂萨恩2:0保罗",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "65",
    "ParisTime": "11:00",
    "BeijingTime": "27日17:00",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - A组 松本麻佑/永原和可那2:0拉哈尤 /拉马达尼",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "66",
    "ParisTime": "11:00",
    "BeijingTime": "27日17:00",
    "eventName": "篮球",
    "matchDetail": "男子小组赛 - A组 澳大利亚92:80西班牙",
    "venue": "皮埃尔·莫鲁瓦体育场"
  },
  {
    "matchId": "67",
    "ParisTime": "11:00",
    "BeijingTime": "27日17:00",
    "eventName": "跳水",
    "matchDetail": "女子双人3米跳板跳水",
    "venue": "水上运动中心"
  },
  {
    "matchId": "68",
    "ParisTime": "11:00",
    "BeijingTime": "27日17:00",
    "eventName": "竞技体操",
    "matchDetail": "男子资格赛 - 分组1",
    "venue": "贝尔西体育馆"
  },
  {
    "matchId": "69",
    "ParisTime": "11:00",
    "BeijingTime": "27日17:00",
    "eventName": "手球",
    "matchDetail": "男子预赛B组 匈牙利32:35埃及",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "70",
    "ParisTime": "11:00",
    "BeijingTime": "27日17:00",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级16强淘汰赛 哈马托夫0:1斯梅托夫",
    "venue": "战神广场"
  },
  {
    "matchId": "71",
    "ParisTime": "11:00",
    "BeijingTime": "27日17:00",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级32强淘汰赛 纳瓦埃斯11:0艾玛德",
    "venue": "战神广场"
  },
  {
    "matchId": "72",
    "ParisTime": "11:00",
    "BeijingTime": "27日17:00",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨分组预赛第5组",
    "venue": "水上体育场"
  },
  {
    "matchId": "73",
    "ParisTime": "11:00",
    "BeijingTime": "27日17:00",
    "eventName": "游泳",
    "matchDetail": "女子100米蝶泳分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "74",
    "ParisTime": "11:00",
    "BeijingTime": "27日17:00",
    "eventName": "游泳",
    "matchDetail": "女子100米蝶泳分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "75",
    "ParisTime": "11:02",
    "BeijingTime": "27日17:02",
    "eventName": "游泳",
    "matchDetail": "女子100米蝶泳分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "76",
    "ParisTime": "11:05",
    "BeijingTime": "27日17:05",
    "eventName": "游泳",
    "matchDetail": "女子100米蝶泳分组预赛第3组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "77",
    "ParisTime": "11:06",
    "BeijingTime": "27日17:06",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级16强淘汰赛 加里戈斯1:0弗斯特拉滕",
    "venue": "战神广场"
  },
  {
    "matchId": "78",
    "ParisTime": "11:06",
    "BeijingTime": "27日17:06",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级2强淘汰赛 阿布扎基诺娃1:0里雄尼",
    "venue": "战神广场"
  },
  {
    "matchId": "79",
    "ParisTime": "11:08",
    "BeijingTime": "27日17:08",
    "eventName": "游泳",
    "matchDetail": "女子100米蝶泳分组预赛第4组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "80",
    "ParisTime": "11:12",
    "BeijingTime": "27日17:12",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级16强淘汰赛 永山 龙树10:0奥古斯托",
    "venue": "战神广场"
  },
  {
    "matchId": "81",
    "ParisTime": "11:12",
    "BeijingTime": "27日17:12",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级32强淘汰赛 林真豪10:0拉梭",
    "venue": "战神广场"
  },
  {
    "matchId": "82",
    "ParisTime": "11:12",
    "BeijingTime": "27日17:12",
    "eventName": "赛艇",
    "matchDetail": "女子单人双桨分组预赛第6组",
    "venue": "水上体育场"
  },
  {
    "matchId": "83",
    "ParisTime": "11:12",
    "BeijingTime": "27日17:12",
    "eventName": "游泳",
    "matchDetail": "女子400米自由泳分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "84",
    "ParisTime": "11:12",
    "BeijingTime": "27日17:12",
    "eventName": "游泳",
    "matchDetail": "女子400米自由泳分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "85",
    "ParisTime": "11:15",
    "BeijingTime": "27日17:15",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑32强 布伦纳11:12胡斯西安",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "86",
    "ParisTime": "11:15",
    "BeijingTime": "27日17:15",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑32强 姜荣美13:14迪弗特",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "87",
    "ParisTime": "11:15",
    "BeijingTime": "27日17:15",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑32强 古齐·文森蒂6:15哈科娃",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "88",
    "ParisTime": "11:15",
    "BeijingTime": "27日17:15",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑32强 穆哈里15:10唐君瑶",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "89",
    "ParisTime": "11:18",
    "BeijingTime": "27日17:18",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级16强淘汰赛 萨尔达拉什维利11:0沃尔恰克",
    "venue": "战神广场"
  },
  {
    "matchId": "90",
    "ParisTime": "11:18",
    "BeijingTime": "27日17:18",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级32强淘汰赛 尼科利奇1:0斯坦加尔",
    "venue": "战神广场"
  },
  {
    "matchId": "91",
    "ParisTime": "11:18",
    "BeijingTime": "27日17:18",
    "eventName": "游泳",
    "matchDetail": "女子400米自由泳分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "92",
    "ParisTime": "11:24",
    "BeijingTime": "27日17:24",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级16强淘汰赛 耶尔迪兹1:0麦肯齐",
    "venue": "战神广场"
  },
  {
    "matchId": "93",
    "ParisTime": "11:24",
    "BeijingTime": "27日17:24",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级32强淘汰赛 马丁内斯·阿贝伦达1:0巴尔加斯·莱",
    "venue": "战神广场"
  },
  {
    "matchId": "94",
    "ParisTime": "11:24",
    "BeijingTime": "27日17:24",
    "eventName": "游泳",
    "matchDetail": "女子400米自由泳分组预赛第3组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "95",
    "ParisTime": "11:30",
    "BeijingTime": "27日17:30",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级16强淘汰赛 姆凯德泽10:0恩赫泰万",
    "venue": "战神广场"
  },
  {
    "matchId": "96",
    "ParisTime": "11:30",
    "BeijingTime": "27日17:30",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级16强淘汰赛 斯库托10:0拉波尔德",
    "venue": "战神广场"
  },
  {
    "matchId": "97",
    "ParisTime": "11:30",
    "BeijingTime": "27日17:30",
    "eventName": "赛艇",
    "matchDetail": "男子双人双桨分组预赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "98",
    "ParisTime": "11:30",
    "BeijingTime": "27日17:30",
    "eventName": "赛艇",
    "matchDetail": "男子双人双桨分组预赛第1组",
    "venue": "水上体育场"
  },
  {
    "matchId": "99",
    "ParisTime": "11:30",
    "BeijingTime": "27日17:30",
    "eventName": "游泳",
    "matchDetail": "男子100米蛙泳分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "100",
    "ParisTime": "11:30",
    "BeijingTime": "27日17:30",
    "eventName": "游泳",
    "matchDetail": "男子100米蛙泳分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "101",
    "ParisTime": "11:33",
    "BeijingTime": "27日17:33",
    "eventName": "游泳",
    "matchDetail": "男子100米蛙泳分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "102",
    "ParisTime": "11:36",
    "BeijingTime": "27日17:36",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级16强淘汰赛 阿加耶夫0:10金元珍",
    "venue": "战神广场"
  },
  {
    "matchId": "103",
    "ParisTime": "11:36",
    "BeijingTime": "27日17:36",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级16强淘汰赛 巴布尔法斯10:0库尔博诺瓦娃",
    "venue": "战神广场"
  },
  {
    "matchId": "104",
    "ParisTime": "11:36",
    "BeijingTime": "27日17:36",
    "eventName": "游泳",
    "matchDetail": "男子100米蛙泳分组预赛第3组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "105",
    "ParisTime": "11:39",
    "BeijingTime": "27日17:39",
    "eventName": "游泳",
    "matchDetail": "男子100米蛙泳分组预赛第4组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "106",
    "ParisTime": "11:40",
    "BeijingTime": "27日17:40",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑32强 恩多洛12:13克里维茨卡",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "107",
    "ParisTime": "11:40",
    "BeijingTime": "27日17:40",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑32强 克纳皮克-米亚兹加9:15维塔利斯",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "108",
    "ParisTime": "11:40",
    "BeijingTime": "27日17:40",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑32强 菲亚明戈14:15塞布拉",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "109",
    "ParisTime": "11:40",
    "BeijingTime": "27日17:40",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑32强 余思涵15:13李海因",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "110",
    "ParisTime": "11:40",
    "BeijingTime": "27日17:40",
    "eventName": "赛艇",
    "matchDetail": "男子双人双桨分组预赛第2组",
    "venue": "水上体育场"
  },
  {
    "matchId": "111",
    "ParisTime": "11:41",
    "BeijingTime": "27日17:41",
    "eventName": "游泳",
    "matchDetail": "男子100米蛙泳分组预赛第5组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "112",
    "ParisTime": "11:42",
    "BeijingTime": "27日17:42",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级1/4决赛 杨勇纬0:1斯梅托夫",
    "venue": "战神广场"
  },
  {
    "matchId": "113",
    "ParisTime": "11:42",
    "BeijingTime": "27日17:42",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级16强淘汰赛 角田 夏美11:0怀特布",
    "venue": "战神广场"
  },
  {
    "matchId": "114",
    "ParisTime": "11:45",
    "BeijingTime": "27日17:45",
    "eventName": "游泳",
    "matchDetail": "男子400米自由泳分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "115",
    "ParisTime": "11:45",
    "BeijingTime": "27日17:45",
    "eventName": "游泳",
    "matchDetail": "男子400米自由泳分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "116",
    "ParisTime": "11:48",
    "BeijingTime": "27日17:48",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级1/4决赛 加里戈斯10:0永山 龙树",
    "venue": "战神广场"
  },
  {
    "matchId": "117",
    "ParisTime": "11:48",
    "BeijingTime": "27日17:48",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级16强淘汰赛 布克莉10:0贝迪乌伊",
    "venue": "战神广场"
  },
  {
    "matchId": "118",
    "ParisTime": "11:50",
    "BeijingTime": "27日17:50",
    "eventName": "赛艇",
    "matchDetail": "男子双人双桨分组预赛第3组",
    "venue": "水上体育场"
  },
  {
    "matchId": "119",
    "ParisTime": "11:51",
    "BeijingTime": "27日17:51",
    "eventName": "游泳",
    "matchDetail": "男子400米自由泳分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "120",
    "ParisTime": "11:54",
    "BeijingTime": "27日17:54",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级1/4决赛 萨尔达拉什维利0:1耶尔迪兹",
    "venue": "战神广场"
  },
  {
    "matchId": "121",
    "ParisTime": "11:54",
    "BeijingTime": "27日17:54",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级16强淘汰赛 巴乌多尔吉10:0坦泽尔",
    "venue": "战神广场"
  },
  {
    "matchId": "122",
    "ParisTime": "11:57",
    "BeijingTime": "27日17:57",
    "eventName": "游泳",
    "matchDetail": "男子400米自由泳分组预赛第3组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "123",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级1/4决赛 姆凯德泽1:0金元珍",
    "venue": "战神广场"
  },
  {
    "matchId": "124",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级16强淘汰赛 科斯塔0:10纳瓦埃斯",
    "venue": "战神广场"
  },
  {
    "matchId": "125",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "赛艇",
    "matchDetail": "女子双人双桨分组预赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "126",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "赛艇",
    "matchDetail": "女子双人双桨分组预赛第1组",
    "venue": "水上体育场"
  },
  {
    "matchId": "127",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子双打第1轮 伯莱里/瓦瓦索里1:2卡雷诺·布斯塔/格拉诺勒斯",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "128",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子双打第1轮 克普费尔/施特鲁夫2:1帕维奇/梅克蒂奇",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "129",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子双打第1轮 吉勒/弗利根2:0安贝尔/菲斯",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "130",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子双打第1轮 博帕纳/巴拉吉0:2孟菲尔斯/罗歇-瓦瑟兰",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "131",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 德约科维奇2:0艾伯登",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "132",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 尤班克斯0:2哈桑",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "133",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 埃查尔吉1:2埃文斯",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "134",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 贝格斯1:2齐齐帕斯",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "135",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 马查克2:1张之臻",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "136",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 瓦林卡2:0科托夫",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "137",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 纳沃内2:0博尔赫斯",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "138",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 德雷珀2:0锦织 圭",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "139",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 布勃利克0:2弗里茨",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "140",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 奥热-阿利亚西姆2:0吉隆",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "141",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 哈瑟0:2奥夫纳",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "142",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 土方凛辉0:2梅德韦杰夫",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "143",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 达德尔里0:2保罗",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "144",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 格里克斯普尔2:0齐齐帕斯",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "145",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "男子单打第1轮 哈比卜0:2阿尔卡拉斯",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "146",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子双打第1轮 佩古拉/高夫2:0佩雷斯/萨维尔",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "147",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子双打第1轮 亚历山德罗娃/维斯尼娜1:2穆霍娃/诺斯科娃",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "148",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子双打第1轮 张帅/袁悦0:2哈达德·玛雅/斯特凡尼",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "149",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子双打第1轮 卡尔/波多罗斯卡2:0玛丽亚/科尔帕奇",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "150",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子双打第1轮 基切洛克2:0王欣瑜/郑赛赛",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "151",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子双打第1轮 费尔南德斯/达布罗夫斯基2:0布雷尔/格拉乔娃",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "152",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 斯维亚特克2:0贝古",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "153",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 波多罗斯卡0:2帕里",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "154",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 科恰雷托0:2施奈德",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "155",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 奥斯塔彭科0:2奥索里奥",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "156",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 皮戈西1:2雅斯特雷姆斯卡",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "157",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 西格蒙德0:2柯林斯",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "158",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 加西亚1:2克里斯蒂安",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "159",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 马蒂克0:2布克沙",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "160",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 穆霍娃1:2费尔南德斯",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "161",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 纳瓦罗2:0格拉伯赫尔",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "162",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 盖德茨基0:2鲁斯",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "163",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 埃拉尼0:2郑钦文",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "164",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 哈达德·玛雅2:1格拉乔娃",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "165",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 施米德洛娃2:0博尔特",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "166",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 博格丹0:2保利尼",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "167",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 孙璐璐0:2科斯秋克",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "168",
    "ParisTime": "12:00",
    "BeijingTime": "27日18:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 安德列斯库2:0陶森",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "169",
    "ParisTime": "12:03",
    "BeijingTime": "27日18:03",
    "eventName": "游泳",
    "matchDetail": "男子400米自由泳分组预赛第4组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "170",
    "ParisTime": "12:05",
    "BeijingTime": "27日18:05",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑32强 莫尔劳森11:15肖睿恩",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "171",
    "ParisTime": "12:05",
    "BeijingTime": "27日18:05",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑32强 圣图乔15:10提卡娜·阿卜杜勒·拉赫曼·基里亚",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "172",
    "ParisTime": "12:05",
    "BeijingTime": "27日18:05",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑32强 马洛-布雷顿14:13贝兹拉",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "173",
    "ParisTime": "12:05",
    "BeijingTime": "27日18:05",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑32强 坎达赛米15:10埃哈卜",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "174",
    "ParisTime": "12:06",
    "BeijingTime": "27日18:06",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级16强淘汰赛 阿布扎基诺娃10:0林真豪",
    "venue": "战神广场"
  },
  {
    "matchId": "175",
    "ParisTime": "12:08",
    "BeijingTime": "27日18:08",
    "eventName": "游泳",
    "matchDetail": "男子400米自由泳分组预赛第5组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "176",
    "ParisTime": "12:10",
    "BeijingTime": "27日18:10",
    "eventName": "赛艇",
    "matchDetail": "女子双人双桨分组预赛第2组",
    "venue": "水上体育场"
  },
  {
    "matchId": "177",
    "ParisTime": "12:12",
    "BeijingTime": "27日18:12",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级16强淘汰赛 尼科利奇0:10马丁内斯·阿贝伦达",
    "venue": "战神广场"
  },
  {
    "matchId": "178",
    "ParisTime": "12:15",
    "BeijingTime": "27日18:15",
    "eventName": "游泳",
    "matchDetail": "女子4x100米自由泳接力分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "179",
    "ParisTime": "12:15",
    "BeijingTime": "27日18:15",
    "eventName": "游泳",
    "matchDetail": "女子4x100米自由泳接力分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "180",
    "ParisTime": "12:18",
    "BeijingTime": "27日18:18",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级1/4决赛 斯库托0:10巴布尔法斯",
    "venue": "战神广场"
  },
  {
    "matchId": "181",
    "ParisTime": "12:20",
    "BeijingTime": "27日18:20",
    "eventName": "赛艇",
    "matchDetail": "女子双人双桨分组预赛第3组",
    "venue": "水上体育场"
  },
  {
    "matchId": "182",
    "ParisTime": "12:20",
    "BeijingTime": "27日18:20",
    "eventName": "游泳",
    "matchDetail": "女子4x100米自由泳接力分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "183",
    "ParisTime": "12:24",
    "BeijingTime": "27日18:24",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级1/4决赛 角田 夏美10:0布克莉",
    "venue": "战神广场"
  },
  {
    "matchId": "184",
    "ParisTime": "12:26",
    "BeijingTime": "27日18:26",
    "eventName": "游泳",
    "matchDetail": "男子4x100米自由泳接力分组预赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "185",
    "ParisTime": "12:26",
    "BeijingTime": "27日18:26",
    "eventName": "游泳",
    "matchDetail": "男子4x100米自由泳接力分组预赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "186",
    "ParisTime": "12:30",
    "BeijingTime": "27日18:30",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑32强 迪泰拉11:15埃尔西西",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "187",
    "ParisTime": "12:30",
    "BeijingTime": "27日18:30",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑32强 朴相元15:10赫特考克",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "188",
    "ParisTime": "12:30",
    "BeijingTime": "27日18:30",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑32强 吉罗8:15吴尚旭",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "189",
    "ParisTime": "12:30",
    "BeijingTime": "27日18:30",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑32强 萨梅莱15:10戈登",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "190",
    "ParisTime": "12:30",
    "BeijingTime": "27日18:30",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级1/4决赛 巴乌多尔吉10:0纳瓦埃斯",
    "venue": "战神广场"
  },
  {
    "matchId": "191",
    "ParisTime": "12:30",
    "BeijingTime": "27日18:30",
    "eventName": "赛艇",
    "matchDetail": "男子四人双桨分组预赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "192",
    "ParisTime": "12:30",
    "BeijingTime": "27日18:30",
    "eventName": "赛艇",
    "matchDetail": "男子四人双桨分组预赛第1组",
    "venue": "水上体育场"
  },
  {
    "matchId": "193",
    "ParisTime": "12:30",
    "BeijingTime": "27日18:30",
    "eventName": "射击",
    "matchDetail": "女子10米气手枪资格赛",
    "venue": "沙托鲁射击中心"
  },
  {
    "matchId": "194",
    "ParisTime": "12:31",
    "BeijingTime": "27日18:31",
    "eventName": "游泳",
    "matchDetail": "男子4x100米自由泳接力分组预赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "195",
    "ParisTime": "12:36",
    "BeijingTime": "27日18:36",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级1/4决赛 阿布扎基诺娃0:10马丁内斯·阿贝伦达",
    "venue": "战神广场"
  },
  {
    "matchId": "196",
    "ParisTime": "12:40",
    "BeijingTime": "27日18:40",
    "eventName": "赛艇",
    "matchDetail": "男子四人双桨分组预赛第2组",
    "venue": "水上体育场"
  },
  {
    "matchId": "197",
    "ParisTime": "12:45",
    "BeijingTime": "27日18:45",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛A组 荷兰5:3南非",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "198",
    "ParisTime": "12:50",
    "BeijingTime": "27日18:50",
    "eventName": "赛艇",
    "matchDetail": "女子四人双桨分组预赛",
    "venue": "水上体育场"
  },
  {
    "matchId": "199",
    "ParisTime": "12:50",
    "BeijingTime": "27日18:50",
    "eventName": "赛艇",
    "matchDetail": "女子四人双桨分组预赛第1组",
    "venue": "水上体育场"
  },
  {
    "matchId": "200",
    "ParisTime": "12:55",
    "BeijingTime": "27日18:55",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑32强 皮昂费蒂12:15萨隆",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "201",
    "ParisTime": "12:55",
    "BeijingTime": "27日18:55",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑32强 加洛6:15沈晨鹏",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "202",
    "ParisTime": "12:55",
    "BeijingTime": "27日18:55",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑32强 帕克达曼 阿里15:11吉田 健人",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "203",
    "ParisTime": "12:55",
    "BeijingTime": "27日18:55",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑32强 库拉托利15:10耶尔德雷姆",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "204",
    "ParisTime": "13:00",
    "BeijingTime": "27日19:00",
    "eventName": "赛艇",
    "matchDetail": "女子四人双桨分组预赛第2组",
    "venue": "水上体育场"
  },
  {
    "matchId": "205",
    "ParisTime": "13:00",
    "BeijingTime": "27日19:00",
    "eventName": "排球",
    "matchDetail": "男子预赛 - B组 意大利3:1巴西",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "206",
    "ParisTime": "13:15",
    "BeijingTime": "27日19:15",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛B组 澳大利亚1:0阿根廷",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "207",
    "ParisTime": "13:20",
    "BeijingTime": "27日19:20",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑32强 阿尔沙姆兰6:15萨博",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "208",
    "ParisTime": "13:20",
    "BeijingTime": "27日19:20",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑32强 具本佶8:15费尔贾尼",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "209",
    "ParisTime": "13:20",
    "BeijingTime": "27日19:20",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑32强 萨特马里13:15阿皮蒂",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "210",
    "ParisTime": "13:20",
    "BeijingTime": "27日19:20",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑32强 阿米尔15:8莫塔兹",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "211",
    "ParisTime": "13:30",
    "BeijingTime": "27日19:30",
    "eventName": "篮球",
    "matchDetail": "男子小组赛 - B组 德国97:77日本",
    "venue": "皮埃尔·莫鲁瓦体育场"
  },
  {
    "matchId": "212",
    "ParisTime": "13:45",
    "BeijingTime": "27日19:45",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑32强 帕特里斯15:13拉赫巴里·科亚基",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "213",
    "ParisTime": "13:45",
    "BeijingTime": "27日19:45",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑32强 德什维兹10:15盖梅西",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "214",
    "ParisTime": "13:45",
    "BeijingTime": "27日19:45",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑32强 西拉吉8:15阿尔法",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "215",
    "ParisTime": "13:45",
    "BeijingTime": "27日19:45",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑32强 巴扎迪15:6基亚",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "216",
    "ParisTime": "14:00",
    "BeijingTime": "27日20:00",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - B组 杨雅婷/杨霈霖1:2斯托伊娃",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "217",
    "ParisTime": "14:00",
    "BeijingTime": "27日20:00",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - B组 刘圣书/谭宁2:0许忻霏/许怡霏",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "218",
    "ParisTime": "14:00",
    "BeijingTime": "27日20:00",
    "eventName": "羽毛球",
    "matchDetail": "混合双打小组赛 - C组 谢影雪/邓俊文2:0叶宏蔚/李佳馨",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "219",
    "ParisTime": "14:00",
    "BeijingTime": "27日20:00",
    "eventName": "手球",
    "matchDetail": "男子预赛A组 克罗地亚30:29日本",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "220",
    "ParisTime": "14:00",
    "BeijingTime": "27日20:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - D组 美国0:2古巴",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "221",
    "ParisTime": "14:00",
    "BeijingTime": "27日20:00",
    "eventName": "水球",
    "matchDetail": "女子预赛A组 荷兰10:8匈牙利",
    "venue": "水上运动中心"
  },
  {
    "matchId": "222",
    "ParisTime": "14:10",
    "BeijingTime": "27日20:10",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑16强 江旻憓15:12胡斯西安",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "223",
    "ParisTime": "14:10",
    "BeijingTime": "27日20:10",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑16强 克拉西克10:11迪弗特",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "224",
    "ParisTime": "14:10",
    "BeijingTime": "27日20:10",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑16强 吉村 美保10:15哈科娃",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "225",
    "ParisTime": "14:10",
    "BeijingTime": "27日20:10",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑16强 穆哈里15:6宋世拉",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "226",
    "ParisTime": "14:30",
    "BeijingTime": "27日20:30",
    "eventName": "公路自行车",
    "matchDetail": "女子个人计时赛",
    "venue": "亚历山大三世桥"
  },
  {
    "matchId": "227",
    "ParisTime": "14:30",
    "BeijingTime": "27日20:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子5-8名排名赛 新西兰17:12阿根廷",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "228",
    "ParisTime": "14:35",
    "BeijingTime": "27日20:35",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑16强 肖睿恩14:15克里维茨卡",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "229",
    "ParisTime": "14:35",
    "BeijingTime": "27日20:35",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑16强 圣图乔15:12维塔利斯",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "230",
    "ParisTime": "14:35",
    "BeijingTime": "27日20:35",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑16强 马洛-布雷顿15:13塞布拉",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "231",
    "ParisTime": "14:35",
    "BeijingTime": "27日20:35",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑16强 坎达赛米10:15余思涵",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "232",
    "ParisTime": "14:50",
    "BeijingTime": "27日20:50",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - C组 阿尔菲安/阿迪安托2:0塞德尔/拉姆斯富斯",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "233",
    "ParisTime": "14:50",
    "BeijingTime": "27日20:50",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - D组 卡特通2:0维亚拿·维埃拉",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "234",
    "ParisTime": "14:50",
    "BeijingTime": "27日20:50",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - H组 金佳恩2:0肖尔茨",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "235",
    "ParisTime": "15:00",
    "BeijingTime": "27日21:00",
    "eventName": "皮划艇激流回旋",
    "matchDetail": "男子单人划艇预赛第1轮",
    "venue": "水上体育场"
  },
  {
    "matchId": "236",
    "ParisTime": "15:00",
    "BeijingTime": "27日21:00",
    "eventName": "皮划艇激流回旋",
    "matchDetail": "男子单人划艇预赛 - 第1轮和第2轮",
    "venue": "水上体育场"
  },
  {
    "matchId": "237",
    "ParisTime": "15:00",
    "BeijingTime": "27日21:00",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑16强 萨隆13:15埃尔西西",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "238",
    "ParisTime": "15:00",
    "BeijingTime": "27日21:00",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑16强 沈晨鹏15:11朴相元",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "239",
    "ParisTime": "15:00",
    "BeijingTime": "27日21:00",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑16强 帕克达曼 阿里10:15吴尚旭",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "240",
    "ParisTime": "15:00",
    "BeijingTime": "27日21:00",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑16强 库拉托利12:15萨梅莱",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "241",
    "ParisTime": "15:00",
    "BeijingTime": "27日21:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子5-8名排名赛 爱尔兰17:14美国",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "242",
    "ParisTime": "15:00",
    "BeijingTime": "27日21:00",
    "eventName": "乒乓球",
    "matchDetail": "男子单打预赛 杰哈4:0乌尔苏",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "243",
    "ParisTime": "15:00",
    "BeijingTime": "27日21:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打预赛 汉夫4:1埃德希尔",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "244",
    "ParisTime": "15:00",
    "BeijingTime": "27日21:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打预赛 韦格日因4:0阿里",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "245",
    "ParisTime": "15:00",
    "BeijingTime": "27日21:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - A组 瑞典2:0澳大利亚",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "246",
    "ParisTime": "15:00",
    "BeijingTime": "27日21:00",
    "eventName": "足球",
    "matchDetail": "男子B组 阿根廷3:1伊拉克",
    "venue": "里昂体育场"
  },
  {
    "matchId": "247",
    "ParisTime": "15:00",
    "BeijingTime": "27日21:00",
    "eventName": "足球",
    "matchDetail": "男子C组 多米尼加1:3西班牙",
    "venue": "波尔多体育场"
  },
  {
    "matchId": "248",
    "ParisTime": "15:25",
    "BeijingTime": "27日21:25",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑16强 帕特里斯13:15萨博",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "249",
    "ParisTime": "15:25",
    "BeijingTime": "27日21:25",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑16强 盖梅西14:15费尔贾尼",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "250",
    "ParisTime": "15:25",
    "BeijingTime": "27日21:25",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑16强 阿尔法15:8阿皮蒂",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "251",
    "ParisTime": "15:25",
    "BeijingTime": "27日21:25",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑16强 巴扎迪14:15阿米尔",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "252",
    "ParisTime": "15:30",
    "BeijingTime": "27日21:30",
    "eventName": "拳击",
    "matchDetail": "女子54公斤级 - 预赛 - 32强赛 黄筱雯5:0戈伊科维奇",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "253",
    "ParisTime": "15:30",
    "BeijingTime": "27日21:30",
    "eventName": "竞技体操",
    "matchDetail": "男子资格赛 - 分组2",
    "venue": "贝尔西体育馆"
  },
  {
    "matchId": "254",
    "ParisTime": "15:30",
    "BeijingTime": "27日21:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子半决赛 南非5:19法国",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "255",
    "ParisTime": "15:35",
    "BeijingTime": "27日21:35",
    "eventName": "水球",
    "matchDetail": "女子预赛 - B组 希腊6:15美国",
    "venue": "水上运动中心"
  },
  {
    "matchId": "256",
    "ParisTime": "15:40",
    "BeijingTime": "27日21:40",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - L组 森2:0科尔顿·布埃佐",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "257",
    "ParisTime": "15:40",
    "BeijingTime": "27日21:40",
    "eventName": "羽毛球",
    "matchDetail": "混合双打小组赛 - A组 郑思维/黄雅琼2:0吉凯尔 /德尔吕",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "258",
    "ParisTime": "15:40",
    "BeijingTime": "27日21:40",
    "eventName": "羽毛球",
    "matchDetail": "混合双打小组赛 - A组 郑娜银/金元浩1:2里瓦尔迪 /门塔里",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "259",
    "ParisTime": "15:45",
    "BeijingTime": "27日21:45",
    "eventName": "乒乓球",
    "matchDetail": "男子单打预赛 德赛·哈米特4:0阿布·亚曼",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "260",
    "ParisTime": "15:45",
    "BeijingTime": "27日21:45",
    "eventName": "乒乓球",
    "matchDetail": "男子单打预赛 迪奥4:0什雷斯塔",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "261",
    "ParisTime": "15:45",
    "BeijingTime": "27日21:45",
    "eventName": "乒乓球",
    "matchDetail": "女子单打预赛 曾志颖1:4萨哈基安",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "262",
    "ParisTime": "15:46",
    "BeijingTime": "27日21:46",
    "eventName": "拳击",
    "matchDetail": "女子54公斤级 - 预赛 - 32强赛 吉蓬4:1西尔科维奇",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "263",
    "ParisTime": "15:50",
    "BeijingTime": "27日21:50",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑8强 江旻憓15:7克里维茨卡",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "264",
    "ParisTime": "15:50",
    "BeijingTime": "27日21:50",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑8强 圣图乔9:10迪弗特",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "265",
    "ParisTime": "15:50",
    "BeijingTime": "27日21:50",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑8强 马洛-布雷顿15:10哈科娃",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "266",
    "ParisTime": "15:50",
    "BeijingTime": "27日21:50",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑8强 穆哈里15:10余思涵",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "267",
    "ParisTime": "16:00",
    "BeijingTime": "27日22:00",
    "eventName": "皮划艇激流回旋",
    "matchDetail": "女子单人皮艇预赛第1轮",
    "venue": "水上体育场"
  },
  {
    "matchId": "268",
    "ParisTime": "16:00",
    "BeijingTime": "27日22:00",
    "eventName": "皮划艇激流回旋",
    "matchDetail": "女子单人皮艇预赛 - 第1轮和第2轮",
    "venue": "水上体育场"
  },
  {
    "matchId": "269",
    "ParisTime": "16:00",
    "BeijingTime": "27日22:00",
    "eventName": "手球",
    "matchDetail": "男子预赛B组 挪威36:31阿根廷",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "270",
    "ParisTime": "16:00",
    "BeijingTime": "27日22:00",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级复活赛 斯库托0:1布克莉",
    "venue": "战神广场"
  },
  {
    "matchId": "271",
    "ParisTime": "16:00",
    "BeijingTime": "27日22:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子半决赛 斐济31:7澳大利亚",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "272",
    "ParisTime": "16:02",
    "BeijingTime": "27日22:02",
    "eventName": "拳击",
    "matchDetail": "女子54公斤级 - 预赛 - 32强赛 乌克塔莫娃 VS 阿亚德",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "273",
    "ParisTime": "16:06",
    "BeijingTime": "27日22:06",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级复活赛 纳瓦埃斯0:10阿布扎基诺娃",
    "venue": "战神广场"
  },
  {
    "matchId": "274",
    "ParisTime": "16:12",
    "BeijingTime": "27日22:12",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级B表半决赛 巴乌多尔吉10:0马丁内斯·阿贝伦达",
    "venue": "战神广场"
  },
  {
    "matchId": "275",
    "ParisTime": "16:15",
    "BeijingTime": "27日22:15",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑8强 萨博14:15埃尔西西",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "276",
    "ParisTime": "16:15",
    "BeijingTime": "27日22:15",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑8强 费尔贾尼15:14沈晨鹏",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "277",
    "ParisTime": "16:15",
    "BeijingTime": "27日22:15",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑8强 阿尔法13:15吴尚旭",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "278",
    "ParisTime": "16:15",
    "BeijingTime": "27日22:15",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑8强 萨梅莱15:13阿米尔",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "279",
    "ParisTime": "16:18",
    "BeijingTime": "27日22:18",
    "eventName": "拳击",
    "matchDetail": "女子60公斤级 - 预赛 - 32强赛 埃佩尼萨0:5哈蒂玲",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "280",
    "ParisTime": "16:18",
    "BeijingTime": "27日22:18",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级A表半决赛 巴布尔法斯0:10角田 夏美",
    "venue": "战神广场"
  },
  {
    "matchId": "281",
    "ParisTime": "16:24",
    "BeijingTime": "27日22:24",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级复活赛 杨勇纬0:1永山 龙树",
    "venue": "战神广场"
  },
  {
    "matchId": "282",
    "ParisTime": "16:30",
    "BeijingTime": "27日22:30",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - C组 兰基雷迪/谢蒂2:0劳役/拉巴尔",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "283",
    "ParisTime": "16:30",
    "BeijingTime": "27日22:30",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - L组 克里斯蒂2:1卡拉吉",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "284",
    "ParisTime": "16:30",
    "BeijingTime": "27日22:30",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级复活赛 萨尔达拉什维利10:0金元珍",
    "venue": "战神广场"
  },
  {
    "matchId": "285",
    "ParisTime": "16:30",
    "BeijingTime": "27日22:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子11-12名排名赛 日本10:21乌拉圭",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "286",
    "ParisTime": "16:30",
    "BeijingTime": "27日22:30",
    "eventName": "乒乓球",
    "matchDetail": "混合双打16强赛 孙颖莎/王楚钦4:0梅什雷夫/阿萨尔",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "287",
    "ParisTime": "16:30",
    "BeijingTime": "27日22:30",
    "eventName": "乒乓球",
    "matchDetail": "混合双打16强赛 斯佐科斯/约内斯库4:1卢姆/杰",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "288",
    "ParisTime": "16:30",
    "BeijingTime": "27日22:30",
    "eventName": "乒乓球",
    "matchDetail": "混合双打16强赛 米特尔汉/邱党0:4林钟勋/申裕斌",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "289",
    "ParisTime": "16:30",
    "BeijingTime": "27日22:30",
    "eventName": "乒乓球",
    "matchDetail": "混合双打16强赛 李郑息/金琴英4:1张本智和/早田希娜",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "290",
    "ParisTime": "16:32",
    "BeijingTime": "27日22:32",
    "eventName": "公路自行车",
    "matchDetail": "男子个人计时赛",
    "venue": "亚历山大三世桥"
  },
  {
    "matchId": "291",
    "ParisTime": "16:34",
    "BeijingTime": "27日22:34",
    "eventName": "拳击",
    "matchDetail": "女子60公斤级 - 预赛 - 32强赛 帕拉西奥斯·埃斯皮诺萨4:1阿莱克西松",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "292",
    "ParisTime": "16:36",
    "BeijingTime": "27日22:36",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级B表半决赛 耶尔迪兹0:1姆凯德泽",
    "venue": "战神广场"
  },
  {
    "matchId": "293",
    "ParisTime": "16:42",
    "BeijingTime": "27日22:42",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级A表半决赛 斯梅托夫10:0加里戈斯",
    "venue": "战神广场"
  },
  {
    "matchId": "294",
    "ParisTime": "16:48",
    "BeijingTime": "27日22:48",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级铜牌争夺赛A 布克莉1:0马丁内斯·阿贝伦达",
    "venue": "战神广场"
  },
  {
    "matchId": "295",
    "ParisTime": "16:50",
    "BeijingTime": "27日22:50",
    "eventName": "拳击",
    "matchDetail": "女子60公斤级 - 预赛 - 32强赛 吴诗仪5:0吴莲枝",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "296",
    "ParisTime": "16:54",
    "BeijingTime": "27日22:54",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级铜牌争夺赛B 阿布扎基诺娃0:10巴布尔法斯",
    "venue": "战神广场"
  },
  {
    "matchId": "297",
    "ParisTime": "17:00",
    "BeijingTime": "27日23:00",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛A组 德国8:2法国",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "298",
    "ParisTime": "17:00",
    "BeijingTime": "27日23:00",
    "eventName": "柔道",
    "matchDetail": "女子48公斤级决赛 角田 夏美1:0巴乌多尔吉",
    "venue": "战神广场"
  },
  {
    "matchId": "299",
    "ParisTime": "17:00",
    "BeijingTime": "27日23:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子9-10名排名赛 萨摩亚5:10肯尼亚",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "300",
    "ParisTime": "17:00",
    "BeijingTime": "27日23:00",
    "eventName": "排球",
    "matchDetail": "男子预赛 - B组 波兰3:0埃及",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "301",
    "ParisTime": "17:00",
    "BeijingTime": "27日23:00",
    "eventName": "足球",
    "matchDetail": "男子B组 乌克兰2:1摩洛哥",
    "venue": "热奥弗鲁瓦基查尔体育场"
  },
  {
    "matchId": "302",
    "ParisTime": "17:00",
    "BeijingTime": "27日23:00",
    "eventName": "足球",
    "matchDetail": "男子C组 乌兹别克斯坦0:1埃及",
    "venue": "博茹瓦尔体育场"
  },
  {
    "matchId": "303",
    "ParisTime": "17:06",
    "BeijingTime": "27日23:06",
    "eventName": "拳击",
    "matchDetail": "男子63.5公斤级 - 预赛 - 32强赛 乌斯莫诺夫0:5罗斯洛夫",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "304",
    "ParisTime": "17:06",
    "BeijingTime": "27日23:06",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级铜牌争夺赛A 永山 龙树10:0耶尔迪兹",
    "venue": "战神广场"
  },
  {
    "matchId": "305",
    "ParisTime": "17:10",
    "BeijingTime": "27日23:10",
    "eventName": "皮划艇激流回旋",
    "matchDetail": "男子单人划艇预赛第2轮",
    "venue": "水上体育场"
  },
  {
    "matchId": "306",
    "ParisTime": "17:12",
    "BeijingTime": "27日23:12",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级铜牌争夺赛B 萨尔达拉什维利0:1加里戈斯",
    "venue": "战神广场"
  },
  {
    "matchId": "307",
    "ParisTime": "17:15",
    "BeijingTime": "27日23:15",
    "eventName": "篮球",
    "matchDetail": "男子小组赛 - B组 法国78:66巴西",
    "venue": "皮埃尔·莫鲁瓦体育场"
  },
  {
    "matchId": "308",
    "ParisTime": "17:15",
    "BeijingTime": "27日23:15",
    "eventName": "乒乓球",
    "matchDetail": "混合双打16强赛 勒布伦/袁嘉楠2:4林昀儒/陈思羽",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "309",
    "ParisTime": "17:15",
    "BeijingTime": "27日23:15",
    "eventName": "乒乓球",
    "matchDetail": "混合双打16强赛 杜凯琹/黄镇廷4:1埃塞基/马达拉斯",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "310",
    "ParisTime": "17:15",
    "BeijingTime": "27日23:15",
    "eventName": "乒乓球",
    "matchDetail": "混合双打16强赛 塔卡哈什/艾什2:4玛丽亚·肖/罗布莱斯",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "311",
    "ParisTime": "17:15",
    "BeijingTime": "27日23:15",
    "eventName": "乒乓球",
    "matchDetail": "混合双打16强赛 卡尔伯格/卡尔松4:1丰塞卡·卡拉扎纳/坎波斯",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "312",
    "ParisTime": "17:18",
    "BeijingTime": "27日23:18",
    "eventName": "柔道",
    "matchDetail": "男子60公斤级决赛 斯梅托夫1:0姆凯德泽",
    "venue": "战神广场"
  },
  {
    "matchId": "313",
    "ParisTime": "17:22",
    "BeijingTime": "27日23:22",
    "eventName": "拳击",
    "matchDetail": "男子63.5公斤级 - 预赛 - 32强赛 阿尔卡斯贝3:2克兰西",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "314",
    "ParisTime": "17:30",
    "BeijingTime": "27日23:30",
    "eventName": "曲棍球",
    "matchDetail": "男子分组循环赛B组 印度3:2新西兰",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "315",
    "ParisTime": "17:38",
    "BeijingTime": "27日23:38",
    "eventName": "拳击",
    "matchDetail": "男子80公斤级 - 预赛 - 32强赛 琼乔霍0:5皮纳莱斯",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "316",
    "ParisTime": "18:00",
    "BeijingTime": "28日00:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子7-8名排名赛 阿根廷19:0美国",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "317",
    "ParisTime": "18:00",
    "BeijingTime": "28日00:00",
    "eventName": "沙滩排球",
    "matchDetail": "女子预赛 - B组 中国1:2澳大利亚",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "318",
    "ParisTime": "18:10",
    "BeijingTime": "28日00:10",
    "eventName": "皮划艇激流回旋",
    "matchDetail": "女子单人皮艇预赛第2轮",
    "venue": "水上体育场"
  },
  {
    "matchId": "319",
    "ParisTime": "18:30",
    "BeijingTime": "28日00:30",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子5-6名排名赛 新西兰17:7爱尔兰",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "320",
    "ParisTime": "18:30",
    "BeijingTime": "28日00:30",
    "eventName": "水球",
    "matchDetail": "女子预赛 - B组 西班牙15:6法国",
    "venue": "水上运动中心"
  },
  {
    "matchId": "321",
    "ParisTime": "19:00",
    "BeijingTime": "28日01:00",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑半决赛1 江旻憓15:11迪弗特",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "322",
    "ParisTime": "19:00",
    "BeijingTime": "28日01:00",
    "eventName": "手球",
    "matchDetail": "男子预赛A组 德国30:27瑞典",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "323",
    "ParisTime": "19:00",
    "BeijingTime": "28日01:00",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子铜牌赛 南非26:19澳大利亚",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "324",
    "ParisTime": "19:00",
    "BeijingTime": "28日01:00",
    "eventName": "冲浪",
    "matchDetail": "男子第1轮 - 第1组",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "325",
    "ParisTime": "19:00",
    "BeijingTime": "28日01:00",
    "eventName": "网球",
    "matchDetail": "男子双打第1轮 冈萨雷斯/莫尔泰尼0:2纳达尔/阿尔卡拉斯",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "326",
    "ParisTime": "19:00",
    "BeijingTime": "28日01:00",
    "eventName": "网球",
    "matchDetail": "女子单打第1轮 大阪 直美0:2科贝尔",
    "venue": "罗兰·加洛斯体育场"
  },
  {
    "matchId": "327",
    "ParisTime": "19:00",
    "BeijingTime": "28日01:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - D组 巴西2:0摩洛哥",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "328",
    "ParisTime": "19:00",
    "BeijingTime": "28日01:00",
    "eventName": "足球",
    "matchDetail": "男子A组 新西兰1:4美国",
    "venue": "马赛体育场"
  },
  {
    "matchId": "329",
    "ParisTime": "19:00",
    "BeijingTime": "28日01:00",
    "eventName": "足球",
    "matchDetail": "男子D组 以色列2:4巴拉圭",
    "venue": "王子公园"
  },
  {
    "matchId": "330",
    "ParisTime": "19:25",
    "BeijingTime": "28日01:25",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑半决赛2 穆哈里9:15马洛-布雷顿",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "331",
    "ParisTime": "19:30",
    "BeijingTime": "28日01:30",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - B组 乔姆科/凯德伦2:0波波夫",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "332",
    "ParisTime": "19:30",
    "BeijingTime": "28日01:30",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - C组 山口 茜2:0德·塔",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "333",
    "ParisTime": "19:30",
    "BeijingTime": "28日01:30",
    "eventName": "羽毛球",
    "matchDetail": "女子单打小组赛 - K组 张蓓雯2:0何羡儒",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "334",
    "ParisTime": "19:36",
    "BeijingTime": "28日01:36",
    "eventName": "冲浪",
    "matchDetail": "男子第1轮 - 第2组",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "335",
    "ParisTime": "19:45",
    "BeijingTime": "28日01:45",
    "eventName": "曲棍球",
    "matchDetail": "女子分组循环赛B组 阿根廷4:1美国",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "336",
    "ParisTime": "19:45",
    "BeijingTime": "28日01:45",
    "eventName": "七人制橄榄球",
    "matchDetail": "男子金牌赛 法国28:7斐济",
    "venue": "法兰西体育场"
  },
  {
    "matchId": "337",
    "ParisTime": "19:50",
    "BeijingTime": "28日01:50",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑半决赛1 费尔贾尼15:11埃尔西西",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "338",
    "ParisTime": "20:00",
    "BeijingTime": "28日02:00",
    "eventName": "拳击",
    "matchDetail": "女子54公斤级 - 预赛 - 32强赛 戴维森2:3阿克巴斯",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "339",
    "ParisTime": "20:00",
    "BeijingTime": "28日02:00",
    "eventName": "竞技体操",
    "matchDetail": "男子资格赛 - 分组3",
    "venue": "贝尔西体育馆"
  },
  {
    "matchId": "340",
    "ParisTime": "20:00",
    "BeijingTime": "28日02:00",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 纽廷克0:4莫雷加德",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "341",
    "ParisTime": "20:00",
    "BeijingTime": "28日02:00",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 奥莫塔约1:4阿拉米扬",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "342",
    "ParisTime": "20:00",
    "BeijingTime": "28日02:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 萨马拉4:3布拉特伊科",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "343",
    "ParisTime": "20:00",
    "BeijingTime": "28日02:00",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 汤米0:4王艾米",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "344",
    "ParisTime": "20:05",
    "BeijingTime": "28日02:05",
    "eventName": "水球",
    "matchDetail": "女子预赛A组 澳大利亚7:5中国",
    "venue": "水上运动中心"
  },
  {
    "matchId": "345",
    "ParisTime": "20:12",
    "BeijingTime": "28日02:12",
    "eventName": "冲浪",
    "matchDetail": "男子第1轮 - 第3组",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "346",
    "ParisTime": "20:15",
    "BeijingTime": "28日02:15",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑半决赛2 萨梅莱5:15吴尚旭",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "347",
    "ParisTime": "20:15",
    "BeijingTime": "28日02:15",
    "eventName": "曲棍球",
    "matchDetail": "女子分组循环赛A组 荷兰6:2法国",
    "venue": "伊芙庄园体育场"
  },
  {
    "matchId": "348",
    "ParisTime": "20:16",
    "BeijingTime": "28日02:16",
    "eventName": "拳击",
    "matchDetail": "女子54公斤级 - 预赛 - 32强赛 蒙冈特塞格5:0沙拉比",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "349",
    "ParisTime": "20:20",
    "BeijingTime": "28日02:20",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - C组 志田千阳/松山奈未2:0于亚杰/玛帕萨",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "350",
    "ParisTime": "20:20",
    "BeijingTime": "28日02:20",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - C组 孔熙容/金昭映2:0克拉斯托/蓬纳帕",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "351",
    "ParisTime": "20:20",
    "BeijingTime": "28日02:20",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - D组 李绍希/白荷娜1:2弗勒尔戈德/蒂格森",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "352",
    "ParisTime": "20:30",
    "BeijingTime": "28日02:30",
    "eventName": "游泳",
    "matchDetail": "女子100米蝶泳半决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "353",
    "ParisTime": "20:30",
    "BeijingTime": "28日02:30",
    "eventName": "游泳",
    "matchDetail": "女子100米蝶泳半决赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "354",
    "ParisTime": "20:32",
    "BeijingTime": "28日02:32",
    "eventName": "拳击",
    "matchDetail": "女子54公斤级 - 预赛 - 32强赛 普里蒂5:0武氏金英",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "355",
    "ParisTime": "20:36",
    "BeijingTime": "28日02:36",
    "eventName": "游泳",
    "matchDetail": "女子100米蝶泳半决赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "356",
    "ParisTime": "20:40",
    "BeijingTime": "28日02:40",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑铜牌赛 穆哈里15:14迪弗特",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "357",
    "ParisTime": "20:42",
    "BeijingTime": "28日02:42",
    "eventName": "游泳",
    "matchDetail": "男子400米自由泳决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "358",
    "ParisTime": "20:45",
    "BeijingTime": "28日02:45",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 姆拉德诺维奇0:4格罗斯",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "359",
    "ParisTime": "20:45",
    "BeijingTime": "28日02:45",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 布卢萨0:4普卡尔",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "360",
    "ParisTime": "20:45",
    "BeijingTime": "28日02:45",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 迪亚兹4:0卢普莱斯库",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "361",
    "ParisTime": "20:45",
    "BeijingTime": "28日02:45",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 埃尔兰4:0戈达",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "362",
    "ParisTime": "20:48",
    "BeijingTime": "28日02:48",
    "eventName": "拳击",
    "matchDetail": "女子60公斤级 - 预赛 - 32强赛 梅西亚诺4:1奥泽尔",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "363",
    "ParisTime": "20:48",
    "BeijingTime": "28日02:48",
    "eventName": "冲浪",
    "matchDetail": "男子第1轮 - 第4组",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "364",
    "ParisTime": "20:52",
    "BeijingTime": "28日02:52",
    "eventName": "游泳",
    "matchDetail": "女子400米自由泳决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "365",
    "ParisTime": "21:00",
    "BeijingTime": "28日03:00",
    "eventName": "篮球",
    "matchDetail": "男子小组赛 - A组 希腊79:86加拿大",
    "venue": "皮埃尔·莫鲁瓦体育场"
  },
  {
    "matchId": "366",
    "ParisTime": "21:00",
    "BeijingTime": "28日03:00",
    "eventName": "手球",
    "matchDetail": "男子预赛B组 丹麦37:29法国",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "367",
    "ParisTime": "21:00",
    "BeijingTime": "28日03:00",
    "eventName": "排球",
    "matchDetail": "男子预赛 - C组 美国3:0阿根廷",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "368",
    "ParisTime": "21:00",
    "BeijingTime": "28日03:00",
    "eventName": "足球",
    "matchDetail": "男子A组 法国1:0几内亚",
    "venue": "尼斯体育场"
  },
  {
    "matchId": "369",
    "ParisTime": "21:00",
    "BeijingTime": "28日03:00",
    "eventName": "足球",
    "matchDetail": "男子D组 日本1:0马里",
    "venue": "波尔多体育场"
  },
  {
    "matchId": "370",
    "ParisTime": "21:04",
    "BeijingTime": "28日03:04",
    "eventName": "拳击",
    "matchDetail": "女子60公斤级 - 预赛 - 32强赛 松纽克2:3萨迪库",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "371",
    "ParisTime": "21:05",
    "BeijingTime": "28日03:05",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑铜牌赛 萨梅莱15:12埃尔西西",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "372",
    "ParisTime": "21:10",
    "BeijingTime": "28日03:10",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - D组 保木卓朗/小林优吾0:2李洋/王齐麟",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "373",
    "ParisTime": "21:10",
    "BeijingTime": "28日03:10",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - P组 安赛龙2:0达哈尔",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "374",
    "ParisTime": "21:10",
    "BeijingTime": "28日03:10",
    "eventName": "羽毛球",
    "matchDetail": "男子单打小组赛 - P组 阮日2:1齐伯曼",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "375",
    "ParisTime": "21:12",
    "BeijingTime": "28日03:12",
    "eventName": "游泳",
    "matchDetail": "男子100米蛙泳半决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "376",
    "ParisTime": "21:12",
    "BeijingTime": "28日03:12",
    "eventName": "游泳",
    "matchDetail": "男子100米蛙泳半决赛第1组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "377",
    "ParisTime": "21:18",
    "BeijingTime": "28日03:18",
    "eventName": "游泳",
    "matchDetail": "男子100米蛙泳半决赛第2组",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "378",
    "ParisTime": "21:20",
    "BeijingTime": "28日03:20",
    "eventName": "拳击",
    "matchDetail": "女子60公斤级 - 预赛 - 32强赛 莫瑟利0:4冈萨雷斯",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "379",
    "ParisTime": "21:24",
    "BeijingTime": "28日03:24",
    "eventName": "冲浪",
    "matchDetail": "男子第1轮 - 第5组",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "380",
    "ParisTime": "21:30",
    "BeijingTime": "28日03:30",
    "eventName": "击剑",
    "matchDetail": "女子个人重剑金牌赛 马洛-布雷顿12:13江旻憓",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "381",
    "ParisTime": "21:30",
    "BeijingTime": "28日03:30",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 格拉斯门科4:0布尔戈斯",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "382",
    "ParisTime": "21:30",
    "BeijingTime": "28日03:30",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 马德里0:4奥恰洛夫",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "383",
    "ParisTime": "21:30",
    "BeijingTime": "28日03:30",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 帕瓦德4:1沙赫萨瓦里",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "384",
    "ParisTime": "21:30",
    "BeijingTime": "28日03:30",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 邵杰妮4:2德努特",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "385",
    "ParisTime": "21:34",
    "BeijingTime": "28日03:34",
    "eventName": "游泳",
    "matchDetail": "女子4x100米自由泳接力决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "386",
    "ParisTime": "21:36",
    "BeijingTime": "28日03:36",
    "eventName": "拳击",
    "matchDetail": "男子63.5公斤级 - 预赛 - 32强赛 阿尔瓦雷斯 VS 乌梅",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "387",
    "ParisTime": "21:44",
    "BeijingTime": "28日03:44",
    "eventName": "游泳",
    "matchDetail": "男子4x100米自由泳接力决赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "388",
    "ParisTime": "21:52",
    "BeijingTime": "28日03:52",
    "eventName": "拳击",
    "matchDetail": "男子63.5公斤级 - 预赛 - 32强赛 穆罕默德萨比尔5:0伊瓦雷什·孔德",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "389",
    "ParisTime": "21:55",
    "BeijingTime": "28日03:55",
    "eventName": "击剑",
    "matchDetail": "男子个人佩剑金牌赛 吴尚旭15:11费尔贾尼",
    "venue": "巴黎大皇宫"
  },
  {
    "matchId": "390",
    "ParisTime": "22:00",
    "BeijingTime": "28日04:00",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - B组 姜敏赫/徐承宰2:0克拉尔/门德雷克",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "391",
    "ParisTime": "22:00",
    "BeijingTime": "28日04:00",
    "eventName": "羽毛球",
    "matchDetail": "男子双打小组赛 - D组 拉斯姆森/阿斯特鲁普2:0约书亚·袁/邱恺翔",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "392",
    "ParisTime": "22:00",
    "BeijingTime": "28日04:00",
    "eventName": "羽毛球",
    "matchDetail": "女子双打小组赛 - D组 基蒂塔拉克尔/普拉琼贾伊2:1兰伯特/特兰",
    "venue": "拉夏贝尔门体育馆"
  },
  {
    "matchId": "393",
    "ParisTime": "22:00",
    "BeijingTime": "28日04:00",
    "eventName": "冲浪",
    "matchDetail": "男子第1轮 - 第6组",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "394",
    "ParisTime": "22:00",
    "BeijingTime": "28日04:00",
    "eventName": "沙滩排球",
    "matchDetail": "女子预赛 - B组 美国2:0加拿大",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "395",
    "ParisTime": "22:08",
    "BeijingTime": "28日04:08",
    "eventName": "拳击",
    "matchDetail": "男子80公斤级 - 预赛 - 32强赛 阿伊库桑4:1卡瓦拉罗",
    "venue": "北巴黎竞技场"
  },
  {
    "matchId": "396",
    "ParisTime": "22:15",
    "BeijingTime": "28日04:15",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 约内斯库4:3阿鲁纳",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "397",
    "ParisTime": "22:15",
    "BeijingTime": "28日04:15",
    "eventName": "乒乓球",
    "matchDetail": "男子单打64强赛 米尼奥4:3芬恩·刘友福",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "398",
    "ParisTime": "22:15",
    "BeijingTime": "28日04:15",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 阿尔廷卡亚2:4倪夏莲",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "399",
    "ParisTime": "22:15",
    "BeijingTime": "28日04:15",
    "eventName": "乒乓球",
    "matchDetail": "女子单打64强赛 波塔4:3单晓娜",
    "venue": "南巴黎竞技场"
  },
  {
    "matchId": "400",
    "ParisTime": "22:24",
    "BeijingTime": "28日04:24",
    "eventName": "游泳",
    "matchDetail": "男子100米蛙泳半决赛重赛",
    "venue": "拉德芳斯体育馆"
  },
  {
    "matchId": "401",
    "ParisTime": "22:36",
    "BeijingTime": "28日04:36",
    "eventName": "冲浪",
    "matchDetail": "男子第1轮 - 第7组",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "402",
    "ParisTime": "23:00",
    "BeijingTime": "28日05:00",
    "eventName": "沙滩排球",
    "matchDetail": "男子预赛 - A组 意大利0:2卡塔尔",
    "venue": "埃菲尔铁塔体育场"
  },
  {
    "matchId": "403",
    "ParisTime": "23:12",
    "BeijingTime": "28日05:12",
    "eventName": "冲浪",
    "matchDetail": "男子第1轮 - 第8组",
    "venue": "特乎普欧，大溪地"
  },
  {
    "matchId": "404",
    "ParisTime": "23:48",
    "BeijingTime": "28日05:48",
    "eventName": "冲浪",
    "matchDetail": "女子第1轮 - 第1组",
    "venue": "特乎普欧，大溪地"
  },
];
export default matchData;